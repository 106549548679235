import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { BoxSearch } from './Styled'
import DatePicker from '../../../../components/Input/DatePicker'
import dayjs from 'dayjs'

const SearchCustom = () => {
  const { search, handleDateSearch } = useSelector((state) => state.table2)

  return (
    <BoxSearch>
      <DatePicker
        style={{ border: '1px solid #CCCCCC' }}
        placeholder="ค้นหาวันที่สอบ"
        disabledStartDate={'1900-01-01'}
        disabledEndDate={dayjs(new Date()).format(
          window.__env__.REACT_APP_DATE_DB,
        )}
        hideDisableDateRange={true}
        value={_.get(search, 'searchDate', '')}
        onChange={(e) => {
          handleDateSearch(e)
        }}
      />
    </BoxSearch>
  )
}

export default SearchCustom
