import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { StyledBox } from '../Styled'
import { getViewUrl } from './events'
import { setOnView } from '../../../../../redux/slices/table2'

const ActionViewUserProfile = ({ row }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <StyledBox minWidth={0}>
      <>
        <IconButton
          color="primary"
          component="span"
          onClick={() => {
            const viewUrl = getViewUrl(row)
            dispatch(setOnView({ history, viewUrl }))
          }}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      </>
    </StyledBox>
  )
}

export default ActionViewUserProfile
