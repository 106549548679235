import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  setStatusFilter,
  resetPage,
} from '../../../../../redux/slices/table2'
import { getELearningDashboardCourseLearner } from '../../../../../services/eLearning/dashboard'
import { DEFUALT_MODULE_STATUS } from '../../model'
import { downloadFile } from '../../../../../services/util'
import { eLearningDashboardLearnerDownload } from '../../../../../utils/apiPath'

export const headCellsEnroll = [
  {
    id: 'codeId',
    label: 'รหัสผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'userLevel',
    label: 'ระดับผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'email',
    label: 'E-MAIL',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'tel',
    label: 'เบอร์โทร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'ActionViewUserProfile',
    label: '',
    disablePadding: false,
    hideSortIcon: true,
    width: '40px',
  },
]
export const fetchData =
  ({ table, uuid, keyword, startDate, endDate, type, status, courseType }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        name: keyword || '',
        courseUuid: uuid,
        periodStart: startDate,
        periodEnd: endDate,
        type,
        status,
        courseType: courseType === 'all' ? '' : courseType,
      }
      const result = await dispatch(getELearningDashboardCourseLearner(body))
      const payload = _.get(result, 'payload', undefined)
      if (payload) {
        dispatch(
          setInitialTable({
            rows: prepareRow(payload.results),
            allCount: _.get(result, 'payload.totalLearner', 0),
            headCells: headCellsEnroll,
            placeholder: 'ค้นหา',
            searchKey: 'name',
            hideFilter: true,
            handleSearch: (text) =>
              dispatch(
                handleQuickSearch(
                  table,
                  uuid,
                  text,
                  startDate,
                  endDate,
                  type,
                  status,
                  courseType,
                ),
              ),
            onDownload: (selected) => dispatch(onDownload(selected)),
          }),
        )
        dispatch(setSelected([]))
      }
    } catch (e) {
      dispatch(
        setInitialTable({ rows: [], allCount: 0, headCells: headCellsEnroll }),
      )
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, uuid, keyword, startDate, endDate, type, status, courseType) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          uuid,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
          courseType,
        }),
      )
    } else {
      dispatch(resetPage())
      dispatch(setStatusFilter(DEFUALT_MODULE_STATUS))
    }
  }

export const onDownload = (selected) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: 'DESC',
    sort: 'updatedAt',
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningDashboardLearnerDownload,
      body: body,
      fileName: `รายการรายชื่อผู้เรียน.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      name: `${ele.firstNameTH || ''} ${ele.lastNameTH || ''}`,
    }
  })
}
