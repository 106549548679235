import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import localforage from 'localforage'
import { initialState } from './model'
import { otherFilter } from './../../../constants/manageClass/table'
import { handleFilterTotal, handleResetTable } from './events'

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.table.isLoading = true
    },
    stopLoading: (state) => {
      state.table.isLoading = false
    },
    setInitialTable: (state, { payload }) => {
      state.table.isCheckBox = _.get(payload, 'isCheckBox', true)
      state.table.rows = _.get(payload, 'rows', [])
      state.table.allCount = _.get(payload, 'allCount', 0)
      state.table.headCells = _.get(payload, 'headCells', [])
      state.table.searchKey = _.get(payload, 'searchKey', '')
      state.table.tableHeadText = _.get(payload, 'tableHeadText', null)
      state.table.tableHeadVariant = _.get(
        payload,
        'tableHeadVariant',
        'body2b',
      )
      state.table.buttonHeadText = _.get(payload, 'buttonHeadText', null)
      state.table.buttonProps = _.get(payload, 'buttonProps', {})
      state.filter.status = _.get(payload, 'status', [])
      state.filter.quickFilter = _.get(payload, 'quickFilter', null)
      state.filter.quickFilterTitle = _.get(payload, 'quickFilterTitle', '')
      state.filter.other = _.get(payload, 'other', otherFilter)
      state.filter.canFilter = _.get(payload, 'canFilter', undefined)
      state.search.placeholder = _.get(payload, 'placeholder', '')
      state.handleSearch = _.get(payload, 'handleSearch', null)
      state.onDelete = _.get(payload, 'onDelete', null)
      state.onDeleted = _.get(payload, 'onDeleted', null)
      state.onDownload = _.get(payload, 'onDownload', null)
      state.onUpload = _.get(payload, 'onUpload', null)
      state.onQuickFilter = _.get(payload, 'onQuickFilter', null)
      state.onChangeStatus = _.get(payload, 'onChangeStatus', null)
      state.onEligibleExam = _.get(payload, 'onEligibleExam', null)
      state.onSendEmail = _.get(payload, 'onSendEmail', null)
      state.showSendEmail = _.get(payload, 'showSendEmail', false)
      state.customStyle = _.get(payload, 'customStyle', {})
      state.handleDateSearch = _.get(payload, 'handleDateSearch', null)

      state.table.isShowPagination = _.get(payload, 'isShowPagination', true)
      state.table.sxTable = _.get(payload, 'sxTable', {})
      state.filter.hideFilter = _.get(payload, 'hideFilter', false)
    },
    setInitialMore: (state, { payload }) => {
      state.table = {
        ...state.table,
        ...payload,
      }
    },
    setSearchText: (state, { payload }) => {
      state.search.searchText = payload
    },
    setSearchDate: (state, { payload }) => {
      state.search.searchDate = payload
    },
    setToggleFilterDrawer: (state, { payload }) => {
      state.filter.isFilterDrawer = payload
    },
    setStatusFilter: (state, { payload }) => {
      state.filter.status = payload
    },
    setWarehouseTypeFilter: (state, { payload }) => {
      state.filter.warehouseType = payload
    },
    setFilterProp: (state, { payload }) => {
      state.filter.filterProp = payload
    },
    setFilterTotal: (state, { payload }) => {
      state.filter.filterTotal =
        typeof payload === 'number' ? payload : handleFilterTotal(payload)
    },
    setQuickFilter: (state, { payload }) => {
      state.filter.quickFilter = payload
    },
    setOther: (state, { payload }) => {
      state.filter.other = payload
    },
    setDefaultSort: (state, { payload }) => {
      state.table.defaultSort = payload
    },
    setOnQuickFilter: (state, { payload }) => {
      state.table.onQuickFilter = payload
    },
    setOnQuickFilterByIndex: (state, { payload }) => {
      state.filter.quickFilter[payload.index] = payload.item
    },
    setOrder: (state, { payload }) => {
      state.table.order = payload.order
      state.table.sort = payload.sort
    },
    setSelected: (state, { payload }) => {
      state.table.selected = payload
    },
    setOnView: (state, { payload }) => {
      const { history, viewUrl } = payload
      handleResetTable(state)
      history.push(viewUrl)
    },
    setOnEdit: (_, { payload }) => {
      localforage.removeItem('persist:root')
      const { history, editUrl } = payload
      history.push(editUrl)
    },
    setChangeRowsPerPage: (state, { payload }) => {
      state.table.defaultRowsPerPage = _.get(state, 'table.rowsPerPage', 20)
      state.table.rowsPerPage = payload.rowsPerPage
      state.table.limit = payload.limit
      state.table.tablePage = 0
      state.table.page = 1
    },
    setChangePage: (state, { payload }) => {
      state.table.defaultPage = _.get(state, 'table.page', 1)
      state.table.page = payload.page
      state.table.tablePage = payload.tablePage
    },
    resetTablePage: (state) => {
      state.table.tablePage = 0
    },
    resetPage: (state) => {
      state.table.page = 1
    },
    resetRowsPerPage: (state) => {
      state.table.defaultRowsPerPage = 100
      state.table.rowsPerPage = {
        label: '100',
        value: 100,
      }
      state.table.limit = 100
      state.table.tablePage = 0
      state.table.page = 1
    },
    setCustomToolbar: (state, { payload }) => {
      state.customToolbar = payload
    },
    resetOnQuickFilter: (state) => {
      state.filter.quickFilter = []
      state.filter.other = null
    },
    resetTable: (state) => {
      handleResetTable(state)
    },
    setHandleChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    clearFilter: (state) => {
      state.filterState = null
      state.filterStateMemo = null
      state.filter.filterTotal = 0
      state.filter.filterProp = null
    },
    setRows: (state, { payload }) => {
      state.table.rows = _.defaultTo(payload, [])
    },
    setHeadCells: (state, { payload }) => {
      state.table.headCells = _.defaultTo(payload, [])
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialTable,
  setInitialMore,
  setSearchText,
  setSearchDate,
  setToggleFilterDrawer,
  setStatusFilter,
  setWarehouseTypeFilter,
  setQuickFilter,
  setFilterProp,
  setFilterTotal,
  setOnQuickFilter,
  setDefaultSort,
  setOrder,
  setSelected,
  setOnView,
  setOnEdit,
  setChangeRowsPerPage,
  setChangePage,
  resetTablePage,
  resetPage,
  resetRowsPerPage,
  setOnQuickFilterByIndex,
  setOther,
  setCustomToolbar,
  resetOnQuickFilter,
  resetTable,
  setHandleChange,
  clearFilter,
  setRows,
  setHeadCells,
} = tableSlice.actions

export default tableSlice.reducer
