import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportStaffProfileConst = {
  COLUMN_NAME: {
    NO: 'no',
    STAFF_CODE: 'staffCode',
    PREFIX: 'prefix',
    FIRST_NAME_: 'firstName',
    LAST_NAME: 'lastName',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    STAFF_POSITION: 'staffPosition',
    STAFF_LEVEL: 'staffLevel',
    JOB_TITLE: 'jobTitle',
    COST_CENTER: 'costCenter',
    DEPARTMENT: 'department',
    STAION: 'station',
    WORK_START: 'workStart',
    STAFF_STATUS: 'staffStatus',
    REPORT_TO: 'reportTo',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    DESK_NUMBER: 'deskNumber',
    DATE_OF_BIRTH: 'dateOfBirth',
    ID_CARD_NO: 'idCardNo',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    STAFF_CODE: 'รหัสพนักงาน',
    PREFIX: 'Title Name EN',
    FIRST_NAME_: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    STAFF_POSITION: 'ประเภทพนักงาน',
    STAFF_LEVEL: 'Level',
    JOB_TITLE: 'Job Title',
    COST_CENTER: 'Cost Center',
    DEPARTMENT: 'Department',
    STAION: 'Station',
    WORK_START: 'วันเริ่มงาน',
    STAFF_STATUS: 'สถานะการทำงาน',
    REPORT_TO: 'Report To',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    DESK_NUMBER: 'เบอร์โต๊ะทำงาน',
    DATE_OF_BIRTH: 'วัน/เดือน/ปี เกิด',
    ID_CARD_NO: 'เลขบัตรประชาชน',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
}

export const reportStaffProfileDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportStaffProfileConst.COLUMN_NAME.NO,
      value: reportStaffProfileConst.COLUMN_NAME.NO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_CODE,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_CODE,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportStaffProfileConst.COLUMN_NAME.PREFIX,
      value: reportStaffProfileConst.COLUMN_NAME.PREFIX,
      label: reportStaffProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_,
      value: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_,
      label: reportStaffProfileConst.COLUMN_NAME_TH.FIRST_NAME_,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportStaffProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportStaffProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportStaffProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportStaffProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportStaffProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportStaffProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportStaffProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_POSITION,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_POSITION,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_POSITION,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_LEVEL,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_LEVEL,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_LEVEL,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportStaffProfileConst.COLUMN_NAME.JOB_TITLE,
      value: reportStaffProfileConst.COLUMN_NAME.JOB_TITLE,
      label: reportStaffProfileConst.COLUMN_NAME_TH.JOB_TITLE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportStaffProfileConst.COLUMN_NAME.COST_CENTER,
      value: reportStaffProfileConst.COLUMN_NAME.COST_CENTER,
      label: reportStaffProfileConst.COLUMN_NAME_TH.COST_CENTER,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportStaffProfileConst.COLUMN_NAME.DEPARTMENT,
      value: reportStaffProfileConst.COLUMN_NAME.DEPARTMENT,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DEPARTMENT,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportStaffProfileConst.COLUMN_NAME.STAION,
      value: reportStaffProfileConst.COLUMN_NAME.STAION,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAION,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportStaffProfileConst.COLUMN_NAME.WORK_START,
      value: reportStaffProfileConst.COLUMN_NAME.WORK_START,
      label: reportStaffProfileConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_STATUS,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_STATUS,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_STATUS,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportStaffProfileConst.COLUMN_NAME.REPORT_TO,
      value: reportStaffProfileConst.COLUMN_NAME.REPORT_TO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.REPORT_TO,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportStaffProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportStaffProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportStaffProfileConst.COLUMN_NAME.EMAIL,
      value: reportStaffProfileConst.COLUMN_NAME.EMAIL,
      label: reportStaffProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportStaffProfileConst.COLUMN_NAME.DESK_NUMBER,
      value: reportStaffProfileConst.COLUMN_NAME.DESK_NUMBER,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DESK_NUMBER,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportStaffProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportStaffProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportStaffProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportStaffProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_CODE,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_CODE,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_CODE,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportStaffProfileConst.COLUMN_NAME.PREFIX,
      value: reportStaffProfileConst.COLUMN_NAME.PREFIX,
      label: reportStaffProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_,
      value: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_,
      label: reportStaffProfileConst.COLUMN_NAME_TH.FIRST_NAME_,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportStaffProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportStaffProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportStaffProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportStaffProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportStaffProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportStaffProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportStaffProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportStaffProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_POSITION,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_POSITION,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_POSITION,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_LEVEL,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_LEVEL,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_LEVEL,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportStaffProfileConst.COLUMN_NAME.JOB_TITLE,
      value: reportStaffProfileConst.COLUMN_NAME.JOB_TITLE,
      label: reportStaffProfileConst.COLUMN_NAME_TH.JOB_TITLE,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportStaffProfileConst.COLUMN_NAME.COST_CENTER,
      value: reportStaffProfileConst.COLUMN_NAME.COST_CENTER,
      label: reportStaffProfileConst.COLUMN_NAME_TH.COST_CENTER,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportStaffProfileConst.COLUMN_NAME.DEPARTMENT,
      value: reportStaffProfileConst.COLUMN_NAME.DEPARTMENT,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DEPARTMENT,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportStaffProfileConst.COLUMN_NAME.STAION,
      value: reportStaffProfileConst.COLUMN_NAME.STAION,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAION,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportStaffProfileConst.COLUMN_NAME.WORK_START,
      value: reportStaffProfileConst.COLUMN_NAME.WORK_START,
      label: reportStaffProfileConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 15,
      name: reportStaffProfileConst.COLUMN_NAME.STAFF_STATUS,
      value: reportStaffProfileConst.COLUMN_NAME.STAFF_STATUS,
      label: reportStaffProfileConst.COLUMN_NAME_TH.STAFF_STATUS,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportStaffProfileConst.COLUMN_NAME.REPORT_TO,
      value: reportStaffProfileConst.COLUMN_NAME.REPORT_TO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.REPORT_TO,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportStaffProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportStaffProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportStaffProfileConst.COLUMN_NAME.EMAIL,
      value: reportStaffProfileConst.COLUMN_NAME.EMAIL,
      label: reportStaffProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportStaffProfileConst.COLUMN_NAME.DESK_NUMBER,
      value: reportStaffProfileConst.COLUMN_NAME.DESK_NUMBER,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DESK_NUMBER,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportStaffProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportStaffProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportStaffProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 21,
      name: reportStaffProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportStaffProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportStaffProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
      type: reportStaffProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}
