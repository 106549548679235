import { setFiltersData } from 'src/redux/slices/reportComp'
import { store } from '../../../App'
import {
  setTabActiveReport,
  setReduxReport,
  startLoadingRP,
  stopLoadingRP,
} from '../../../redux/slices/monthlyPlan'
import { filterReport } from '../../../services/reportComp'
import _ from 'lodash'
import { handleBodyFilters } from 'src/redux/slices/reportComp/event'
import { downloadFile } from 'src/services/util'
import { reportDownload } from 'src/utils/apiPath'
import dayjs from 'dayjs'
import { COLUMN_NAME } from 'src/constants/report/monthlyPlan'

export const handleChangeTab = (e, newValue) => {
  store.dispatch(setTabActiveReport(newValue))
}

export const handleFilterReport = async () => {
  store.dispatch(startLoadingRP())
  const {
    reportConfig: { modulePath },
    displayFilters,
    defaultColumns,
  } = store.getState().reportComp
  const { reportsData } = store.getState().monthlyPlan.report

  await store.dispatch(setFiltersData(displayFilters))

  const bodyFilters = handleBodyFilters(displayFilters)

  const body = {
    filters: bodyFilters,
  }
  const { payload: response } = await store.dispatch(
    filterReport({
      modulePath: modulePath,
      body,
    }),
  )
  const results = _.get(response, 'results', [])
  const updatedHeadCellsReport = [...defaultColumns]

  _.map(results, (res) => {
    updatedHeadCellsReport.push({
      id: `staff-${res.agentCode}`,
      agentCode: res.agentCode,
      label: res.staffNameTH,
      jobTitle: res.jobTitle,
      minWidth: 160,
      align: 'center',
    })
  })

  const updatedData = _.map(reportsData, (d) => {
    const updatedDetails = _.map(_.get(d, 'details', []), (item) => {
      const staffData = _.reduce(
        results,
        (acc, res) => {
          if (!_.isUndefined(item.key)) {
            return { ...acc, [`staff-${res.agentCode}`]: res[`${item.key}`] }
          }
          return acc
        },
        {},
      )
      return { ...item, ...staffData }
    })
    return { ...d, details: updatedDetails }
  })

  store.dispatch(
    setReduxReport({ key: 'headCellsReport', value: updatedHeadCellsReport }),
  )
  store.dispatch(setReduxReport({ key: 'reportsData', value: updatedData }))
  store.dispatch(stopLoadingRP())
}

export const onDownload = async () => {
  const {
    filters,
    reportConfig: { modulePath, moduleId },
  } = store.getState().reportComp

  const trainingDate = _.find(
    filters,
    (filter) => filter.key === COLUMN_NAME.TRAINING_DATE,
  )
  store.dispatch(stopLoadingRP())
  const startDate = _.isEmpty(trainingDate?.value?.startDate)
    ? dayjs().startOf('month').format('DDMMYYYY')
    : dayjs(trainingDate?.value?.startDate).format('DDMMYYYY')
  const endDate = _.isEmpty(trainingDate?.value?.endDate)
    ? dayjs().format('DDMMYYYY')
    : dayjs(trainingDate?.value?.endDate).format('DDMMYYYY')

  const body = {
    filters: filters,
    moduleId,
  }
  await store.dispatch(
    downloadFile({
      url: `${reportDownload}${modulePath}`,
      body: body,
      fileName: `Report ${
        moduleId === 'MP_WORKLOAD' ? 'Workload' : 'Summary'
      } ${startDate}-${endDate}.xlsx`,
    }),
  )
  store.dispatch(stopLoadingRP())
}
