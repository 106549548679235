import { store } from '../../../App'
import {
  setColumns,
  setIsFilter,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import { fetchReports } from './fetchReports'

export const handleSaveColumns = async () => {
  const {
    displayColumns,
    reportConfig: { moduleId },
  } = store.getState().reportComp

  localStorage.setItem(`columns_${moduleId}`, JSON.stringify(displayColumns))
  store.dispatch(setReduxValue({ key: 'hasSortedColumns', value: true }))
  store.dispatch(setIsFilter(true))
  store.dispatch(setColumns(displayColumns))
  await fetchReports()
}
