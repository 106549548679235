import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../../redux/slices/dialog'
import { Box } from '@mui/material'
// import { handleDeleteAsset } from '../../handler/handleDeleteAsset'
import { useHistory } from 'react-router-dom'
// import { onDownload } from '../events'
import { store } from '../../../../../../App'
import { ViewRow } from '../../../../../../components/CRUD/components/StyledViewBox'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../../components/CRUD/components/CustomTextArea'
import {
  setFieldValue,
  replaceFieldError,
  setFieldError,
} from '../../../../../../redux/slices/crud'
import {
  handleNumberKeyDown,
  handlePasteFormat,
  validatePermission,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import * as yup from 'yup'
import {
  INVENTORY_SETTING,
  PRODUCT_TYPE,
  STOCK_PERMISSION,
  STOCK_STATUS,
} from '../../../../../../constants/stock'
import { productReduce } from '../../../../../../utils/apiPath'
import callAxios from '../../../../../../utils/baseService'

export const formValidationSchema = yup.object({
  available: yup.string().nullable(),
  productReduceQty: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('กรุณาระบุจำนวน')
    .when('available', (available) => {
      let availableNumber = available
      if (typeof availableNumber === 'string') {
        availableNumber = availableNumber
          .replace(/-/g, '')
          .replace(/,/g, '')
          .trim()
      }
      if (available) {
        return yup
          .number()
          .max(
            parseInt(availableNumber),
            'กรุณาระบุจำนวน ในค่าที่น้อยกว่าจำนวนคงเหลือปัจจุบัน',
          )
          .typeError('กรุณาระบุจำนวน')
      }
    }),
  productReduceRemark: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุภายใน 3000 ตัวอักษร')
    .required('กรุณาระบุสาเหตุ'),
})
//

const DropdownButton = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  // const { isHistory, ticketId, assetId } = props
  const { row, isInventoryStock } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  let menuOptions = createOptions
  let product = _.get(row, 'product', '')
  let productType = _.get(row, 'product.productType', '')
  let warehouse = _.get(row, 'warehouse', '')
  if (row?.productWarehouse) {
    productType = _.get(row, 'productWarehouse.product.productType', '')
    warehouse = _.get(row, 'productWarehouse.warehouse', '')
    product = _.get(row, 'productWarehouse.product', '')
  }

  if (productType === PRODUCT_TYPE.STOCK && !isInventoryStock) {
    menuOptions = stockOptions
  } else if (
    [
      PRODUCT_TYPE.STOCK_ASSET,
      PRODUCT_TYPE.ASSET,
      PRODUCT_TYPE.LICENSE,
    ].includes(productType) &&
    !isInventoryStock
  ) {
    menuOptions = stockSKUOptions
  }

  if (
    (warehouse &&
      [STOCK_STATUS.INACTIVE, STOCK_STATUS.DELETED].includes(
        warehouse?.status,
      )) ||
    (isInventoryStock &&
      [STOCK_STATUS.INACTIVE, STOCK_STATUS.DELETED].includes(product?.status))
  ) {
    menuOptions = menuOptions.filter(
      (item) => !['transfer', 'reduce'].includes(item.value),
    )
  }
  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {menuOptions.map((option, index) => {
          let isHide = false
          if (option.value === 'reduce' && productType !== 'STOCK') {
            isHide = true
          } else if (
            option.value === 'asset_list' &&
            ![
              PRODUCT_TYPE.STOCK_ASSET,
              PRODUCT_TYPE.ASSET,
              PRODUCT_TYPE.LICENSE,
            ].includes(productType)
          ) {
            isHide = true
          }
          let disabled = false
          if (option.value === 'asset_detail' && !hasPermission) {
            disabled = true
          }

          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                if (option.value === 'transfer') {
                  history.push(
                    `${productPath.TICKET}/?warehouse=${warehouse?.uuid}&productType=${productType}`,
                  )
                } else if (option.value === 'reduce') {
                  openReduceDialog(row)
                } else if (option.value === 'asset_list') {
                  openAssetListDrawer(row)
                } else if (option.value === 'asset_detail') {
                  history.push(`${productPath?.[productType]}/${product?.uuid}`)
                } else if (option.value === 'history') {
                  openHistoryDrawer(row)
                } else if (option.value === 'warehouse_detail') {
                  history.push(`${productPath.WAREHOUSE}/${warehouse?.uuid}`)
                }
                setAnchorEl(null)
              }}
              sx={{ display: isHide ? 'none' : '' }}
              disabled={disabled}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ปรับลด', value: 'reduce' },
  { text: 'รายการ รหัสทรัพย์สิน ของทรัพย์สินนี้ในคลัง', value: 'asset_list' },
  { text: 'รายละเอียดทรัพย์สิน', value: 'asset_detail' },
]

export const stockOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ปรับลด', value: 'reduce' },
  { text: 'ประวัติการเคลื่อนไหว', value: 'history' },
  { text: 'รายละเอียด คลังทรัพย์สิน', value: 'warehouse_detail' },
]

export const stockSKUOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ประวัติการเคลื่อนไหว', value: 'history' },
  { text: 'รายละเอียด คลังทรัพย์สิน', value: 'warehouse_detail' },
  { text: 'ดูรายการ รหัสทรัพย์สิน ของทรัพย์สินนี้ในคลัง', value: 'asset_list' },
]

export const productPath = {
  STOCK: '/inventory/product/stock/view',
  STOCK_ASSET: '/inventory/product/stock/view',
  ASSET: '/inventory/product/asset/view',
  LICENSE: '/inventory/product/license/view',
  WAREHOUSE: '/inventory/stock/detail',
  TICKET: '/inventory/transfer/form',
}

export const openReduceDialog = (row) => {
  const { formData } = store.getState().crud
  let product = _.get(row, 'product', '')
  if (row?.productWarehouse) {
    product = _.get(row, 'productWarehouse.product', '')
  }
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        productReduceQty: '',
        productReduceRemark: '',
        available: row?.available,
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'ปรับลด',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อทรัพย์สิน"
            value={_.get(product, 'productName', '-')}
          />
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อคลังทรัพย์สิน"
            value={_.get(row, 'warehouseName', '-')}
            required
          />
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="จำนวนคงเหลือปัจจุบัน"
            value={_.get(row, 'available', '-')}
            required
          />
          <CustomInput
            boxSx={{ my: 2 }}
            fieldName="productReduceQty"
            labelName="จำนวนที่ปรับลด"
            required
            inputProps={{
              'data-testid': 'input-productReduceQty',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
          <CustomTextArea
            fieldName="productReduceRemark"
            labelName="สาเหตุ"
            required
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitReduce(row)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}

export const openAssetListDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetDrawer',
      value: true,
    }),
  )
  setAssetDrawerInfo(row)
}

export const openHistoryDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenHistoryDrawer',
      value: true,
    }),
  )
  setAssetDrawerInfo(row)
}

export const setAssetDrawerInfo = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'assetDrawerInfo',
      value: row,
    }),
  )
}

export const handleSubmitReduce = async (row) => {
  const isValid = await validateData(formValidationSchema)
  if (isValid) {
    const { formData } = store.getState().crud
    const body = {
      uuid: _.get(row, 'uuid', ''),
      quantity: _.get(formData, 'productReduceQty', ''),
      remark: _.get(formData, 'productReduceRemark', ''),
    }
    store.dispatch(loadingDialog())
    await callAxios
      .post(productReduce, body)
      .then(() => {
        store.dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              store.dispatch(closeDialog())
              store.dispatch(
                setFieldValue({
                  key: 'isRefreshTable',
                  value: true,
                }),
              )
            },
          }),
        )
      })
      .catch(() => {
        store.dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ระบบขัดข้อง',
          }),
        )
      })
  }
  // if (!isCancel) store.dispatch(closeDialog())
}
