import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../../../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenStaffDrawer } from '../../../../../redux/slices/monthlyPlan'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Table from '../../../../../components/redux/Table'
import { useTheme } from '@mui/system'
import { TOP_LIST_TYPE_LABEL } from 'src/constants/monthlyPlan/dashboard'

const StaffDrawer = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isOpenStaffDrawer, staffType } = useSelector(
    (state) => ({
      isOpenStaffDrawer: state.monthlyPlan.dashboard.isOpenStaffDrawer,
      staffType: state.monthlyPlan.dashboard.staffType,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{
        position: 'relative',
        '& .MuiPaper-root': {
          width: 630,
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      open={isOpenStaffDrawer}
      onClose={() => dispatch(setIsOpenStaffDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">
            รายชื่อ
            {`${
              staffType === 'SPEAKER'
                ? 'หน้าที่'
                : staffType === 'WORKLOAD'
                ? 'ที่มี'
                : ''
            } ${TOP_LIST_TYPE_LABEL[staffType]} ${
              staffType !== 'SPEAKER' ? 'สูงสุด (ทุกประเภท)' : 'มากที่สุด'
            }`}
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenStaffDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <Box
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: 1,
        }}
        mx={3}
      >
        <Table />
      </Box>

      <StyledFooter>
        <Button
          sx={{ ml: 'auto', maxWidth: 40 }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => dispatch(setIsOpenStaffDrawer(false))}
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default StaffDrawer
