import _ from 'lodash'
import {
  setIsOpenFilterDrawer,
  setDisplayFilter,
  setFiltersDashboard,
} from '../../../../redux/slices/monthlyPlan'
import { store } from '../../../../App'
import { getDefaultFiltersDashboard } from '../../handler/getDefaultFiltersDashboard'

export const handleChange = ({ key, value, listKey }) => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard

  let objState = {
    ...displayFilters,
    [key]: value,
  }

  if (!_.isNil(listKey)) {
    objState = {
      ...displayFilters,
      [listKey]: {
        ...displayFilters[listKey],
        [key]: value,
      },
    }
  }
  store.dispatch(setDisplayFilter(objState))
}

export const handleSelectDate = (selectedDates) => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard
  let objState = {
    ...displayFilters,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }

  store.dispatch(setDisplayFilter(objState))
}

export const onClearClick = async () => {
  const defaultFilters = await getDefaultFiltersDashboard()
  store.dispatch(setDisplayFilter(defaultFilters))
}

export const filterDashboard = async () => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard

  store.dispatch(setIsOpenFilterDrawer(false))
  store.dispatch(setFiltersDashboard(displayFilters))
}
