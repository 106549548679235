import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportExamEnrollConst = {
  COLUMN_NAME: {
    NO: 'no',
    DATE: 'date',
    TIME_NAME: 'timeName',
    TIME: 'time',
    START_DATE_REGISTER: 'startDateRegister',
    END_DATE_REGISTER: 'endDateRegister',
    NUMBER_OF_PEOPLE: 'numberOfPeople',
    COUNT_ENROLL: 'countEnroll',
    COUNT_EXAMINEES: 'countExaminees',
    EXAMINATION_FIELD: 'examinationField',
    FILED_TYPE: 'fieldType',
    ASSOCIATION_LOCATION_CODE: 'associationLoactionCode',
    STATION_LOCATION_CODE: 'stationLocationCode',
    OTHER: 'other',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
    STATUS: 'status',
    RESULT: 'result',
    TEMP_CODE: 'tempCode',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    PREFIX_: 'prefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    LICENSE_NO: 'licenseNo',
    REG_RENEWAL_DATE: 'regRenewalDate',
    ID_CARD_NO: 'idCardNo',
    UNIT_CODE: 'unitCode',
    UNIT_NAME: 'unitName',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    GA_CODE: 'gaCode',
    STATUS_AGENT: 'statusAgent',
    POSITION: 'position',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    MANAGER_FULL_CODE: 'managerFullCode',
    MANAGER_NAME: 'managerName',
    MANAGER_MOBILE_NO: 'managerMobileNo',
    MANAGER_EMAIL: 'managerEmail',
    CREATED_AT: 'createdAt',
    CREATED_BY: 'createdBy',
    UPDATED_AT: 'updatedAt',
    UPDATED_BY: 'updatedBy',
    COMPANY_UUID: 'companyUuid',
    REGION_UUID: 'regionUuid',
    ASSOCIATION_UUID: 'associationUuid',
    SEAT_AVAILABLE: 'seatAvailable',
    MANAGER_AGENT_CODE: 'managerAgentCode',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    DATE: 'วันที่สอบ',
    TIME: 'เวลาที่สอบ',
    TIME_NAME: 'รอบสอบ',
    START_DATE_REGISTER: 'วันเริ่มต้นรับสมัคร',
    END_DATE_REGISTER: 'วันสิ้นสุดรับสมัคร',
    NUMBER_OF_PEOPLE: 'จำนวนเปิดรับสมัคร',
    COUNT_ENROLL: 'จำนวนผู้สมัครสอบ',
    COUNT_EXAMINEES: 'จำนวนผู้เข้าสอบ',
    EXAMINATION_FIELD: 'ประเภทสนามสอบ',
    FILED_TYPE: 'รูปแบบ',
    ASSOCIATION_LOCATION_CODE: 'รหัสจังหวัด',
    STATION_LOCATION_CODE: 'รหัสบริษัท',
    OTHER: 'สถานที่สอบอื่นๆ',
    DAY: 'วันสอบ',
    MONTH: 'เดือนสอบ',
    YEAR: 'ปี พ.ศ. สอบ',
    STATUS: 'สถานะรอบสอบ',
    RESULT: 'ผลสอบ',
    TEMP_CODE: 'Temp Code',
    FULL_AGENT_CODE: 'Full Agent Code',
    AGENT_CODE: 'Agent Code',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    PREFIX_: 'Title Name EN',
    FIRST_NAME: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    LICENSE_NO: 'License No.',
    REG_RENEWAL_DATE: 'Reg. Renewal Date',
    ID_CARD_NO: 'ID Card',
    UNIT_CODE: 'Unit Code',
    UNIT_NAME: 'Unit Name',
    RAM_CODE: 'Ram Code',
    RAM_NAME: 'Ram Name',
    GA_CODE: 'GA',
    STATUS_AGENT: 'Status Agent',
    POSITION: 'Position',
    MOBILE_NO: 'Phone Number',
    EMAIL: 'Email',
    MANAGER_FULL_CODE: 'Manger Full Code',
    MANAGER_NAME: 'Manager Name',
    MANAGER_MOBILE_NO: 'Manager Tel.',
    MANAGER_EMAIL: 'Manager E-mail',
    CREATED_AT: 'วันที่สร้าง',
    CREATED_BY: 'สร้างโดย',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    UPDATED_BY: 'แก้ไขล่าสุดโดย',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  examinationFieldOptions: [
    {
      label: 'สนามสอบบริษัท',
      value: 'บริษัท',
    },
    {
      label: 'สนามสอบสมาคม',
      value: 'สมาคม',
    },
    {
      label: 'สนามสอบอื่นๆ',
      value: 'อื่นๆ',
    },
  ],
  statusOptions: [
    {
      label: 'รอเปิดรับสมัคร',
      value: 'รอเปิดรับสมัคร',
    },
    {
      label: 'เปิดรับสมัคร',
      value: 'เปิดรับสมัคร',
    },
    {
      label: 'ปิดรับสมัคร',
      value: 'ปิดรับสมัคร',
    },
    {
      label: 'ระหว่างสอบ',
      value: 'ระหว่างสอบ',
    },
    {
      label: 'จบการสอบ',
      value: 'จบการสอบ',
    },
    {
      label: 'เสร็จสมบูรณ์',
      value: 'เสร็จสมบูรณ์',
    },
    {
      label: 'ยกเลิกการสอบ',
      value: 'ยกเลิกการสอบ',
    },
  ],
  timeNameOptions: [
    {
      label: 'เช้า',
      value: 'เช้า',
    },
    {
      label: 'สาย',
      value: 'สาย',
    },
    {
      label: 'บ่าย',
      value: 'บ่าย',
    },
    {
      label: 'เย็น',
      value: 'เย็น',
    },
    {
      label: 'ค่ำ',
      value: 'ค่ำ',
    },
  ],
  seatAvailableOptions: [
    { label: 'ว่าง', value: true },
    { label: 'เต็ม', value: false },
  ],
}

export const reportExamEnrollDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportExamEnrollConst.COLUMN_NAME.NO,
      value: reportExamEnrollConst.COLUMN_NAME.NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportExamEnrollConst.COLUMN_NAME.DATE,
      value: reportExamEnrollConst.COLUMN_NAME.DATE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.DATE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportExamEnrollConst.COLUMN_NAME.TIME_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.TIME_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.TIME_NAME,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportExamEnrollConst.COLUMN_NAME.TIME,
      value: reportExamEnrollConst.COLUMN_NAME.TIME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.TIME,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportExamEnrollConst.COLUMN_NAME.START_DATE_REGISTER,
      value: reportExamEnrollConst.COLUMN_NAME.START_DATE_REGISTER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.START_DATE_REGISTER,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportExamEnrollConst.COLUMN_NAME.END_DATE_REGISTER,
      value: reportExamEnrollConst.COLUMN_NAME.END_DATE_REGISTER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.END_DATE_REGISTER,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportExamEnrollConst.COLUMN_NAME.NUMBER_OF_PEOPLE,
      value: reportExamEnrollConst.COLUMN_NAME.NUMBER_OF_PEOPLE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.NUMBER_OF_PEOPLE,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportExamEnrollConst.COLUMN_NAME.COUNT_ENROLL,
      value: reportExamEnrollConst.COLUMN_NAME.COUNT_ENROLL,
      label: reportExamEnrollConst.COLUMN_NAME_TH.COUNT_ENROLL,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportExamEnrollConst.COLUMN_NAME.COUNT_EXAMINEES,
      value: reportExamEnrollConst.COLUMN_NAME.COUNT_EXAMINEES,
      label: reportExamEnrollConst.COLUMN_NAME_TH.COUNT_EXAMINEES,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportExamEnrollConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportExamEnrollConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportExamEnrollConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportExamEnrollConst.COLUMN_NAME.FILED_TYPE,
      value: reportExamEnrollConst.COLUMN_NAME.FILED_TYPE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FILED_TYPE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportExamEnrollConst.COLUMN_NAME.ASSOCIATION_LOCATION_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.ASSOCIATION_LOCATION_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.ASSOCIATION_LOCATION_CODE,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportExamEnrollConst.COLUMN_NAME.STATION_LOCATION_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.STATION_LOCATION_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.STATION_LOCATION_CODE,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportExamEnrollConst.COLUMN_NAME.OTHER,
      value: reportExamEnrollConst.COLUMN_NAME.OTHER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.OTHER,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportExamEnrollConst.COLUMN_NAME.DAY,
      value: reportExamEnrollConst.COLUMN_NAME.DAY,
      label: reportExamEnrollConst.COLUMN_NAME_TH.DAY,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportExamEnrollConst.COLUMN_NAME.MONTH,
      value: reportExamEnrollConst.COLUMN_NAME.MONTH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MONTH,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportExamEnrollConst.COLUMN_NAME.YEAR,
      value: reportExamEnrollConst.COLUMN_NAME.YEAR,
      label: reportExamEnrollConst.COLUMN_NAME_TH.YEAR,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportExamEnrollConst.COLUMN_NAME.STATUS,
      value: reportExamEnrollConst.COLUMN_NAME.STATUS,
      label: reportExamEnrollConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportExamEnrollConst.COLUMN_NAME.RESULT,
      value: reportExamEnrollConst.COLUMN_NAME.RESULT,
      label: reportExamEnrollConst.COLUMN_NAME_TH.RESULT,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportExamEnrollConst.COLUMN_NAME.TEMP_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.TEMP_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.TEMP_CODE,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportExamEnrollConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportExamEnrollConst.COLUMN_NAME.AGENT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.AGENT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportExamEnrollConst.COLUMN_NAME.PREFIX_TH,
      value: reportExamEnrollConst.COLUMN_NAME.PREFIX_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportExamEnrollConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportExamEnrollConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportExamEnrollConst.COLUMN_NAME.PREFIX_,
      value: reportExamEnrollConst.COLUMN_NAME.PREFIX_,
      label: reportExamEnrollConst.COLUMN_NAME_TH.PREFIX_,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportExamEnrollConst.COLUMN_NAME.LAST_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.LAST_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportExamEnrollConst.COLUMN_NAME.LICENSE_NO,
      value: reportExamEnrollConst.COLUMN_NAME.LICENSE_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportExamEnrollConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportExamEnrollConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportExamEnrollConst.COLUMN_NAME.ID_CARD_NO,
      value: reportExamEnrollConst.COLUMN_NAME.ID_CARD_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportExamEnrollConst.COLUMN_NAME.UNIT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.UNIT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportExamEnrollConst.COLUMN_NAME.UNIT_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.UNIT_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportExamEnrollConst.COLUMN_NAME.RAM_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.RAM_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportExamEnrollConst.COLUMN_NAME.RAM_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.RAM_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportExamEnrollConst.COLUMN_NAME.GA_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.GA_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportExamEnrollConst.COLUMN_NAME.STATUS_AGENT,
      value: reportExamEnrollConst.COLUMN_NAME.STATUS_AGENT,
      label: reportExamEnrollConst.COLUMN_NAME_TH.STATUS_AGENT,
      minWidth: 160,
    },
    {
      id: 38,
      name: reportExamEnrollConst.COLUMN_NAME.POSITION,
      value: reportExamEnrollConst.COLUMN_NAME.POSITION,
      label: reportExamEnrollConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportExamEnrollConst.COLUMN_NAME.MOBILE_NO,
      value: reportExamEnrollConst.COLUMN_NAME.MOBILE_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportExamEnrollConst.COLUMN_NAME.EMAIL,
      value: reportExamEnrollConst.COLUMN_NAME.EMAIL,
      label: reportExamEnrollConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_FULL_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_FULL_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MANAGER_FULL_CODE,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MANAGER_NAME,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_MOBILE_NO,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_MOBILE_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MANAGER_MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_EMAIL,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_EMAIL,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MANAGER_EMAIL,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportExamEnrollConst.COLUMN_NAME.DATE,
      value: reportExamEnrollConst.COLUMN_NAME.DATE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.DATE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 2,
      name: reportExamEnrollConst.COLUMN_NAME.START_DATE_REGISTER,
      value: reportExamEnrollConst.COLUMN_NAME.START_DATE_REGISTER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.START_DATE_REGISTER,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 3,
      name: reportExamEnrollConst.COLUMN_NAME.END_DATE_REGISTER,
      value: reportExamEnrollConst.COLUMN_NAME.END_DATE_REGISTER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.END_DATE_REGISTER,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportExamEnrollConst.COLUMN_NAME.CREATED_AT,
      value: reportExamEnrollConst.COLUMN_NAME.CREATED_AT,
      label: reportExamEnrollConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 5,
      name: reportExamEnrollConst.COLUMN_NAME.UPDATED_AT,
      value: reportExamEnrollConst.COLUMN_NAME.UPDATED_AT,
      label: reportExamEnrollConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 6,
      name: reportExamEnrollConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportExamEnrollConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportExamEnrollConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportExamEnrollConst.examinationFieldOptions,
    },
    {
      id: 7,
      name: reportExamEnrollConst.COLUMN_NAME.COMPANY_UUID,
      value: reportExamEnrollConst.COLUMN_NAME.COMPANY_UUID,
      label: 'สนามสอบ บริษัท',
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExamEnrollConst.COLUMN_NAME.COMPANY_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 8,
      name: reportExamEnrollConst.COLUMN_NAME.REGION_UUID,
      value: reportExamEnrollConst.COLUMN_NAME.REGION_UUID,
      label: 'ภาค',
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExamEnrollConst.COLUMN_NAME.REGION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportExamEnrollConst.COLUMN_NAME.ASSOCIATION_UUID,
      value: reportExamEnrollConst.COLUMN_NAME.ASSOCIATION_UUID,
      label: 'สนามสอบ สมาคม',
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExamEnrollConst.COLUMN_NAME.ASSOCIATION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 10,
      name: reportExamEnrollConst.COLUMN_NAME.STATUS,
      value: reportExamEnrollConst.COLUMN_NAME.STATUS,
      label: reportExamEnrollConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportExamEnrollConst.statusOptions,
    },
    {
      id: 11,
      name: reportExamEnrollConst.COLUMN_NAME.TIME_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.TIME_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.TIME_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportExamEnrollConst.timeNameOptions,
    },
    {
      id: 12,
      name: reportExamEnrollConst.COLUMN_NAME.SEAT_AVAILABLE,
      value: reportExamEnrollConst.COLUMN_NAME.SEAT_AVAILABLE,
      label: 'ที่นั่ง',
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportExamEnrollConst.seatAvailableOptions,
    },
    {
      id: 13,
      name: reportExamEnrollConst.COLUMN_NAME.OTHER,
      value: reportExamEnrollConst.COLUMN_NAME.OTHER,
      label: reportExamEnrollConst.COLUMN_NAME_TH.OTHER,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      label: 'Manager Agent Code',
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportExamEnrollConst.COLUMN_NAME.TEMP_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.TEMP_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.TEMP_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportExamEnrollConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportExamEnrollConst.COLUMN_NAME.AGENT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.AGENT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportExamEnrollConst.COLUMN_NAME.PREFIX_TH,
      value: reportExamEnrollConst.COLUMN_NAME.PREFIX_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportExamEnrollConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportExamEnrollConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportExamEnrollConst.COLUMN_NAME.PREFIX_,
      value: reportExamEnrollConst.COLUMN_NAME.PREFIX_,
      label: reportExamEnrollConst.COLUMN_NAME_TH.PREFIX_,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 22,
      name: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.FIRST_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportExamEnrollConst.COLUMN_NAME.LAST_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.LAST_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 24,
      name: reportExamEnrollConst.COLUMN_NAME.LICENSE_NO,
      value: reportExamEnrollConst.COLUMN_NAME.LICENSE_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 25,
      name: reportExamEnrollConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportExamEnrollConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 26,
      name: reportExamEnrollConst.COLUMN_NAME.ID_CARD_NO,
      value: reportExamEnrollConst.COLUMN_NAME.ID_CARD_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 27,
      name: reportExamEnrollConst.COLUMN_NAME.UNIT_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.UNIT_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 28,
      name: reportExamEnrollConst.COLUMN_NAME.UNIT_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.UNIT_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportExamEnrollConst.COLUMN_NAME.RAM_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.RAM_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 30,
      name: reportExamEnrollConst.COLUMN_NAME.RAM_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.RAM_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportExamEnrollConst.COLUMN_NAME.GA_CODE,
      value: reportExamEnrollConst.COLUMN_NAME.GA_CODE,
      label: reportExamEnrollConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 32,
      name: reportExamEnrollConst.COLUMN_NAME.STATUS_AGENT,
      value: reportExamEnrollConst.COLUMN_NAME.STATUS_AGENT,
      label: reportExamEnrollConst.COLUMN_NAME_TH.STATUS_AGENT,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 33,
      name: reportExamEnrollConst.COLUMN_NAME.POSITION,
      value: reportExamEnrollConst.COLUMN_NAME.POSITION,
      label: reportExamEnrollConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 34,
      name: reportExamEnrollConst.COLUMN_NAME.MOBILE_NO,
      value: reportExamEnrollConst.COLUMN_NAME.MOBILE_NO,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 35,
      name: reportExamEnrollConst.COLUMN_NAME.EMAIL,
      value: reportExamEnrollConst.COLUMN_NAME.EMAIL,
      label: reportExamEnrollConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 36,
      name: reportExamEnrollConst.COLUMN_NAME.MANAGER_NAME,
      value: reportExamEnrollConst.COLUMN_NAME.MANAGER_NAME,
      label: reportExamEnrollConst.COLUMN_NAME_TH.MANAGER_NAME,
      minWidth: 160,
      type: reportExamEnrollConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}
