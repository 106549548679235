import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportEExamScheduleConst = {
  COLUMN_NAME: {
    NO: 'no',
    DATE: 'date',
    TIME_NAME: 'timeName',
    TIME: 'time',
    START_DATE_REGISTER: 'startDateRegister',
    END_DATE_REGISTER: 'endDateRegister',
    NUMBER_OF_PEOPLE: 'numberOfPeople',
    COUNT_AVAILABLE: 'countAvailable',
    EXAMINATION_FIELD: 'examinationField',
    FIELD_TYPE: 'fieldType',
    ASSOCIATION_LOCATION_CODE: 'associationLoactionCode',
    STATION_LOCATION_CODE: 'stationLocationCode',
    OTHER: 'other',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
    STATUS: 'status',
    COUNT_ENROLL: 'countEnroll',
    COUNT_EXAMINEES: 'countExaminees',
    COUNT_PASS: 'countPass',
    COUNT_FAIL: 'countFail',
    COUNT_MISSED: 'countMissed',
    PERCENT_OF_EXAMINEES: 'percentOfExaminees',
    PERCENT_OF_PASS: 'percentOfPass',
    PERCENT_OF_FAIL: 'percentOfFail',
    PERCENT_OF_MISSED: 'percentOfMissed',
    CREATED_AT: 'createdAt',
    CREATED_BY: 'createdBy',
    UPDATED_AT: 'updatedAt',
    UPDATED_BY: 'updatedBy',
    COMPANY_UUID: 'companyUuid',
    REGION_UUID: 'regionUuid',
    ASSOCIATION_UUID: 'associationUuid',
    SEAT_AVAILABLE: 'seatAvailable',
  },
  COLUMN_NAME_TH: {
    NO: 'No.',
    DATE: 'วันที่สอบ',
    TIME_NAME: 'รอบสอบ',
    TIME: 'เวลาที่สอบ',
    START_DATE_REGISTER: 'วันเริ่มต้นรับสมัคร',
    END_DATE_REGISTER: 'วันสิ้นสุดรับสมัคร',
    NUMBER_OF_PEOPLE: 'จำนวนเปิดรับสมัคร',
    COUNT_AVAILABLE: 'จำนวนที่ว่าง',
    EXAMINATION_FIELD: 'ประเภทสนามสอบ',
    FIELD_TYPE: 'รูปแบบ',
    ASSOCIATION_LOCATION_CODE: 'รหัสจังหวัด',
    STATION_LOCATION_CODE: 'รหัสบริษัท',
    OTHER: 'สถานที่สอบอื่นๆ',
    DAY: 'วันสอบ',
    MONTH: 'เดือนสอบ',
    YEAR: 'ปี พ.ศ.สอบ',
    STATUS: 'สถานะรอบอบรม',
    COUNT_ENROLL: 'จำนวนผู้สมัครสอบ',
    COUNT_EXAMINEES: 'จำนวนผู้เข้าสอบ',
    COUNT_PASS: 'ผ่าน',
    COUNT_FAIL: 'ไม่ผ่าน',
    COUNT_MISSED: 'ขาดสอบ',
    PERCENT_OF_EXAMINEES: '% จำนวนผู้เข้าสอบ',
    PERCENT_OF_PASS: '% ผ่าน',
    PERCENT_OF_FAIL: '% ไม่ผ่าน',
    PERCENT_OF_MISSED: '% ขาดสอบ',
    CREATED_AT: 'วันที่สร้าง',
    CREATED_BY: 'สร้างโดย',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    UPDATED_BY: 'แก้ไขล่าสุดโดย',
    SEAT_AVAILABLE: 'ที่นั่ง',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  examinationFieldOptions: [
    {
      label: 'สนามสอบบริษัท',
      value: 'บริษัท',
    },
    {
      label: 'สนามสอบสมาคม',
      value: 'สมาคม',
    },
    {
      label: 'สนามสอบอื่นๆ',
      value: 'อื่นๆ',
    },
  ],
  statusOptions: [
    {
      label: 'รอเปิดรับสมัคร',
      value: 'รอเปิดรับสมัคร',
    },
    {
      label: 'เปิดรับสมัคร',
      value: 'เปิดรับสมัคร',
    },
    {
      label: 'ปิดรับสมัคร',
      value: 'ปิดรับสมัคร',
    },
    {
      label: 'ระหว่างสอบ',
      value: 'ระหว่างสอบ',
    },
    {
      label: 'จบการสอบ',
      value: 'จบการสอบ',
    },
    {
      label: 'เสร็จสมบูรณ์',
      value: 'เสร็จสมบูรณ์',
    },
    {
      label: 'ยกเลิกการสอบ',
      value: 'ยกเลิกการสอบ',
    },
  ],
  timeNameOptions: [
    {
      label: 'เช้า',
      value: 'เช้า',
    },
    {
      label: 'สาย',
      value: 'สาย',
    },
    {
      label: 'บ่าย',
      value: 'บ่าย',
    },
    {
      label: 'เย็น',
      value: 'เย็น',
    },
    {
      label: 'ค่ำ',
      value: 'ค่ำ',
    },
  ],
  seatAvailableOptions: [
    { label: 'ว่าง', value: true },
    { label: 'เต็ม', value: false },
  ],
}

export const reportEExamScheduleDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportEExamScheduleConst.COLUMN_NAME.NO,
      value: reportEExamScheduleConst.COLUMN_NAME.NO,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportEExamScheduleConst.COLUMN_NAME.DATE,
      value: reportEExamScheduleConst.COLUMN_NAME.DATE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.DATE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportEExamScheduleConst.COLUMN_NAME.TIME_NAME,
      value: reportEExamScheduleConst.COLUMN_NAME.TIME_NAME,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.TIME_NAME,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportEExamScheduleConst.COLUMN_NAME.TIME,
      value: reportEExamScheduleConst.COLUMN_NAME.TIME,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.TIME,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportEExamScheduleConst.COLUMN_NAME.START_DATE_REGISTER,
      value: reportEExamScheduleConst.COLUMN_NAME.START_DATE_REGISTER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.START_DATE_REGISTER,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportEExamScheduleConst.COLUMN_NAME.END_DATE_REGISTER,
      value: reportEExamScheduleConst.COLUMN_NAME.END_DATE_REGISTER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.END_DATE_REGISTER,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportEExamScheduleConst.COLUMN_NAME.NUMBER_OF_PEOPLE,
      value: reportEExamScheduleConst.COLUMN_NAME.NUMBER_OF_PEOPLE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.NUMBER_OF_PEOPLE,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_AVAILABLE,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_AVAILABLE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_AVAILABLE,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportEExamScheduleConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportEExamScheduleConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportEExamScheduleConst.COLUMN_NAME.FIELD_TYPE,
      value: reportEExamScheduleConst.COLUMN_NAME.FIELD_TYPE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.FIELD_TYPE,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportEExamScheduleConst.COLUMN_NAME.ASSOCIATION_LOCATION_CODE,
      value: reportEExamScheduleConst.COLUMN_NAME.ASSOCIATION_LOCATION_CODE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.ASSOCIATION_LOCATION_CODE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportEExamScheduleConst.COLUMN_NAME.STATION_LOCATION_CODE,
      value: reportEExamScheduleConst.COLUMN_NAME.STATION_LOCATION_CODE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.STATION_LOCATION_CODE,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportEExamScheduleConst.COLUMN_NAME.OTHER,
      value: reportEExamScheduleConst.COLUMN_NAME.OTHER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.OTHER,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportEExamScheduleConst.COLUMN_NAME.DAY,
      value: reportEExamScheduleConst.COLUMN_NAME.DAY,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.DAY,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportEExamScheduleConst.COLUMN_NAME.MONTH,
      value: reportEExamScheduleConst.COLUMN_NAME.MONTH,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.MONTH,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportEExamScheduleConst.COLUMN_NAME.YEAR,
      value: reportEExamScheduleConst.COLUMN_NAME.YEAR,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.YEAR,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportEExamScheduleConst.COLUMN_NAME.STATUS,
      value: reportEExamScheduleConst.COLUMN_NAME.STATUS,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_ENROLL,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_ENROLL,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_ENROLL,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_MISSED,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_MISSED,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_MISSED,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_MISSED,
      value: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_MISSED,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.PERCENT_OF_MISSED,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_EXAMINEES,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_EXAMINEES,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_EXAMINEES,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_EXAMINEES,
      value: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_EXAMINEES,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.PERCENT_OF_EXAMINEES,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_PASS,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_PASS,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_PASS,
      value: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_PASS,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.PERCENT_OF_PASS,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportEExamScheduleConst.COLUMN_NAME.COUNT_FAIL,
      value: reportEExamScheduleConst.COLUMN_NAME.COUNT_FAIL,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.COUNT_FAIL,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_FAIL,
      value: reportEExamScheduleConst.COLUMN_NAME.PERCENT_OF_FAIL,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.PERCENT_OF_FAIL,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportEExamScheduleConst.COLUMN_NAME.CREATED_AT,
      value: reportEExamScheduleConst.COLUMN_NAME.CREATED_AT,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportEExamScheduleConst.COLUMN_NAME.CREATED_BY,
      value: reportEExamScheduleConst.COLUMN_NAME.CREATED_BY,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.CREATED_BY,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportEExamScheduleConst.COLUMN_NAME.UPDATED_AT,
      value: reportEExamScheduleConst.COLUMN_NAME.UPDATED_AT,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportEExamScheduleConst.COLUMN_NAME.UPDATED_BY,
      value: reportEExamScheduleConst.COLUMN_NAME.UPDATED_BY,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.UPDATED_BY,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportEExamScheduleConst.COLUMN_NAME.DATE,
      value: reportEExamScheduleConst.COLUMN_NAME.DATE,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.DATE,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 2,
      name: reportEExamScheduleConst.COLUMN_NAME.START_DATE_REGISTER,
      value: reportEExamScheduleConst.COLUMN_NAME.START_DATE_REGISTER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.START_DATE_REGISTER,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 3,
      name: reportEExamScheduleConst.COLUMN_NAME.END_DATE_REGISTER,
      value: reportEExamScheduleConst.COLUMN_NAME.END_DATE_REGISTER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.END_DATE_REGISTER,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportEExamScheduleConst.COLUMN_NAME.CREATED_AT,
      value: reportEExamScheduleConst.COLUMN_NAME.CREATED_AT,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 5,
      name: reportEExamScheduleConst.COLUMN_NAME.UPDATED_AT,
      value: reportEExamScheduleConst.COLUMN_NAME.UPDATED_AT,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 6,
      name: reportEExamScheduleConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportEExamScheduleConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamScheduleConst.examinationFieldOptions,
    },
    {
      id: 7,
      name: reportEExamScheduleConst.COLUMN_NAME.COMPANY_UUID,
      value: reportEExamScheduleConst.COLUMN_NAME.COMPANY_UUID,
      label: 'สนามสอบ บริษัท',
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamScheduleConst.COLUMN_NAME.COMPANY_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 8,
      name: reportEExamScheduleConst.COLUMN_NAME.REGION_UUID,
      value: reportEExamScheduleConst.COLUMN_NAME.REGION_UUID,
      label: 'ภาค',
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamScheduleConst.COLUMN_NAME.REGION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportEExamScheduleConst.COLUMN_NAME.ASSOCIATION_UUID,
      value: reportEExamScheduleConst.COLUMN_NAME.ASSOCIATION_UUID,
      label: 'สนามสอบ สมาคม',
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamScheduleConst.COLUMN_NAME.ASSOCIATION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 10,
      name: reportEExamScheduleConst.COLUMN_NAME.OTHER,
      value: reportEExamScheduleConst.COLUMN_NAME.OTHER,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.OTHER,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportEExamScheduleConst.COLUMN_NAME.STATUS,
      value: reportEExamScheduleConst.COLUMN_NAME.STATUS,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamScheduleConst.statusOptions,
    },
    {
      id: 12,
      name: reportEExamScheduleConst.COLUMN_NAME.TIME_NAME,
      value: reportEExamScheduleConst.COLUMN_NAME.TIME_NAME,
      label: reportEExamScheduleConst.COLUMN_NAME_TH.TIME_NAME,
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamScheduleConst.timeNameOptions,
    },
    {
      id: 13,
      name: reportEExamScheduleConst.COLUMN_NAME.SEAT_AVAILABLE,
      value: reportEExamScheduleConst.COLUMN_NAME.SEAT_AVAILABLE,
      label: 'ที่นั่ง',
      minWidth: 160,
      type: reportEExamScheduleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamScheduleConst.seatAvailableOptions,
    },
  ],
}
