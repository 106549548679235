import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportAgentProfileConst = {
  COLUMN_NAME: {
    NO: 'no',
    USER_ID: 'userId',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    PREFIX: 'prefix',
    FIRST_NAME_: 'firstName',
    LAST_NAME: 'lastName',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    AGENT_TYPE: 'agentType',
    LEVEL: 'level',
    POSITION: 'position',
    WORK_START: 'workStart',
    WORK_DISTANCE: 'workDistance',
    LICENSE_NO: 'licenseNo',
    REG_EFFECTIVE_DATE: 'regEffectiveDate',
    REG_RENEWAL_DATE: 'regRenewalDate',
    AGENT_STATUS: 'agentStatus',
    UNIT_NUMBER: 'unitNumber',
    UNIT_NAME: 'unitName',
    RAM_CODE: 'ramCode',
    GA_CODE: 'gaCode',
    DUMMY_AGENT_FLAG: 'dummyAgentFlag',
    AGENCY_CODE: 'agencyCode',
    AGENCY_NAME: 'agencyName',
    ASSISTANT_BRANCH_CODE: 'assistantBranchcode',
    TERRITORY_CODE: 'territoryCode',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    DATE_OF_BIRTH: 'dateOfBirth',
    ID_CARD_NO: 'idCardNo',
    ZIP_CODE: 'zipCode',
    PROVINCE: 'province',
    AMPHURE: 'amphure',
    TAMBON: 'tambon',
    ADDRESS: 'address',
    LAST_LOGIN: 'lastLogin',
    DEVICE: 'device',
    LICENSE_CATEGORY_1: 'licenseCategory_1',
    LICENSE_NAME_1: 'licenseName_1',
    EFFECTIVE_DATE_1: 'effectiveDate_1',
    EXPIRY_DATE_1: 'expiryDate_1',
    LICENSE_CATEGORY_2: 'licenseCategory_2',
    LICENSE_NAME_2: 'licenseName_2',
    EFFECTIVE_DATE_2: 'effectiveDate_2',
    EXPIRY_DATE_2: 'expiryDate_2',
    LICENSE_CATEGORY_3: 'licenseCategory_3',
    LICENSE_NAME_3: 'licenseName_3',
    EFFECTIVE_DATE_3: 'effectiveDate_3',
    EXPIRY_DATE_3: 'expiryDate_3',
    LICENSE_CATEGORY_4: 'licenseCategory_4',
    LICENSE_NAME_4: 'licenseName_4',
    EFFECTIVE_DATE_4: 'effectiveDate_4',
    EXPIRY_DATE_4: 'expiryDate_4',
    LICENSE_CATEGORY_5: 'licenseCategory_5',
    LICENSE_NAME_5: 'licenseName_5',
    EFFECTIVE_DATE_5: 'effectiveDate_5',
    EXPIRY_DATE_5: 'expiryDate_5',
    LICENSE_CATEGORY_6: 'licenseCategory_6',
    LICENSE_NAME_6: 'licenseName_6',
    EFFECTIVE_DATE_6: 'effectiveDate_6',
    EXPIRY_DATE_6: 'expiryDate_6',
    LICENSE_CATEGORY_7: 'licenseCategory_7',
    LICENSE_NAME_7: 'licenseName_7',
    EFFECTIVE_DATE_7: 'effectiveDate_7',
    EXPIRY_DATE_7: 'expiryDate_7',
    LICENSE_CATEGORY_8: 'licenseCategory_8',
    LICENSE_NAME_8: 'licenseName_8',
    EFFECTIVE_DATE_8: 'effectiveDate_8',
    EXPIRY_DATE_8: 'expiryDate_8',
    LICENSE_CATEGORY_9: 'licenseCategory_9',
    LICENSE_NAME_9: 'licenseName_9',
    EFFECTIVE_DATE_9: 'effectiveDate_9',
    EXPIRY_DATE_9: 'expiryDate_9',
    LICENSE_CATEGORY_10: 'licenseCategory_10',
    LICENSE_NAME_10: 'licenseName_10',
    EFFECTIVE_DATE_10: 'effectiveDate_10',
    EXPIRY_DATE_10: 'expiryDate_10',
    //filter column
    SRAM: 'sram',
    LICENSE_CATEGORY: 'licenseCategory',
    LICENSE_NAME: 'licenseName',
    EFFECTIVE_DATE: 'effectiveDate',
    EXPIRY_DATE: 'expiryDate',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    USER_ID: 'User ID',
    FULL_AGENT_CODE: 'รหัส Agent แบบเต็ม',
    AGENT_CODE: 'รหัส Agent',
    PREFIX: 'Title Name EN',
    FIRST_NAME_: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    AGENT_TYPE: 'ประเภท Agent',
    LEVEL: 'Level',
    POSITION: 'Position',
    WORK_START: 'วันเริ่มงาน',
    WORK_DISTANCE: 'อายุงาน',
    LICENSE_NO: 'License No.',
    REG_EFFECTIVE_DATE: 'Reg. Effective Date',
    REG_RENEWAL_DATE: 'Reg. Renewal Date',
    AGENT_STATUS: 'สถานะการทำงาน',
    UNIT_NUMBER: 'Unit Number',
    UNIT_NAME: 'ชื่อ Unit',
    RAM_CODE: 'RAM Code',
    GA_CODE: 'GA Code',
    DUMMY_AGENT_FLAG: 'Dummy Agent Flag',
    AGENCY_CODE: 'Agency Code',
    AGENCY_NAME: 'Agency Name',
    ASSISTANT_BRANCH_CODE: 'Assistant Branch Code',
    TERRITORY_CODE: 'Territory Code',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    DATE_OF_BIRTH: 'วัน/เดือน/ปี เกิด',
    ID_CARD_NO: 'เลขบัตรประชาชน',
    ZIP_CODE: 'รหัสไปรษณีย์',
    PROVINCE: 'จังหวัด',
    AMPHURE: 'อำเภอ/เขต',
    TAMBON: 'แขวง/ตำบล',
    ADDRESS: 'รายละเอียดที่อยู่',
    LAST_LOGIN: 'Last Login',
    DEVICE: 'Device Login',
    LICENSE_CATEGORY_1: 'ประเภทใบอนุญาต 1',
    LICENSE_NAME_1: 'ชื่่อใบอนุญาต 1',
    EFFECTIVE_DATE_1: 'วันมีผลบังคับ 1',
    EXPIRY_DATE_1: 'วันหมดอายุ 1',
    LICENSE_CATEGORY_2: 'ประเภทใบอนุญาต 2',
    LICENSE_NAME_2: 'ชื่่อใบอนุญาต 2',
    EFFECTIVE_DATE_2: 'วันมีผลบังคับ 2',
    EXPIRY_DATE_2: 'วันหมดอายุ 2',
    LICENSE_CATEGORY_3: 'ประเภทใบอนุญาต 3',
    LICENSE_NAME_3: 'ชื่่อใบอนุญาต 3',
    EFFECTIVE_DATE_3: 'วันมีผลบังคับ 3',
    EXPIRY_DATE_3: 'วันหมดอายุ 3',
    LICENSE_CATEGORY_4: 'ประเภทใบอนุญาต 4',
    LICENSE_NAME_4: 'ชื่่อใบอนุญาต 4',
    EFFECTIVE_DATE_4: 'วันมีผลบังคับ 4',
    EXPIRY_DATE_4: 'วันหมดอายุ 4',
    LICENSE_CATEGORY_5: 'ประเภทใบอนุญาต 5',
    LICENSE_NAME_5: 'ชื่่อใบอนุญาต 5',
    EFFECTIVE_DATE_5: 'วันมีผลบังคับ 5',
    EXPIRY_DATE_5: 'วันหมดอายุ 5',
    LICENSE_CATEGORY_6: 'ประเภทใบอนุญาต 6',
    LICENSE_NAME_6: 'ชื่่อใบอนุญาต 6',
    EFFECTIVE_DATE_6: 'วันมีผลบังคับ 6',
    EXPIRY_DATE_6: 'วันหมดอายุ 6',
    LICENSE_CATEGORY_7: 'ประเภทใบอนุญาต 7',
    LICENSE_NAME_7: 'ชื่่อใบอนุญาต 7',
    EFFECTIVE_DATE_7: 'วันมีผลบังคับ 7',
    EXPIRY_DATE_7: 'วันหมดอายุ 7',
    LICENSE_CATEGORY_8: 'ประเภทใบอนุญาต 8',
    LICENSE_NAME_8: 'ชื่่อใบอนุญาต 8',
    EFFECTIVE_DATE_8: 'วันมีผลบังคับ 8',
    EXPIRY_DATE_8: 'วันหมดอายุ 8',
    LICENSE_CATEGORY_9: 'ประเภทใบอนุญาต 9',
    LICENSE_NAME_9: 'ชื่่อใบอนุญาต 9',
    EFFECTIVE_DATE_9: 'วันมีผลบังคับ 9',
    EXPIRY_DATE_9: 'วันหมดอายุ 9',
    LICENSE_CATEGORY_10: 'ประเภทใบอนุญาต 10',
    LICENSE_NAME_10: 'ชื่่อใบอนุญาต 10',
    EFFECTIVE_DATE_10: 'วันมีผลบังคับ 10',
    EXPIRY_DATE_10: 'วันหมดอายุ 10',
    //filter column
    SRAM: 'SRAM',
    LICENSE_CATEGORY: 'ประเภทใบอนุญาต',
    LICENSE_NAME: 'ชื่่อใบอนุญาต',
    EFFECTIVE_DATE: 'วันมีผลบังคับ',
    EXPIRY_DATE: 'วันหมดอายุ',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  agentTypeOptions: [
    { label: 'A: Agent', value: 'A: Agent' },
    { label: 'L: License Broker', value: 'L: License Broker' },
  ],
}

export const reportAgentProfileDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportAgentProfileConst.COLUMN_NAME.NO,
      value: reportAgentProfileConst.COLUMN_NAME.NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportAgentProfileConst.COLUMN_NAME.USER_ID,
      value: reportAgentProfileConst.COLUMN_NAME.USER_ID,
      label: reportAgentProfileConst.COLUMN_NAME_TH.USER_ID,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportAgentProfileConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportAgentProfileConst.COLUMN_NAME.PREFIX,
      value: reportAgentProfileConst.COLUMN_NAME.PREFIX,
      label: reportAgentProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_,
      value: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FIRST_NAME_,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportAgentProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportAgentProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportAgentProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportAgentProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportAgentProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_TYPE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_TYPE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportAgentProfileConst.COLUMN_NAME.LEVEL,
      value: reportAgentProfileConst.COLUMN_NAME.LEVEL,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LEVEL,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportAgentProfileConst.COLUMN_NAME.POSITION,
      value: reportAgentProfileConst.COLUMN_NAME.POSITION,
      label: reportAgentProfileConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportAgentProfileConst.COLUMN_NAME.WORK_START,
      value: reportAgentProfileConst.COLUMN_NAME.WORK_START,
      label: reportAgentProfileConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportAgentProfileConst.COLUMN_NAME.WORK_DISTANCE,
      value: reportAgentProfileConst.COLUMN_NAME.WORK_DISTANCE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.WORK_DISTANCE,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NO,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportAgentProfileConst.COLUMN_NAME.REG_EFFECTIVE_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.REG_EFFECTIVE_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.REG_EFFECTIVE_DATE,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportAgentProfileConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_STATUS,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_STATUS,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_STATUS,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportAgentProfileConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportAgentProfileConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportAgentProfileConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportAgentProfileConst.COLUMN_NAME.UNIT_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.UNIT_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportAgentProfileConst.COLUMN_NAME.RAM_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.RAM_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportAgentProfileConst.COLUMN_NAME.GA_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.GA_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportAgentProfileConst.COLUMN_NAME.DUMMY_AGENT_FLAG,
      value: reportAgentProfileConst.COLUMN_NAME.DUMMY_AGENT_FLAG,
      label: reportAgentProfileConst.COLUMN_NAME_TH.DUMMY_AGENT_FLAG,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportAgentProfileConst.COLUMN_NAME.AGENCY_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENCY_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENCY_CODE,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportAgentProfileConst.COLUMN_NAME.AGENCY_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.AGENCY_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENCY_NAME,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportAgentProfileConst.COLUMN_NAME.ASSISTANT_BRANCH_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.ASSISTANT_BRANCH_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ASSISTANT_BRANCH_CODE,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportAgentProfileConst.COLUMN_NAME.TERRITORY_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.TERRITORY_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.TERRITORY_CODE,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportAgentProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportAgentProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportAgentProfileConst.COLUMN_NAME.EMAIL,
      value: reportAgentProfileConst.COLUMN_NAME.EMAIL,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportAgentProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportAgentProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportAgentProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportAgentProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportAgentProfileConst.COLUMN_NAME.ZIP_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.ZIP_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ZIP_CODE,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportAgentProfileConst.COLUMN_NAME.PROVINCE,
      value: reportAgentProfileConst.COLUMN_NAME.PROVINCE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.PROVINCE,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportAgentProfileConst.COLUMN_NAME.AMPHURE,
      value: reportAgentProfileConst.COLUMN_NAME.AMPHURE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AMPHURE,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportAgentProfileConst.COLUMN_NAME.TAMBON,
      value: reportAgentProfileConst.COLUMN_NAME.TAMBON,
      label: reportAgentProfileConst.COLUMN_NAME_TH.TAMBON,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 37,
      name: reportAgentProfileConst.COLUMN_NAME.ADDRESS,
      value: reportAgentProfileConst.COLUMN_NAME.ADDRESS,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ADDRESS,
      minWidth: 160,
    },
    {
      id: 38,
      name: reportAgentProfileConst.COLUMN_NAME.LAST_LOGIN,
      value: reportAgentProfileConst.COLUMN_NAME.LAST_LOGIN,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LAST_LOGIN,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportAgentProfileConst.COLUMN_NAME.DEVICE,
      value: reportAgentProfileConst.COLUMN_NAME.DEVICE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.DEVICE,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_1,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_1,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_1,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_1,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_1,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_1,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_1,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_1,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_1,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_1,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_1,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_1,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_2,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_2,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_2,
      minWidth: 160,
    },
    {
      id: 45,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_2,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_2,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_2,
      minWidth: 160,
    },
    {
      id: 46,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_2,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_2,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_2,
      minWidth: 160,
    },
    {
      id: 47,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_2,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_2,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_2,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_3,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_3,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_3,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_3,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_3,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_3,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_3,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_3,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_3,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_3,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_3,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_3,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_4,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_4,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_4,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_4,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_4,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_4,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_4,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_4,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_4,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_4,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_4,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_4,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_5,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_5,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_5,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_5,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_5,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_5,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_5,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_5,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_5,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_5,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_5,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_5,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_6,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_6,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_6,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_6,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_6,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_6,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_6,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_6,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_6,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_6,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_6,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_6,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_7,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_7,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_7,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_7,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_7,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_7,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_7,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_7,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_7,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_7,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_7,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_7,
      minWidth: 160,
    },
    {
      id: 68,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_8,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_8,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_8,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_8,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_8,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_8,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_8,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_8,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_8,
      minWidth: 160,
    },
    {
      id: 71,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_8,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_8,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_8,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_9,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_9,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_9,
      minWidth: 160,
    },
    {
      id: 73,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_9,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_9,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_9,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_9,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_9,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_9,
      minWidth: 160,
    },
    {
      id: 75,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_9,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_9,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_9,
      minWidth: 160,
    },
    {
      id: 76,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_10,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY_10,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY_10,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_10,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME_10,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME_10,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_10,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE_10,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE_10,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_10,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE_10,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE_10,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportAgentProfileConst.COLUMN_NAME.USER_ID,
      value: reportAgentProfileConst.COLUMN_NAME.USER_ID,
      label: reportAgentProfileConst.COLUMN_NAME_TH.USER_ID,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportAgentProfileConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportAgentProfileConst.COLUMN_NAME.PREFIX,
      value: reportAgentProfileConst.COLUMN_NAME.PREFIX,
      label: reportAgentProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_,
      value: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FIRST_NAME_,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportAgentProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportAgentProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportAgentProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportAgentProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportAgentProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportAgentProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_TYPE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_TYPE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportAgentProfileConst.agentTypeOptions,
    },
    {
      id: 11,
      name: reportAgentProfileConst.COLUMN_NAME.LEVEL,
      value: reportAgentProfileConst.COLUMN_NAME.LEVEL,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LEVEL,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportAgentProfileConst.COLUMN_NAME.POSITION,
      value: reportAgentProfileConst.COLUMN_NAME.POSITION,
      label: reportAgentProfileConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportAgentProfileConst.COLUMN_NAME.WORK_START,
      value: reportAgentProfileConst.COLUMN_NAME.WORK_START,
      label: reportAgentProfileConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 14,
      name: reportAgentProfileConst.COLUMN_NAME.WORK_DISTANCE,
      value: reportAgentProfileConst.COLUMN_NAME.WORK_DISTANCE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.WORK_DISTANCE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NO,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportAgentProfileConst.COLUMN_NAME.REG_EFFECTIVE_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.REG_EFFECTIVE_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.REG_EFFECTIVE_DATE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 17,
      name: reportAgentProfileConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 18,
      name: reportAgentProfileConst.COLUMN_NAME.AGENT_STATUS,
      value: reportAgentProfileConst.COLUMN_NAME.AGENT_STATUS,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENT_STATUS,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportAgentProfileConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportAgentProfileConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportAgentProfileConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportAgentProfileConst.COLUMN_NAME.UNIT_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.UNIT_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportAgentProfileConst.COLUMN_NAME.SRAM,
      value: reportAgentProfileConst.COLUMN_NAME.SRAM,
      label: reportAgentProfileConst.COLUMN_NAME_TH.SRAM,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 22,
      name: reportAgentProfileConst.COLUMN_NAME.RAM_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.RAM_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportAgentProfileConst.COLUMN_NAME.GA_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.GA_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 24,
      name: reportAgentProfileConst.COLUMN_NAME.DUMMY_AGENT_FLAG,
      value: reportAgentProfileConst.COLUMN_NAME.DUMMY_AGENT_FLAG,
      label: reportAgentProfileConst.COLUMN_NAME_TH.DUMMY_AGENT_FLAG,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 25,
      name: reportAgentProfileConst.COLUMN_NAME.AGENCY_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.AGENCY_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENCY_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 26,
      name: reportAgentProfileConst.COLUMN_NAME.AGENCY_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.AGENCY_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.AGENCY_NAME,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 27,
      name: reportAgentProfileConst.COLUMN_NAME.ASSISTANT_BRANCH_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.ASSISTANT_BRANCH_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ASSISTANT_BRANCH_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 28,
      name: reportAgentProfileConst.COLUMN_NAME.TERRITORY_CODE,
      value: reportAgentProfileConst.COLUMN_NAME.TERRITORY_CODE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.TERRITORY_CODE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportAgentProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportAgentProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 30,
      name: reportAgentProfileConst.COLUMN_NAME.EMAIL,
      value: reportAgentProfileConst.COLUMN_NAME.EMAIL,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportAgentProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportAgentProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportAgentProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 32,
      name: reportAgentProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportAgentProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportAgentProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 33,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_CATEGORY,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAgentProfileConst.COLUMN_NAME.LICENSE_CATEGORY,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 34,
      name: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME,
      value: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME,
      label: reportAgentProfileConst.COLUMN_NAME_TH.LICENSE_NAME,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAgentProfileConst.COLUMN_NAME.LICENSE_NAME,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 35,
      name: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.EFFECTIVE_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EFFECTIVE_DATE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 36,
      name: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE,
      value: reportAgentProfileConst.COLUMN_NAME.EXPIRY_DATE,
      label: reportAgentProfileConst.COLUMN_NAME_TH.EXPIRY_DATE,
      minWidth: 160,
      type: reportAgentProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
