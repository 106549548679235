import { TYPE_CLASS, WORK_TYPE } from 'src/constants/report/monthlyPlan'
import { themeColor } from '../../../utils/themeData'
import { headCellsDetails } from 'src/modules/MonthlyPlan/WorkloadDashboard/model'
import { positionOptions } from '../../../constants/report/monthlyPlan'
import _ from 'lodash'

export const initialState = {
  error: null,
  isLoading: false,
  staffList: [],
  report: {
    isLoading: false,
    tabActive: 0,
    headCellsReport: [],
    reportsData: [],
    filters: [],
  },
  dashboard: {
    isLoading: false,
    isOpenFilterDrawer: false,
    isOpenClassDrawer: false,
    isOpenStaffDrawer: false,
    latestUpdated: '',
    classType: '',
    name: '',
    filters: {
      startDate: '',
      endDate: '',
      dateChecked: false,
      displayDate: false,
      departmentChecked: false,
      department: [],
      departmentList: [],
      stationChecked: false,
      stationList: [],
      stationOptios: [],
      defaultStation: [],
      defaultDepartment: [],
      courseCodeChecked: false,
      courseCode: [],
      positionChecked: false,
      position: [],
      positionOptions: _.filter(
        positionOptions,
        (position) => position.value !== 'All',
      ),
      filterTotal: 0,
    },
    displayFilter: {},
    filtersList: [],
    allClass: 0,
    classroomClass: 0,
    onlineClass: 0,
    classStatusDetails: [
      {
        statusClassTH: 'กำลังเปิดรับสมัคร',
        statusClass: 'Open regis',
        fontColor: themeColor.common.white,
        color: themeColor.graph.green1,
        bgColor: themeColor.graph.green1Light,
        key: TYPE_CLASS.OPEN_REGISTRATION,
      },
      {
        statusClassTH: 'รอการอบรม',
        statusClass: 'Closed regis',
        color: themeColor.graph.yellow1,
        bgColor: themeColor.graph.yellow1Light,
        key: TYPE_CLASS.CLOSE_REGISTRATION,
      },
      {
        statusClassTH: 'กำลังอบรม',
        statusClass: 'Start class',
        fontColor: themeColor.common.white,
        color: themeColor.blue.oceanBlue,
        bgColor: themeColor.graph.blue1Light,
        key: TYPE_CLASS.START_CLASS,
      },
      {
        statusClassTH: 'ยกเลิก',
        statusClass: 'Cancel',
        fontColor: themeColor.common.white,
        color: themeColor.error.light,
        bgColor: themeColor.graph.red1Light,
        key: TYPE_CLASS.CANCELED,
      },
      {
        statusClassTH: 'อื่นๆ',
        statusClass: 'Unassigned, To do',
        color: themeColor.graph.grey1,
        bgColor: themeColor.graph.grey1Light,
        key: TYPE_CLASS.OTHER,
      },
      {
        statusClassTH: 'End Class',
        statusClass: 'End Class',
        fontColor: themeColor.common.white,
        color: themeColor.graph.orange1,
        bgColor: themeColor.graph.orange1Light,
        key: TYPE_CLASS.END_CLASS,
      },
      {
        statusClassTH: 'Closed Class',
        statusClass: 'Closed Class',
        color: themeColor.graph.purple2,
        bgColor: themeColor.graph.purple1Light,
        key: TYPE_CLASS.CLOSED,
      },
    ],
    academyActivityList: [
      {
        listName: 'รายการ',
        count: '(ครั้ง)',
      },
      {
        key: WORK_TYPE.OFFICE_WORK,
        listName: 'Office work',
      },
      {
        key: WORK_TYPE.CERTIFIED,
        listName: 'Certified',
      },
      {
        key: WORK_TYPE.TRAVELING,
        listName: 'Traveling',
      },
      {
        key: WORK_TYPE.EXTERNAL_TRAINING,
        listName: 'External training',
      },
      {
        key: WORK_TYPE.ACADEMY_TRAINING,
        listName: 'Academy training',
      },
      {
        key: WORK_TYPE.MEETING,
        listName: 'Meeting',
      },
      { key: WORK_TYPE.OTHER_ACTIVITY, listName: 'Other Activities' },
    ],
    activitySupportList: [
      {
        listName: 'รายการ',
        count: '(ครั้ง)',
      },
    ],
    //for workload
    tabOverviewActive: 0,
    tabSumWorkloadActive: 0,
    table: {
      headCells: headCellsDetails,
      allCount: 0,
      details: [],
      tablePage: 0,
      page: -1,
      rowsPerPage: { label: '100', value: 100 },
    },
    isExpand: false,
    workTypeData: {},
    topListType: ['SPEAKER', 'WORKLOAD', 'LEAVE'],
    searchText: '',
    errors: {},
  },
}
