import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Box from '@mui/material/Box'
import _ from 'lodash'

const BarChart = ({ dataSet, labels, height = '100%' }) => {
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }

    const data = _.get(dataSet, 'data', [])
    const maxData = Math.max(...data)
    const hasZero = _.some(data, (val) => val === 0)
    const adjustedDataSet = {
      ...dataSet,
      data: _.map(data, (value) => {
        if (value === 0) {
          return 1
        } else if (hasZero) {
          return (value * 100) / maxData
        } else {
          return value
        }
      }),
    }

    const ctx = document.getElementById('barChart')
    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            borderRadius: 5,
            barPercentage: 1,
            categoryPercentage: 0.95,
            ...adjustedDataSet,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 50,
          },
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                weight: 'bold',
              },
            },
          },
          yAxis: {
            display: false,
            min: 0,
            max: hasZero ? 100 : maxData,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value, context) => {
              return data[context.dataIndex]
            },
            font: {
              weight: 'bold',
            },
          },
        },
      },
    })
    setChart(newChart)
  }, [dataSet, labels])
  return (
    <Box sx={{ height: height }}>
      <canvas id="barChart" />
    </Box>
  )
}

export default BarChart
