import _ from 'lodash'
import { setReduxValue } from '.'
import { store } from '../../../App'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const handleColumnOptions = () => (dispatch) => {
  const { isOpenFilterDrawer, defaultColumns, filterColumnOptions } =
    store.getState().reportComp
  const options = isOpenFilterDrawer ? filterColumnOptions : defaultColumns
  const sortedColumns = handleSortColumns([...options])

  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}

export const handleSortColumns = (columns) => {
  const collator = new Intl.Collator([], { numeric: true })
  return columns.sort((a, b) => collator.compare(a?.label, b?.label))
}

export const handleDisabledColumnOptions = () => (dispatch) => {
  const { displayColumns, columnOptions } = store.getState().reportComp
  const idColumnArr = _.map(displayColumns, 'name')
  const newColumnOptions = _.map(columnOptions, (column) => {
    let disabled = _.includes(idColumnArr, column?.name)

    return { ...column, disabled: disabled }
  })

  const sortedColumns = handleSortColumns(newColumnOptions)
  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}

export const handleBodyFilters = () => {
  const { columnName, filters, reportConfig } = store.getState().reportComp

  let formattedKeyFilters = []
  formattedKeyFilters = _.map(filters, (item) => {
    if (item.key === columnName.ABBREVIATION_DISTRIBUTION) {
      return { ...item, key: columnName.DISTRIBUTION }
    }
    if (item.key === columnName.ABBREVIATION_ROADMAP) {
      return { ...item, key: columnName.ROADMAP }
    }
    return item
  })

  const groupedByKey = _.groupBy(formattedKeyFilters, 'key')

  const bodyFilters = _.reduce(
    groupedByKey,
    (acc, filters, key) => {
      let newFilters = filters
      if (
        key === columnName.DISTRIBUTION ||
        key === columnName.ABBREVIATION_DISTRIBUTION ||
        key === columnName.ROADMAP ||
        key === columnName.ABBREVIATION_ROADMAP ||
        key === columnName.COURSE_LEVEL_CONTROL
      ) {
        newFilters = _.reduce(
          filters,
          (acc, filter) => {
            let existingItem = _.find(acc, (item) => item.key === filter.key)
            if (existingItem) {
              existingItem = {
                ...existingItem,
                value: [...existingItem.value, ...filter.value],
              }
              acc = acc.map((item) =>
                item.key === existingItem.key ? existingItem : item,
              )
            } else {
              acc.push(filter)
            }
            return acc
          },
          [],
        )
      }

      if (key === columnName.TRAINING_DATE) {
        newFilters = _.map(filters, (filter) => {
          return {
            ...filter,
            value: {
              startDate: dayjs(filter.value.startDate)
                .utc(true)
                .format('YYYY-MM-DD'),
              endDate: dayjs(filter.value.endDate)
                .utc(true)
                .format('YYYY-MM-DD'),
            },
          }
        })
      }

      return [...acc, ...newFilters]
    },
    [],
  )

  const ignoreKeyChange = [
    'EXPORT_BY_CLASS',
    'EXPORT_BY_LEARNER',
    'REPORT_FOR_HF',
  ]
  if (ignoreKeyChange.includes(_.get(reportConfig, 'moduleId', ''))) {
    return filters
  }
  return bodyFilters
}

export const handleResetState = (state) => {
  state.reportConfig = {
    moduleId: '',
    modulePath: '',
  }
  state.isLoading = false
  state.isLoadingDrawer = false
  state.isOpenFilterDrawer = false
  state.isOpenManageColumnDrawer = false
  state.tabActiveColDrawer = 0
  state.tabActiveColCard = 0
  state.filterColumnOptions = []
  state.columns = []
  state.filters = []
  state.bodyFilters = []
  state.onDownload = null
  state.displayFilters = []
  state.reportsData = []
  state.tablePage = 0
  state.page = -1
  state.rowsPerPage = { label: '100', value: 100 }
  state.allCount = 0
  state.columnSelected = null
  state.value = ''
  state.error = ''
  state.isFilter = false
  state.disabledAddBtn = true
  state.newColumnName = null
  state.firstColumnSelected = null
  state.secondaryColumnSelected = null
  state.defaultColumns = []
  state.columnName = null
  state.columnNameTH = null
  state.hasSortedColumns = false
  state.options = []
  state.isInitial = true
}
