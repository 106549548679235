import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import {
  StyledDrawer,
  StyledContentDrawer,
  StyledFooter,
  StyledHeadLabel,
} from '../../Styled'
import { setIsOpenFilterDrawer } from '../../../../redux/slices/reportComp'
import FilterListCard from './FilterListCard'
import AddFilterSection from './AddFilterSection'
import { handleConfirmCancelDraft } from '../../handler/handleConfirmCancelDraft'
import { handleSaveFilters } from '../../handler/handleSaveFilters'
import _ from 'lodash'

const FilterDrawer = ({ handleFilter }) => {
  const dispatch = useDispatch()
  const {
    displayFilters,
    isOpenFilterDrawer,
    disabledAddBtn,
    columnName,
    moduleId,
  } = useSelector(
    (state) => ({
      displayFilters: state.reportComp.displayFilters,
      isOpenFilterDrawer: state.reportComp.isOpenFilterDrawer,
      disabledAddBtn: state.reportComp.disabledAddBtn,
      columnName: state.reportComp.columnName,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )
  const requiredFilters = _.every(
    [columnName?.TRAINING_DATE, columnName?.DEPARTMENT],
    (key) => _.some(displayFilters, { key }),
  )
  const isRequired =
    _.includes(['MP_WORKLOAD', 'MP_YEARLY'], moduleId) && requiredFilters

  const disabledConfirmBtn = _.includes(['MP_WORKLOAD', 'MP_YEARLY'], moduleId)
    ? !isRequired
    : !disabledAddBtn

  return (
    <StyledDrawer
      open={isOpenFilterDrawer}
      onClose={() => dispatch(setIsOpenFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
      </StyledHeadLabel>
      <StyledContentDrawer>
        <AddFilterSection />
        <Divider />
        {displayFilters?.length > 0 && <FilterListCard />}
      </StyledContentDrawer>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => handleConfirmCancelDraft()}
        >
          ยกเลิก
        </Button>
        <Button
          data-testid="btn-submit"
          variant="contained"
          disabled={disabledConfirmBtn}
          onClick={() => {
            dispatch(setIsOpenFilterDrawer(false))
            if (handleFilter) {
              handleFilter()
            } else {
              dispatch(handleSaveFilters())
            }
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default FilterDrawer
