import {
  defaultColumns,
  filterColumnOptions,
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from './courseSetting'
import { reportClassConst, reportClassDefaultColumn } from './ReportClass'
import {
  reportExamEnrollConst,
  reportExamEnrollDefaultColumn,
} from './ReportExamEnroll'
import {
  reportExportByLearnerConst,
  reportExportByLearnerDefaultColumn,
} from './ReportExportByLearner'
import { reportForHFConst, reportForHFDefaultColumn } from './ReportForHF'
import {
  reportEExamResultConst,
  reportEExamResultDefaultColumn,
} from './ReportEExamResult'
import {
  reportWorkloadTOConst,
  reportWorkloadTODefaultColumn,
} from './ReportWorkloadTO'
import {
  reportEExamScheduleConst,
  reportEExamScheduleDefaultColumn,
} from './ReportEExamSchedule'
import {
  defaultColumns as defaultColumns_MP,
  filterColumnOptions as filterColumnOptions_MP,
  COLUMN_NAME as COLUMN_NAME_MP,
  COLUMN_NAME_TH as COLUMN_NAME_TH_MP,
} from './monthlyPlan'
import {
  reportETestingRawDataByClassConst,
  reportETestingRawDataByClassDefaultColumn,
} from './ReportETestingRawDataByClass'
import {
  HandleLearnerExamQuestion,
  reportETestingRawDataByLearnerConst,
  reportETestingRawDataByLearnerDefaultColumn,
} from './ReportETestingRawDataByLearner'
import {
  reportAgentProfileConst,
  reportAgentProfileDefaultColumn,
} from './ReportManageAgentProfile'
import {
  reportProspectProfileConst,
  reportProspectProfileDefaultColumn,
} from './ReportManageProspectProfile'
import {
  reportStaffProfileConst,
  reportStaffProfileDefaultColumn,
} from './ReportManageStaffProfile'

export const REPORTS_CONST = {
  COURSE_SETTING: {
    defaultColumns,
    filterColumnOptions: filterColumnOptions,
    COLUMN_NAME,
    COLUMN_NAME_TH,
  },
  EXPORT_BY_CLASS: {
    defaultColumns: reportClassDefaultColumn.defaultColumns,
    filterColumnOptions: reportClassDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportClassConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportClassConst.COLUMN_NAME_TH,
  },
  EXPORT_BY_LEARNER: {
    defaultColumns: reportExportByLearnerDefaultColumn.defaultColumns,
    filterColumnOptions: reportExportByLearnerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportExportByLearnerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportExportByLearnerConst.COLUMN_NAME_TH,
  },
  REPORT_FOR_HF: {
    defaultColumns: reportForHFDefaultColumn.defaultColumns,
    filterColumnOptions: reportForHFDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportForHFConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportForHFConst.COLUMN_NAME_TH,
  },
  REPORT_WORKLOAD_TO: {
    defaultColumns: reportWorkloadTODefaultColumn.defaultColumns,
    filterColumnOptions: reportWorkloadTODefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportWorkloadTOConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportWorkloadTOConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_RESULT: {
    defaultColumns: reportEExamResultDefaultColumn.defaultColumns,
    filterColumnOptions: reportEExamResultDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEExamResultConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEExamResultConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_ENROLL: {
    defaultColumns: reportExamEnrollDefaultColumn.defaultColumns,
    filterColumnOptions: reportExamEnrollDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportExamEnrollConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportExamEnrollConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_SCHEDULE: {
    defaultColumns: reportEExamScheduleDefaultColumn.defaultColumns,
    filterColumnOptions: reportEExamScheduleDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEExamScheduleConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEExamScheduleConst.COLUMN_NAME_TH,
  },
  MP_WORKLOAD: {
    defaultColumns: defaultColumns_MP,
    filterColumnOptions: filterColumnOptions_MP,
    COLUMN_NAME: COLUMN_NAME_MP,
    COLUMN_NAME_TH: COLUMN_NAME_TH_MP,
  },
  MP_YEARLY: {
    defaultColumns: defaultColumns_MP,
    filterColumnOptions: filterColumnOptions_MP,
    COLUMN_NAME: COLUMN_NAME_MP,
    COLUMN_NAME_TH: COLUMN_NAME_TH_MP,
  },
  REPORT_E_TESTING_RAW_DATA_BY_CLASS: {
    defaultColumns: reportETestingRawDataByClassDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportETestingRawDataByClassDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportETestingRawDataByClassConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportETestingRawDataByClassConst.COLUMN_NAME_TH,
  },
  REPORT_E_TESTING_RAW_DATA_BY_LEARNER: {
    defaultColumns: await HandleLearnerExamQuestion(),
    filterColumnOptions:
      reportETestingRawDataByLearnerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportETestingRawDataByLearnerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH,
  },
  REPORT_AGENT_PROFILE: {
    defaultColumns: reportAgentProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportAgentProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportAgentProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAgentProfileConst.COLUMN_NAME_TH,
  },
  REPORT_PROSPECT_PROFILE: {
    defaultColumns: reportProspectProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportProspectProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportProspectProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportProspectProfileConst.COLUMN_NAME_TH,
  },
  REPORT_STAFF_PROFILE: {
    defaultColumns: reportStaffProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportStaffProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportStaffProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportStaffProfileConst.COLUMN_NAME_TH,
  },
}
