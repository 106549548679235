import { FileDownloadOutlined } from '@mui/icons-material'
import { Box, Typography, Button } from '@mui/material'
import SearchCustom from '../SearchCustom'

const SearchAndDownloadToolbar = () => {
  return (
    <Box
      display="flex"
      p={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchCustom />
      <Button
        data-testid="btn-download"
        variant="text"
        size="m"
        startIcon={<FileDownloadOutlined />}
        //   onClick={() => download()}
      >
        <Typography variant="button">ดาวน์โหลด</Typography>
      </Button>
    </Box>
  )
}
export default SearchAndDownloadToolbar
