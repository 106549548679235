import _ from 'lodash'
import { callAPI } from '../../../../../utils/lib'
import { stationFilter } from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { fetchOverview } from '../../handler/fetchOverview'
import dayjs from 'dayjs'
import { handleSetIsOpenFilterDrawer } from '../../handler/handleFilterDrawer'

export const handleFiltering =
  (filter, station) => (dispatch) => {
    const stationList = []
    if (filter.stationChecked) {
      for (const key in filter.station) {
        if (!filter.station[key]) continue
        const item = station.find((e) => e.name === key)
        stationList.push(item)
      }
    }
    const isFilterDate = filter.startDate && filter.endDate
    const isFilterStation = stationList.length > 0
    const user = JSON.parse(localStorage.getItem('user'))
    const userStation = _.get(user, 'area[0].station')
    let countFilterTotal = 0
    if (isFilterStation) ++countFilterTotal
    if (isFilterDate) ++countFilterTotal
    dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters',
        value: {
          station: isFilterStation
            ? stationList
            : [
                {
                  label: userStation.station,
                  name: `${userStation.station.toLowerCase()}Checked`,
                  uuid: userStation.uuid,
                },
              ],
          startDate: isFilterDate
            ? dayjs(filter.startDate).format('YYYY-MM-DD')
            : dayjs().startOf('month').format('YYYY-MM-DD'),
          endDate: isFilterDate ? dayjs(filter.endDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
          filterTotal: countFilterTotal,
          isFilterStation: isFilterStation,
          isFilterDate: isFilterDate,
        },
      }),
    )
    handleSetIsOpenFilterDrawer(false)
    fetchOverview()
  }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: item.station,
        name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
        uuid: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: false,
      startDate: '',
      endDate: '',
      displayDate: false,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: false,
      station: undefined,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
