import React from 'react'
import { TableRow, TableCell } from '@mui/material'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import Truncate from './Truncate'

const TableReportRow = ({ report }) => {
  const { columns } = useSelector(
    (state) => ({
      columns: state.reportComp.columns,
    }),
    shallowEqual,
  )

  return (
    <TableRow tabIndex={-1} key={report.id}>
      {_.map(columns, (column) => {
        const value =
          !_.isNull(report[column.name]) &&
          report[column.name] !== undefined &&
          report[column.name] !== ''
            ? report[column.name]
            : '-'

        return (
          <TableCell
            key={column?.id}
            align={column?.align}
            style={{
              minWidth: column?.minWidth,
            }}
          >
            <Truncate align={column?.align}>{'' + value}</Truncate>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default TableReportRow
