import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import { headerTopFive } from '../model'
import _ from 'lodash'
import { ChevronRightOutlined } from '@mui/icons-material'
import { handleOpenStaffDrawer } from '../handler/handleOpenStaffDrawer'
import { TOP_LIST_TYPE_LABEL } from 'src/constants/monthlyPlan/dashboard'

const TopFiveList = ({ type = '', list = [] }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.blue.blueLine}`,
        borderRadius: 4,
        p: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.25,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1b">
          Top 5 รายชื่อ
          {`${
            type === 'SPEAKER' ? 'หน้าที่' : type === 'WORKLOAD' ? 'ที่มี' : ''
          } ${TOP_LIST_TYPE_LABEL[type]} ${
            type !== 'SPEAKER' ? 'สูงสุด (ทุกประเภท)' : 'มากที่สุด'
          }`}
        </Typography>
        <IconButton
          sx={{
            backgroundColor: theme.palette?.primary?.outlinedHoverBackground,
          }}
          onClick={() => handleOpenStaffDrawer(type)}
        >
          <ChevronRightOutlined />
        </IconButton>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          '& > .MuiGrid-item:first-of-type': {
            pl: 3.5,
          },
        }}
      >
        {_.map(headerTopFive, (header, idx) => {
          return (
            <Grid item key={idx} md={header?.grid}>
              <Typography
                variant="table.header"
                color="text.lightGray"
                sx={{ wordBreak: 'nowrap' }}
              >
                {header.label}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
      {_.map(list, (li, idx) => {
        return (
          <Grid
            container
            sx={{
              backgroundColor: '#F1F4FA',
              borderRadius: 4,
              py: 0.5,
              '& > .MuiGrid-item:first-of-type': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '.MuiTypography-root': {
                  color: theme.palette.text.white,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.caption.fontSize,
                  fontWeight: theme.typography.caption.fontWeight,
                },
              },
            }}
          >
            {_.map(headerTopFive, (header, idxH) => {
              return (
                <Grid
                  item
                  key={idx}
                  md={header?.grid}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Tooltip
                    title={
                      li[header.id]?.length > 16 ? (
                        <Typography variant="tooltip">
                          {li[header.id]}
                        </Typography>
                      ) : (
                        ''
                      )
                    }
                    arrow
                    placement="top"
                  >
                    <Typography
                      key={idxH}
                      sx={{
                        width: header.width ? header.width : 'auto',
                        px: 0.75,
                        whiteSpace: 'nowrap',
                      }}
                      variant="body2b"
                      color="primary"
                    >
                      {li[header.id]}
                    </Typography>
                  </Tooltip>
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </Box>
  )
}

export default TopFiveList
