import React from 'react'
import { Box, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomCheckbox } from '../../../../../../components/CRUD/components/CustomCheckbox'
import { StyledBodyCell } from './Styled'
import {
  convertNumberWithComma,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import DropdownButton from '../DropdownButton'
import _ from 'lodash'
import { StatusComponent } from '../../components/StatusComponent'
import { statusColorEnum } from '../../enum/statusEnum'
import SearchCustom from './Search'
import { viewTypeEnum } from '../../enum/viewTypeEnum'
import { RequisitionStatus } from '../../../../../../constants/stock'
import Truncate from '../../../../../../components/redux/Table/Truncate'

export const AssetList = () => {
  const { assets, searchAsset, viewType, status } = useSelector(
    (state) => ({
      assets: state.crud.formData.assets,
      searchAsset: state.crud?.searchAsset,
      viewType: state.crud.viewType,
      status: state.crud.formData?.status,
    }),
    shallowEqual,
  )

  const isRequisitionCompleted = [
    RequisitionStatus.COMPLETED,
    RequisitionStatus.COMPLETED_WITH_CONDITION,
  ].includes(status)

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" mb={2}>
          รายการเบิกทรัพย์สิน
        </Typography>
        <SearchCustom />
      </Box>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={20}>No.</TableCell>
              <TableCell width={150}>สถานะการเบิก</TableCell>
              <TableCell width={150}>กำหนดคืน</TableCell>
              <TableCell width={350}>ชื่อทรัพย์สิน</TableCell>
              <TableCell width={350}>รหัสทรัพย์สิน</TableCell>
              <TableCell width={150}>จำนวนเบิก</TableCell>
              <TableCell width={150}>จำนวนคืน</TableCell>
              <TableCell>คำสั่ง</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets &&
              assets.map((item, index) => {
                const isSelected = _.get(item, 'value', false)
                const checkHaveAssetCode = _.get(item, 'assetCode', false)
                let statusDotsx = {}
                if (
                  item?.withdrawStatus &&
                  statusColorEnum?.[item?.withdrawStatus]
                ) {
                  statusDotsx = {
                    ...statusDotsx,
                    backgroundColor: statusColorEnum?.[item?.withdrawStatus],
                  }
                }
                if (searchAsset && searchAsset != '' && item?.value) {
                  if (
                    !item?.label
                      .toUpperCase()
                      .includes(searchAsset.toUpperCase())
                  )
                    return <></>
                }
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {StatusComponent(
                        { status: item?.withdrawStatus ?? '' },
                        statusDotsx,
                      )}
                    </TableCell>
                    <StyledBodyCell
                      isActive={
                        !_.isEmpty(item?.returnDate) && !isRequisitionCompleted
                      }
                    >
                      {!isRequisitionCompleted && (
                        <Typography color={item?.returnDateColor}>
                          {item?.returnDate ?? ''}
                        </Typography>
                      )}
                    </StyledBodyCell>
                    <TableCell>
                      <Truncate>{'' + (item?.label ?? ' ')}</Truncate>
                    </TableCell>
                    <StyledBodyCell isActive={checkHaveAssetCode}>
                      {checkHaveAssetCode && (
                        <>{item?.assetCode?.label ?? ''}</>
                      )}
                    </StyledBodyCell>
                    <StyledBodyCell
                      isActive={isSelected && !checkHaveAssetCode}
                    >
                      {isSelected && !checkHaveAssetCode && (
                        <>{convertNumberWithComma(item?.qty ?? '')}</>
                      )}
                    </StyledBodyCell>
                    <StyledBodyCell
                      isActive={
                        viewType == viewTypeEnum.RETURN || item?.returnQty > 0
                      }
                    >
                      {/* จำนวนคืน */}
                      {viewType !== viewTypeEnum.RETURN &&
                      item?.returnQty &&
                      !checkHaveAssetCode
                        ? item?.returnQty
                        : ''}
                      {checkHaveAssetCode && (
                        <CustomCheckbox
                          fieldName={`assets[${index}].returnCheckbox`}
                          disabled={true}
                        />
                      )}
                      {viewType === viewTypeEnum.RETURN &&
                        !checkHaveAssetCode && (
                          <CustomInput
                            fieldName={`assets[${index}].returnQty`}
                            labelName=""
                            placeholder="ระบุจำนวน"
                            inputProps={{
                              'data-testid': 'input-qty',
                              onInput: (e) => {
                                handleInputNumber(e, regex.NUMBER)
                              },
                              onKeyDown: (e) => {
                                handleNumberKeyDown(e, ['.'])
                              },
                              onPaste: handlePasteFormat,
                            }}
                            disabled={
                              item?.product?.statusFlagReturn === 'ACTIVE'
                            }
                            sxTextError={{ height: 'auto' }}
                          />
                        )}
                    </StyledBodyCell>
                    <TableCell
                      align="center"
                      sx={{ pointerEvents: isSelected ? '' : 'none' }}
                    >
                      <DropdownButton item={item} itemIndex={index} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
