import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../../redux/slices/dialog'
import {
  setFieldValue,
  replaceFieldError,
  setFieldError,
} from '../../../../../../redux/slices/crud'
import { Box } from '@mui/material'
import { CustomTextArea } from '../../../../../../components/CRUD/components/CustomTextArea'
import * as yup from 'yup'
import { store } from '../../../../../../App'
import {
  productSkuStatus,
  productSkuUpdateStatus,
} from '../../../../../../utils/apiPath'
import callAxios from '../../../../../../utils/baseService'
import { STOCK_STATUS } from '../../../../../../constants/stock'
import _ from 'lodash'

export const stockSKUOptions = [
  { text: 'เพิ่มรหัสทรัพย์สิน', value: 'add_ticket' },
]

export const historyOptions = [
  { text: 'รายละเอียด Ticket', value: 'view_ticket' },
]

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}

export const AssetListAssetOptions = [
  { text: 'รายละเอียด Ticket', value: 'view_ticket' },
  { text: 'รายละเอียดรหัสทรัพย์สิน', value: 'view_asset' },
  { text: 'ประวัติการเคลื่อนไหว รหัสทรัพย์สิน', value: 'history' },
  { text: 'ลบรหัสทรัพย์สิน', value: 'del_asset' },
]

export const openDeleteDialog = (item) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        deleteReason: '',
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'ยืนยันลบรหัสทรัพย์สิน',
      agreeText: 'บันทึก',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <CustomTextArea required fieldName="deleteReason" labelName="เหตุผล" />
        </Box>
      ),
      handleConfirm: () => {
        handleConfirmDeleteDialog(item)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const handleConfirmDeleteDialog = async (item) => {
  const { formData } = store.getState().crud
  const isValid = await validateData(formValidationSchema)
  if (isValid) {
    store.dispatch(loadingDialog())
    await callAxios
      .put(productSkuUpdateStatus, {
        skuWarehouseUuid: item.uuid,
        status: STOCK_STATUS.DELETED,
        remark: _.get(formData, 'deleteReason'),
      })
      .then(() => {
        store.dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              store.dispatch(closeDialog())
              store.dispatch(
                setFieldValue({
                  key: 'isReloadAssetListDrawer',
                  value: true,
                }),
              )
            },
          }),
        )
      })
      .catch(() => {
        store.dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ระบบขัดข้อง',
          }),
        )
      })
  }
}

export const formValidationSchema = yup.object({
  deleteReason: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุภายใน 3000 ตัวอักษร')
    .required('กรุณาระบุสาเหตุ'),
})

export const openAssetHistoryDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetHistoryDrawer',
      value: true,
    }),
  )
  setAssetHistoryDrawerInfo(row)
}

export const setAssetHistoryDrawerInfo = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'assetHistoryDrawerInfo',
      value: row,
    }),
  )
}

export const setAssetDrawerInfo = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'assetDrawerInfo',
      value: row,
    }),
  )
}

export const openAssetDetailDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetDetailDrawer',
      value: true,
    }),
  )
  setAssetDetailDrawer(row)
}

export const setAssetDetailDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'assetDetailDrawer',
      value: row,
    }),
  )
}

export const enabledAsset = async (value, item) => {
  const status = value === 'enabled' ? 'ACTIVE' : 'INACTIVE'
  const body = {
    status,
    skuWarehouseUuid: item.uuid,
  }
  await callAxios
    .put(productSkuStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            store.dispatch(
              setFieldValue({
                key: 'isReloadAssetListDrawer',
                value: true,
              }),
            )
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}
