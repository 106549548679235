import { shallowEqual, useDispatch } from 'react-redux'
import { BoxSearch, SearchInput } from '../../../../Styled'
import { Search } from '@mui/icons-material'
import { setReduxDashboardByKey } from '../../../../../../redux/slices/monthlyPlan'
import { useSelector } from 'react-redux'
import { fetchOverviewWorkload } from '../../../handler/fetchOverviewWorkload'

const SearchCustom = () => {
  const dispatch = useDispatch()
  const { searchText } = useSelector(
    (state) => ({
      searchText: state.monthlyPlan.dashboard.searchText,
    }),
    shallowEqual,
  )

  return (
    <BoxSearch>
      <SearchInput
        placeholder="ค้นหา"
        onChange={(e) =>
          dispatch(
            setReduxDashboardByKey({
              key: 'searchText',
              value: e.target.value,
            }),
          )
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            fetchOverviewWorkload()
          }
        }}
        value={searchText}
      />
      <Search
        sx={{
          width: 20,
          height: 20,
          cursor: 'pointer',
          position: 'absolute',
          mt: 1,
        }}
        color="primary"
        onClick={() => fetchOverviewWorkload()}
      />
    </BoxSearch>
  )
}
export default SearchCustom
