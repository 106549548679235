import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { StyledBodyCell } from './Styled'
import { handleSelectAsset } from '../../handler/handleSelectAsset'
import {
  convertNumberWithComma,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import { handleAddAsset } from '../../handler/handleAddAsset'
import DropdownButton from '../DropdownButton'
import _ from 'lodash'
import { useTheme } from '@emotion/react'
import { PRODUCT_TYPE, STOCK_STATUS } from '../../../../../../constants/stock'

export const AssetList = ({ uuid }) => {
  const { assets, crudConfig, assetsError, formErrors, assetsData } =
    useSelector(
      (state) => ({
        assets: state.crud.formData.assets,
        assetsData: state.crud.masterData.assets,
        crudConfig: state.crud.crudConfig,
        assetsError: _.find(state.crud.formErrors, { path: 'assets' }),
        formErrors: state.crud.formErrors,
      }),
      shallowEqual,
    )
  const theme = useTheme()
  let assetOptions = { isLoading: false, options: [] }
  if (assetsData) {
    assetOptions = {
      isLoading: false,
      options: assetsData?.options.filter((item) => {
        if (item?.productType === PRODUCT_TYPE.STOCK && assets?.length > 0) {
          const selectedProductStock = assets.find((item2) => {
            return item2?.value === item?.value
          })
          if (selectedProductStock) return false
        }
        return true
      }),
    }
  }

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        รายการทรัพย์สิน
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          mb: 3,
          border: !_.isEmpty(assetsError)
            ? `solid 1px ${theme.palette.error.main}`
            : '',
        }}
        id={`${crudConfig.moduleSlug}-assets`}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={20}>No.</TableCell>
              <TableCell width={350}>ชื่อทรัพย์สิน</TableCell>
              <TableCell width={350}>รหัสทรัพย์สิน</TableCell>
              <TableCell width={150}>จำนวนเบิก</TableCell>
              <TableCell>จำนวนพร้อมใช้งานก่อนเบิก</TableCell>
              <TableCell>สถานะคงเหลือ</TableCell>
              <TableCell>คำสั่ง</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets &&
              assets.map((item, index) => {
                const isSelected = _.get(item, 'value', false)
                const checkHaveAssetCode =
                  item?.assetCodeOption && item?.assetCodeOption.length > 0
                const assetCodeOption = checkHaveAssetCode
                  ? {
                      isLoading: false,
                      options: item?.assetCodeOption.filter((sku) => {
                        let checkIsSelected = false
                        if (
                          item?.productType === PRODUCT_TYPE.STOCK_ASSET &&
                          assets?.length > 0
                        ) {
                          const selectedProductSku = assets.find((item2) => {
                            return (
                              item2?.value === item?.value &&
                              item2?.assetCode?.value === sku?.value
                            )
                          })
                          if (selectedProductSku) {
                            checkIsSelected = true
                          }
                        }
                        return (
                          (!_.isEmpty(uuid) && sku?.ticket?.id === uuid) ||
                          (!sku?.holder &&
                            sku.status === STOCK_STATUS.ACTIVE &&
                            !checkIsSelected)
                        )
                      }),
                    }
                  : { isLoading: false, options: [] }

                let availableColor = ''
                if (checkAvailableQtyAlert(assets[index])) {
                  availableColor = '#F07662'
                }
                if (checkAvailableQty(assets[index])) {
                  availableColor = '#C91432'
                }

                const isAssetError = !_.isEmpty(
                  _.find(formErrors, { path: `assets[${index}].value` }),
                )
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      id={`${crudConfig.moduleSlug}-assets[${index}].value`}
                    >
                      <CustomDropdown
                        masterDataName="assets"
                        labelName=""
                        fieldName={`assets[${index}]`}
                        placeholder="เลือกทรัพย์สิน"
                        isCustomOption={true}
                        customOptions={assetOptions}
                        handleExtraChange={(event, value) => {
                          handleSelectAsset(value, index)
                        }}
                        boxSx={{
                          border: isAssetError
                            ? `solid 1px ${theme.palette.error.main}`
                            : '',
                        }}
                      />
                      {isAssetError && (
                        <Box color={theme.palette.error.main}>
                          {
                            _.find(formErrors, {
                              path: `assets[${index}].value`,
                            }).errors[0]
                          }
                        </Box>
                      )}
                    </TableCell>
                    <StyledBodyCell isActive={checkHaveAssetCode}>
                      {checkHaveAssetCode && (
                        <CustomDropdown
                          labelName=""
                          // masterData.assets.options
                          fieldName={`assets[${index}].assetCode`}
                          placeholder="เลือกรหัสทรัพย์สิน"
                          isCustomOption={true}
                          customOptions={assetCodeOption}
                          // handleExtraChange={handleOwnerChange}
                        />
                      )}
                    </StyledBodyCell>
                    <StyledBodyCell
                      isActive={isSelected && !checkHaveAssetCode}
                    >
                      {isSelected && !checkHaveAssetCode && (
                        <CustomInput
                          fieldName={`assets[${index}].qty`}
                          labelName=""
                          placeholder="จำนวน"
                          inputProps={{
                            'data-testid': 'input-qty',
                            onInput: (e) => {
                              handleInputNumber(e, regex.NUMBER)
                            },
                            onKeyDown: (e) => {
                              handleNumberKeyDown(e, ['.'])
                            },
                            onPaste: handlePasteFormat,
                          }}
                        />
                      )}
                    </StyledBodyCell>
                    <StyledBodyCell
                      isActive={isSelected && !checkHaveAssetCode}
                    >
                      {isSelected && !checkHaveAssetCode && (
                        <>
                          <span style={{ color: availableColor }}>
                            {convertNumberWithComma(item?.availableQty ?? '')}
                          </span>
                          /{convertNumberWithComma(item?.totalQty ?? '')}
                        </>
                      )}
                    </StyledBodyCell>
                    <StyledBodyCell
                      isActive={isSelected && !checkHaveAssetCode}
                      color={(theme) =>
                        item?.status ? theme.palette?.secondary?.main : ''
                      }
                    >
                      {isSelected && !checkHaveAssetCode ? (
                        <>
                          {checkAvailableQty(assets[index]) ? (
                            <Typography color={'#C91432'}>
                              ไม่เพียงพอ
                            </Typography>
                          ) : (
                            <>
                              {checkAvailableQtyAlert(assets[index]) && (
                                <Typography color={'#F07662'}>
                                  คงเหลือน้อย
                                </Typography>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </StyledBodyCell>
                    <TableCell align="center">
                      <DropdownButton
                        item={item}
                        itemIndex={index}
                        isSelected={isSelected}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!_.isEmpty(assetsError) && (
        <Typography color={theme.palette.error.main} mt={'-20px'}>
          กรุณาเพิ่มอย่างน้อย 1 รายการ
        </Typography>
      )}
      <Box width="auto">
        <Button
          size="medium"
          data-testid={`btn-add-inside`}
          variant="contained"
          onClick={() => handleAddAsset()}
        >
          เพิ่มรายการทรัพย์สิน
        </Button>
      </Box>
    </Box>
  )
}

export const checkAvailableQty = (assetData) => {
  return assetData?.qty > 0 && assetData?.qty > assetData?.availableQty
}

export const checkAvailableQtyAlert = (assetData) => {
  return assetData?.availableQty <= assetData?.quantityAlert
}
