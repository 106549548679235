import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Typography, IconButton } from '@mui/material'
import { useTheme } from '@mui/system'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setReduxDashboardByKey } from 'src/redux/slices/monthlyPlan'

const ExpandableHeader = ({ title = 'รายละเอียด' }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isExpand } = useSelector(
    (state) => ({
      isExpand: state.monthlyPlan.dashboard.isExpand,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" gap={2} p={2} alignItems="center">
      <Typography variant="body1b">{title}</Typography>
      <IconButton
        sx={{
          width: 28,
          height: 28,
          backgroundColor: theme.palette.primary.outlinedHoverBackground,
          '.Mui-Typography': {
            color: theme.palette.text.lightGray,
          },
        }}
        onClick={() =>
          dispatch(
            setReduxDashboardByKey({ key: 'isExpand', value: !isExpand }),
          )
        }
      >
        {isExpand ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </Box>
  )
}
export default ExpandableHeader
