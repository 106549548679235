import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { Row } from '../../Layout/Styled'
import {
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
} from '../../../redux/slices/reportComp'
import { onDownload } from '../handler/onDownload'

const ActionButtons = () => {
  const dispatch = useDispatch()
  const { filters } = useSelector(
    (state) => ({
      filters: state.reportComp.filters,
    }),
    shallowEqual,
  )

  return (
    <Row sx={{ justifyContent: 'space-between', px: 2, py: 3 }}>
      <Button
        data-testid={'btn-manage-column'}
        sx={{ height: 40, py: 1, px: 2 }}
        variant="text"
        startIcon={<ViewColumnIcon />}
        onClick={() => dispatch(setIsOpenManageColumnDrawer(true))}
      >
        <Typography variant="text">จัดการคอลัมน์</Typography>
      </Button>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          data-testid="btn-download"
          variant="text"
          startIcon={<FileDownloadOutlined />}
          onClick={() => onDownload()}
        >
          ดาวน์โหลด
        </Button>
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, py: 1, px: 2 }}
          variant="text"
          startIcon={<FilterListOutlinedIcon />}
          onClick={() => dispatch(setIsOpenFilterDrawer(true))}
        >
          <Typography variant="text">
            ตัวกรอง {filters.length > 0 && `(${filters.length})`}
          </Typography>
        </Button>
      </Box>
    </Row>
  )
}
export default ActionButtons
