import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { overviewEnum } from '../enums/drawerEnum'
import { resetPage } from '../../../../redux/slices/table'
import { fetchData } from '../components/UserLoginHistory/events'
import { staffProfileDashboard } from '../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { manageProfileDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      manageProfileDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      manageProfileDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(staffProfileDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'manageProfileDashboard.latestUpdated',
          value: _.get(data, 'result.latestUpdated', undefined),
        }),
      )

      store.dispatch(
        setFieldValue({
          key: 'manageProfileDashboard.overview',
          value: { ...overviewEnum, ...data.result },
        }),
      )

      const sRam1Data = _.get(data, 'sRam1Data', [])
      const sRam2Data = _.get(data, 'sRam2Data', [])
      const sRam3Data = _.get(data, 'sRam3Data', [])
      const sRam4Data = _.get(data, 'sRam4Data', [])
      store.dispatch(
        setFieldValue({
          key: 'manageProfileDashboard.sRamData',
          value: {
            sRam1Data: sRam1Data,
            sRam2Data: sRam2Data,
            sRam3Data: sRam3Data,
            sRam4Data: sRam4Data,
          },
        }),
      )
      const mergeSRam = [...sRam1Data, ...sRam2Data, ...sRam3Data, ...sRam4Data]
      const sRamTotal = _.sumBy(mergeSRam, 'TOTAL')
      store.dispatch(
        setFieldValue({
          key: 'manageProfileDashboard.sRamTotal',
          value: sRamTotal,
        }),
      )
      // const sRamCountType= _.get(data, 'sRamCountType', {})
      // store.dispatch(
      //   setFieldValue({
      //     key: 'eExamDashboard.manageProfileDashboard.countTypeArr',
      //     value: [
      //       {
      //         examinationField: 'สมาคม',
      //         totalEnroll: _.get(sRamCountType, 'ASSOCIATION', 0),
      //       },
      //       {
      //         examinationField: 'บริษัท',
      //         totalEnroll: _.get(sRamCountType, 'COMPANY', 0),
      //       },
      //       {
      //         examinationField: 'อื่นๆ',
      //         totalEnroll: _.get(sRamCountType, 'OTHER', 0),
      //       },
      //     ],
      //   }),
      // )
    })
    .catch((err) => {
      console.log(err)
    })

  const { table } = store.getState().table
  store.dispatch(resetPage())
  store.dispatch(
    fetchData({
      table,
    }),
  )

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
