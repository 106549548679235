import { ChevronRight } from '@mui/icons-material'
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TablePagination,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { Fragment } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ROW_PAGE_PER_LIST } from 'src/constants/table'

const TableSection = () => {
  const theme = useTheme()
  const {
    table: { headCells, details, allCount, rowsPerPage, tablePage, page },
  } = useSelector(
    (state) => ({
      table: state.monthlyPlan.dashboard.table,
    }),
    shallowEqual,
  )

  return (
    <Fragment>
      <Table
        sx={{
          '& .MuiTableCell-root': {
            p: 2,
            borderColor: '#EDEDED',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {_.map(headCells, (head, idx) => {
              return <TableCell key={idx}>{head.label}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(details, (dt, idx) => {
            return (
              <TableRow
                sx={{
                  '.MuiTableCell-root': {
                    backgroundColor: _.includes(dt.staffName, 'AVG')
                      ? theme?.palette?.primary?.outlinedHoverBackground
                      : 'none',
                  },
                }}
                key={idx}
              >
                {_.map(headCells, (header, idxH) => {
                  return (
                    <TableCell
                      sx={{
                        py: 0,
                        px: 1,
                      }}
                      align={header?.align}
                      key={idxH}
                    >
                      {dt[header.id]}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
      >
        <Button
          endIcon={<ChevronRight />}
          variant="outlined"
          onClick={() => window.open('/monthly-plan/report', '_blank')}
        >
          BREAKDOWN
        </Button>
        <TablePagination
          component="div"
          count={allCount}
          page={tablePage < 0 ? 1 : +tablePage}
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          // onPageChange={(e, newPage) => {
          //   dispatch(handleChangePage(e, newPage))
          // }}
          // onRowsPerPageChange={(e) => dispatch(handleChangeRowsPerPage(e))}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า :{' '}
            </Typography>
          }
          labelDisplayedRows={() => {
            const realPage = tablePage <= 0 ? 1 : tablePage + 1
            let minRowsShowed = page < 0 ? 1 : rowsPerPage.value * tablePage + 1
            let maxRowsShowed =
              rowsPerPage.value * realPage > allCount
                ? allCount
                : rowsPerPage.value * realPage

            if (maxRowsShowed < 0) {
              maxRowsShowed = allCount
            }
            if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

            return (
              <Typography variant="caption">
                {minRowsShowed}-{maxRowsShowed} of {allCount}
              </Typography>
            )
          }}
        />
      </Box>
    </Fragment>
  )
}
export default TableSection
