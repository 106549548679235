import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportForHFConst = {
  COLUMN_NAME: {
    NO: 'no',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
    CLASS_PLAN: 'classPlan',
    CLASS_ACTUAL: 'classActual',
    SEAT_PLAN: 'seatPlan',
    SEAT_ACTUAL: 'seatActual',
    PASS: 'countPass',
    FAIL: 'countNotPass',
    COUNT_STUDENT: 'countStudent',
    COUNT_ATTENDACE: 'countAttendance',
    COUNT_ABSENT: 'countAbsent',
    RESULT: 'result',
    TRAINING_PLATFORM: 'trainingPlatform',
    DEPARTMENT: 'department',
    DISTRIBUTION: 'distribution',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    TRAINER_NAME: 'trainerName',
    VERSION_NUMBER: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
    STATION: 'station',
    STATUS_CLASS: 'statusClass',
    END_CLASS_DATETIME: 'endClassDateTime',
    START_CLASS_DATETIME: 'startClassDateTime',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
    CLASS_PLAN: 'Class (Plan)',
    CLASS_ACTUAL: 'Class (Actual)',
    SEAT_PLAN: 'Seat (Plan)',
    SEAT_ACTUAL: 'Seat (Actual)',
    COUNT_STUDENT: 'จำนวนคนที่สมัคร',
    COUNT_ATTENDACE: 'จำนวนผู้เข้าอบรม',
    COUNT_ABSENT: 'จำนวนผู้ไม่เข้าอบรม',
    PASS: 'ผ่าน',
    FAIL: 'ไม่ผ่าน',
    TRAINING_PLATFORM: 'รูปแบบการสอน',
    DEPARTMENT: 'Department',
    DISTRIBUTION: 'ช่องทางการจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนชองทางการจำหน่ายอื่น',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    TRAINER_NAME: 'Trainer Name',
    VERSION_NUMBER: 'เลขเวอร์ชันของหลักสูตร',
    STATION: 'Station',
    END_CLASS_DATETIME: 'วันเริ่มต้นคลาส',
    START_CLASS_DATETIME: 'วันสิ้นสุดคลาส',
    STATUS_CLASS: 'สถานะคลาส',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportForHFDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportForHFConst.COLUMN_NAME.NO,
      value: reportForHFConst.COLUMN_NAME.NO,
      label: reportForHFConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportForHFConst.COLUMN_NAME.COURSE_CODE,
      value: reportForHFConst.COLUMN_NAME.COURSE_CODE,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportForHFConst.COLUMN_NAME.STATION,
      value: reportForHFConst.COLUMN_NAME.STATION,
      label: reportForHFConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportForHFConst.COLUMN_NAME.CLASS_PLAN,
      value: reportForHFConst.COLUMN_NAME.CLASS_PLAN,
      label: reportForHFConst.COLUMN_NAME_TH.CLASS_PLAN,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportForHFConst.COLUMN_NAME.SEAT_PLAN,
      value: reportForHFConst.COLUMN_NAME.SEAT_PLAN,
      label: reportForHFConst.COLUMN_NAME_TH.SEAT_PLAN,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportForHFConst.COLUMN_NAME.CLASS_ACTUAL,
      value: reportForHFConst.COLUMN_NAME.CLASS_ACTUAL,
      label: reportForHFConst.COLUMN_NAME_TH.CLASS_ACTUAL,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportForHFConst.COLUMN_NAME.SEAT_ACTUAL,
      value: reportForHFConst.COLUMN_NAME.SEAT_ACTUAL,
      label: reportForHFConst.COLUMN_NAME_TH.SEAT_ACTUAL,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportForHFConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportForHFConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportForHFConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportForHFConst.COLUMN_NAME.COUNT_ATTENDACE,
      value: reportForHFConst.COLUMN_NAME.COUNT_ATTENDACE,
      label: reportForHFConst.COLUMN_NAME_TH.COUNT_ATTENDACE,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportForHFConst.COLUMN_NAME.COUNT_ABSENT,
      value: reportForHFConst.COLUMN_NAME.COUNT_ABSENT,
      label: reportForHFConst.COLUMN_NAME_TH.COUNT_ABSENT,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportForHFConst.COLUMN_NAME.PASS,
      value: reportForHFConst.COLUMN_NAME.PASS,
      label: reportForHFConst.COLUMN_NAME_TH.PASS,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportForHFConst.COLUMN_NAME.FAIL,
      value: reportForHFConst.COLUMN_NAME.FAIL,
      label: reportForHFConst.COLUMN_NAME_TH.FAIL,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportForHFConst.COLUMN_NAME.COURSE_CODE,
      value: reportForHFConst.COLUMN_NAME.COURSE_CODE,
      label: 'รหัสหลักสูตร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 20,
      align: 'center',
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportForHFConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportForHFConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportForHFConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportForHFConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportForHFConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportForHFConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.TRAINING_PLATFORM,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 7,
      name: reportForHFConst.COLUMN_NAME.DISTRIBUTION,
      value: reportForHFConst.COLUMN_NAME.DISTRIBUTION,
      label: 'ช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportForHFConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      value: reportForHFConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      label: 'ชื่อย่อของช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportForHFConst.COLUMN_NAME.ROADMAP,
      value: reportForHFConst.COLUMN_NAME.ROADMAP,
      label: 'กลุ่มหลักสูตร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportForHFConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      value: reportForHFConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      label: 'ชื่อย่อของกลุ่มหลักสูตร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportForHFConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportForHFConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียน',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 13,
      name: reportForHFConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      value: reportForHFConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 14,
      name: reportForHFConst.COLUMN_NAME.ACQUIRED_SKILL,
      value: reportForHFConst.COLUMN_NAME.ACQUIRED_SKILL,
      label: 'ระดับหลักสูตร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.ACQUIRED_SKILL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: reportForHFConst.COLUMN_NAME.REQUIRE_DOCUMENT,
      value: reportForHFConst.COLUMN_NAME.REQUIRE_DOCUMENT,
      label: 'เอกสารการสมัคร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportForHFConst.requireOptions,
    },
    {
      id: 16,
      name: reportForHFConst.COLUMN_NAME.STATION,
      value: reportForHFConst.COLUMN_NAME.STATION,
      label: 'Station',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportForHFConst.COLUMN_NAME.DEPARTMENT,
      value: reportForHFConst.COLUMN_NAME.DEPARTMENT,
      label: 'Department',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportForHFConst.COLUMN_NAME.DEPARTMENT,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 18,
      name: reportForHFConst.COLUMN_NAME.STATUS_CLASS,
      value: reportForHFConst.COLUMN_NAME.STATUS_CLASS,
      label: 'Status Class',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportForHFConst.statusOptions,
    },
    {
      id: 19,
      name: reportForHFConst.COLUMN_NAME.VERSION_NUMBER,
      value: reportForHFConst.COLUMN_NAME.VERSION_NUMBER,
      label: 'เลขเวอร์ชันของหลักสูตร',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 20,
      name: reportForHFConst.COLUMN_NAME.START_CLASS_DATETIME,
      value: reportForHFConst.COLUMN_NAME.START_CLASS_DATETIME,
      label: 'วันเริ่มต้นคลาส',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 21,
      name: reportForHFConst.COLUMN_NAME.END_CLASS_DATETIME,
      value: reportForHFConst.COLUMN_NAME.END_CLASS_DATETIME,
      label: 'วันสิ้นสุดคลาส',
      minWidth: 160,
      type: reportForHFConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
