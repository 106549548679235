import _ from 'lodash'
import { store } from '../../../../App'
import dayjs from 'dayjs'
import { callAPI, validateDate } from 'src/utils/lib'
import { monthlyPlanFilterSummaryWorkload } from '../../../../utils/apiPath'
import { headCellsDetails, listWorkTypeData } from '../model'

export const fetchSummaryWorkload = async () => {
  const {
    dashboard: { filters, table, workTypeData, searchText },
  } = store.getState().monthlyPlan

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
    courseCodeList,
    positionList,
  } = filters

  const url = monthlyPlanFilterSummaryWorkload

  const body = {
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList?.length > 0 ? departmentList : defaultDepartment,
    station: stationList?.length > 0 ? stationList : defaultStation,
    courseCode: courseCodeList?.length > 0 ? courseCodeList : [],
    position: positionList?.length > 0 ? positionList : [],
    limit: 100,
    page: 1,
    searchText,
  }

  const response = await callAPI({
    method: 'post',
    url,
    body,
  })

  const countMonth = _.get(response, 'countMonth', 1)

  const results = _.get(response, 'results', [])
  const filteredResults = _.filter(results, (res) => !_.isUndefined(res.no))

  const findAvgPerCountMonth = _.find(
    results,
    (res) => res.staffName === `AVG/${countMonth} Month`,
  )

  const workTypeListAVG = _.map(listWorkTypeData, (li) => {
    return { ...li, value: findAvgPerCountMonth[li?.key] }
  })

  return {
    table: {
      ...table,
      headCells: headCellsDetails,
      details: results,
      allCount: filteredResults.length,
    },
    workTypeData: {
      ...workTypeData,
      workTypeList: listWorkTypeData,
      workTypeListAVG,
    },
  }
}
