import { toggleAgencyDrawer } from './handleClickOpenDrawer'

export const handleClickButton = (ele) => {
  if (ele.type === 'Agency' && ele.value === 'ALL') {
    toggleAgencyDrawer(true)
  } else {
    const url = `/manage/report-manage-profile`
    if (['Agency', 'Banc'].includes(ele.type)) {
      window.open(`${url}/agent?type=${ele.type}&value=${ele.value}`, '_blank')
    } else if (['Prospect'].includes(ele.type)) {
      window.open(
        `${url}/prospect?type=${ele.type}&value=${ele.value}`,
        '_blank',
      )
    }
  }
}
