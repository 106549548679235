import { COLUMN_NAME, COLUMN_NAME_TH } from 'src/constants/report/monthlyPlan'

export const breadcrumbReportMonthlyPlan = () => [
  { title: 'แผนรายเดือน', link: '/', pointer: false },
  { title: 'รายงาน Workload', link: '/', pointer: false },
]

export const tabsList = [
  {
    label: 'Overview',
  },
  {
    label: 'Detail Workload',
  },
]

export const defaultDataWorkload = [
  {
    workType: 'Training',
    details: [
      {
        type: 'รูปแบบการสอน',
        list: '(Classroom)',
      },
      {
        list: 'Agency',
        key: COLUMN_NAME.CLASSROOM_AGENCY,
      },
      {
        list: 'Banca',
        key: COLUMN_NAME.CLASSROOM_BANCA,
      },
      {
        list: 'KTB',
        key: COLUMN_NAME.CLASSROOM_KTB,
      },
      {
        list: 'Corporate Solution',
        key: COLUMN_NAME.CLASSROOM_CORPORATE,
      },
      {
        list: 'Staff(Academy)',
        key: COLUMN_NAME.CLASSROOM_ST_ACA,
      },
      {
        list: 'Staff(Non-Academy)',
        key: COLUMN_NAME.CLASSROOM_ST_NON_ACA,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.CLASSROOM_TOTAL,
      },
      {
        type: 'รูปแบบการสอน',
        list: '(Online)',
      },
      {
        list: 'Agency',
        key: COLUMN_NAME.ONLINE_AGENCY,
      },
      {
        list: 'Banca',
        key: COLUMN_NAME.ONLINE_BANCA,
      },
      {
        list: 'KTB',
        key: COLUMN_NAME.ONLINE_KTB,
      },
      {
        list: 'Corporate Solution',
        key: COLUMN_NAME.ONLINE_CORPORATE,
      },
      {
        list: 'Staff(Academy)',
        key: COLUMN_NAME.ONLINE_ST_ACA,
      },
      {
        list: 'Staff(Non-Academy)',
        key: COLUMN_NAME.ONLINE_ST_NON_ACA,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.ONLINE_TOTAL,
      },
    ],
  },
  {
    workType: 'Academy Activity',
    details: [
      {
        list: 'Office Work (Work from office)',
        key: COLUMN_NAME.OFFICE_WFO,
      },
      {
        list: 'Office Work (Smart working)',
        key: COLUMN_NAME.OFFICE_SM,
      },
      {
        list: 'Certified',
        key: COLUMN_NAME.CERTIFIED,
      },
      {
        list: 'Travelling',
        key: COLUMN_NAME.TRAVELLING,
      },
      {
        list: 'External Training',
        key: COLUMN_NAME.EXTERNAL_TRAINING,
      },
      {
        list: 'Academy Training',
        key: COLUMN_NAME.ACADEMY_TRAINING,
      },
      {
        list: 'Meeting',
        key: COLUMN_NAME.MEETING,
      },
      {
        list: 'Other Activity',
        key: COLUMN_NAME.OTHER_ACTIVITY,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.ACADEMY_TOTAL,
      },
    ],
  },
  {
    workType: 'Activity Support',
    details: [
      {
        list: 'Activity Support (Classroom)',
        key: COLUMN_NAME.CLASSROOM_ACTIVITY,
      },
      {
        list: 'Activity Support (Online)',
        key: COLUMN_NAME.ONLINE_ACTIVITY,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.ACTIVITY_TOTAL,
      },
    ],
  },
  {
    workType: 'Holiday',
    details: [
      {
        list: 'วันหยุด / หยุดชดเชย',
        key: COLUMN_NAME.PUBLIC_HOLIDAY,
      },
      {
        list: 'Holiday',
        key: COLUMN_NAME.HOLIDAY,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.HOLIDAY_TOTAL,
      },
    ],
  },
  {
    workType: 'Leave',
    details: [
      {
        list: 'Annual Leave',
        key: COLUMN_NAME.ANNUAL_LEAVE,
      },
      {
        list: 'Birthday Leave',
        key: COLUMN_NAME.BIRTHDAY_LEAVE,
      },
      {
        list: 'Family Care Leave',
        key: COLUMN_NAME.FAMILY_CARE_LEAVE,
      },
      {
        list: 'Personal Leave',
        key: COLUMN_NAME.PERSONAL_LEAVE,
      },
      {
        list: 'Sick Leave',
        key: COLUMN_NAME.SICK_LEAVE,
      },
      {
        list: 'Other Leave',
        key: COLUMN_NAME.OTHER_LEAVE,
      },
      {
        list: 'Day Off',
        key: COLUMN_NAME.DAY_OFF,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.LEAVE_TOTAL,
      },
    ],
  },
  {
    details: [
      {
        type: 'Summary Workload',
        list: 'Training (Classroom / Online)',
        key: COLUMN_NAME.SUM_WORKLOAD_TRAINING,
      },
      {
        list: 'Academy Activity',
        key: COLUMN_NAME.SUM_WORKLOAD_ACADEMY,
      },
      {
        list: 'Activity Support',
        key: COLUMN_NAME.SUM_WORKLOAD_ACTIVITY,
      },
      {
        list: 'Holiday',
        key: COLUMN_NAME.SUM_WORKLOAD_HOLIDAY,
      },
      {
        list: 'Leave',
        key: COLUMN_NAME.SUM_WORKLOAD_LEAVE,
      },
    ],
  },
  {
    details: [
      {
        list: COLUMN_NAME_TH.SUMMARY_TOTAL,
        key: COLUMN_NAME.SUMMARY_TOTAL,
      },
    ],
  },
]

export const defaultDataYearly = [
  {
    workType: 'Training (Classroom , Online)',
    details: [
      {
        list: 'Training hours',
        key: COLUMN_NAME.TRAINING,
      },
    ],
  },
  {
    workType: 'Academy Activity',
    details: [
      {
        list: 'Office Work (Work from office)',
        key: COLUMN_NAME.OFFICE_WFO,
      },
      {
        list: 'Office Work (Smart working)',
        key: COLUMN_NAME.OFFICE_SM,
      },
      {
        list: 'Certified',
        key: COLUMN_NAME.CERTIFIED,
      },
      {
        list: 'External Training',
        key: COLUMN_NAME.EXTERNAL_TRAINING,
      },
      {
        list: 'Academy Training',
        key: COLUMN_NAME.ACADEMY_TRAINING,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.ACADEMY_TOTAL_YEARLY,
      },
    ],
  },
  {
    workType: 'Activity Support',
    details: [
      {
        list: 'Activity Support',
        key: COLUMN_NAME.ACTIVITY_SUPPORT,
      },
    ],
  },
  {
    workType: 'Holiday',
    details: [
      {
        list: 'วันหยุด / หยุดชดเชย',
        key: COLUMN_NAME.PUBLIC_HOLIDAY_YEARLY,
      },
      {
        list: COLUMN_NAME_TH.TOTAL,
        key: COLUMN_NAME.HOLIDAY_TOTAL_YEARLY,
      },
    ],
  },
  {
    workType: 'Leave',
    details: [
      {
        list: 'Leave',
        key: COLUMN_NAME.LEAVE,
      },
    ],
  },
  {
    details: [
      {
        type: 'Summary Workload',
        list: 'Training (Classroom / Online)',
        key: COLUMN_NAME.SUM_WORKLOAD_TRAINING,
      },
      {
        list: 'Academy Activity',
        key: COLUMN_NAME.SUM_WORKLOAD_ACADEMY_YEARLY,
      },
      {
        list: 'Activity Support',
        key: COLUMN_NAME.SUM_WORKLOAD_ACTIVITY,
      },
      {
        list: 'Holiday',
        key: COLUMN_NAME.SUM_WORKLOAD_HOLIDAY_YEARLY,
      },
      {
        list: 'Leave',
        key: COLUMN_NAME.SUM_WORKLOAD_LEAVE,
      },
    ],
  },
  {
    details: [
      {
        list: COLUMN_NAME_TH.SUMMARY_TOTAL,
        key: COLUMN_NAME.SUMMARY_TOTAL_YEARLY,
      },
    ],
  },
]
