import { store } from '../../../App'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import {
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
} from '../../../redux/slices/reportComp'

export const handleConfirmCancelDraft = () => {
  const { displayFilters, isOpenFilterDrawer, isOpenManageColumnDrawer } =
    store.getState().reportComp

  if (
    (displayFilters.length > 0 && isOpenFilterDrawer) ||
    isOpenManageColumnDrawer
  ) {
    store.dispatch(
      openDialog({
        type: 'confirm',
        title: 'ยืนยันการละทิ้ง',
        message:
          'เนื้อหาที่สร้างไว้ จะไม่สามารถกู้คืนได้ คุณต้องการละทิ้งใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        handleConfirm: () => {
          store.dispatch(setIsOpenFilterDrawer(false))
          store.dispatch(setIsOpenManageColumnDrawer(false))
          store.dispatch(closeDialog())
        },
        handleCancel: () => {
          store.dispatch(closeDialog())
        },
      }),
    )
  } else {
    store.dispatch(setIsOpenFilterDrawer(false))
    store.dispatch(setIsOpenManageColumnDrawer(false))
  }
}
