import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import {
  requisitionTicket,
  requisitionTicketStatus,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import {
  RequisitionStatus,
  TicketStatus,
  TicketStatusText,
} from '../../../../../constants/stock'
import _ from 'lodash'
import { getBodyMutation } from '../mutation/get'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { fetchDetail } from '../../../../../components/CRUD/handler/fetchDetail'
import { viewTypeEnum } from '../enum/viewTypeEnum'
import {
  replaceFieldError,
  setFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import { setAssets } from './setAssets'
import { validateFormData } from './handleSubmitDrawer'
import { rejectValidationSchema, ReturnValidation } from '../schema/schema'
import { handleViewUpdatedAt } from '../../../utils'
import { Box } from '@mui/material'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'

export const handleChangeStatus = async (
  uuid,
  status,
  formData = null,
  isDraft = false,
) => {
  if (status === TicketStatus.WAITING_RETURN_APPROVE && formData) {
    const isValid = await validateFormData(formData, ReturnValidation)
    if (!isValid) {
      return false
    }
  }
  confirmDialog(uuid, status, formData, isDraft)
}

function confirmDialog(uuid, status, formData, isDraft) {
  if (status === RequisitionStatus.REJECTED) {
    openRejectDialog(uuid, status)
  } else {
    const dialogText = getDialogText(status, isDraft)
    const message = isDraft
      ? `คุณต้องการบันทึกฉบับร่างรายการนี้ใช่หรือไม่`
      : `คุณต้องการ${TicketStatusText?.[status]}รายการนี้ใช่หรือไม่`
    store.dispatch(
      openDialog({
        type: dialogText.type,
        title: dialogText.DIALOG_TITLE,
        message: message,
        agreeText: dialogText.agreeText,
        disagreeText: dialogText.disagreeText,
        handleConfirm: () => {
          changeTransferStatus(uuid, status, formData)
        },
        isCloseDialog: false,
      }),
    )
  }
}

const getDialogText = (status, isDraft) => {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  let type = 'delete'
  if (TicketStatus.CANCELED === status) {
    DIALOG_TITLE = `ยืนยันการยกเลิก`
    agreeText = 'ยืนยัน'
    disagreeText = 'ปฏิเสธ'
  } else if (TicketStatus.REJECTED === status) {
    DIALOG_TITLE = `ยืนยันการปฏิเสธ`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
  } else if (
    [
      TicketStatus.APPROVED,
      TicketStatus.EDITED_APPROVED,
      TicketStatus.WAITING_RETURN,
      TicketStatus.WAITING_RETURN_APPROVE,
      TicketStatus.COMPLETED,
      TicketStatus.COMPLETED_WITH_CONDITION,
    ].includes(status)
  ) {
    DIALOG_TITLE = `ยืนยันอนุมัติรายการ`
    agreeText = 'อนุมัติ'
    disagreeText = 'ยกเลิก'
    type = 'confirm'
  }
  if (TicketStatus.DRAFT === status || isDraft) {
    DIALOG_TITLE = `ยืนยันบันทึกฉบับร่าง`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
    type = 'confirm'
  }

  return {
    DIALOG_TITLE,
    agreeText,
    disagreeText,
    type,
  }
}

const changeTransferStatus = async (uuid, status, formData) => {
  store.dispatch(loadingDialog())
  let apiUrl = requisitionTicketStatus
  let body = {
    uuid,
    status: TicketStatus.CANCELED === status ? TicketStatus.REJECTED : status,
    products: _.get(formData, 'products', []),
    isCancel: TicketStatus.CANCELED === status,
  }
  if (status === TicketStatus.WAITING_RETURN && formData) {
    apiUrl = requisitionTicket
    body = {
      ...body,
      ...formData,
      warehouseUuid: _.get(formData, 'warehouse.value', ''),
      responsibility: _.get(formData, 'responsibility.uuid', ''),
      monthlyPlanUuid: _.get(formData, 'monthlyPlan.uuid'),
      assets: _.get(formData, 'assets', []).map((data) => {
        return {
          ...data,
          uuid: data.value,
          reasonNotReturnFile: data?.remark ?? '',
          returnQty: data?.returnQty ? Number(data?.returnQty) : null,
        }
      }),
    }
  }

  if (status === TicketStatus.REJECTED && formData) {
    body = {
      ...body,
      rejectReason: _.get(formData, 'rejectReason', ''),
    }
  }

  await callAxios
    .put(apiUrl, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            initialConfig(initialCrudConfig)
            fetchDetail(uuid, getBodyMutation).then((detail) => {
              demoData(detail)
              handleViewUpdatedAt()
              setAssets()
            })
          },
        }),
      )
    })
    .catch((e) => {
      const value =
        e?.response?.data?.property === 'SkuNoNotAvailable'
          ? e?.response?.data?.constraints?.value ?? 'ระบบขัดข้อง'
          : 'ระบบขัดข้อง'

      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: value,
        }),
      )
    })
}

const demoData = (detail = {}) => {
  const status =
    viewTypeEnum?.[_.get(detail, 'status', viewTypeEnum.TICKET)] ??
    viewTypeEnum.TICKET
  store.dispatch(
    setFieldValue({
      key: 'viewType',
      value: status,
    }),
  )
}

export const openRejectDialog = (uuid, status) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        rejectReason: '',
      },
    }),
  )
  const dialogText = getDialogText(status, false)
  store.dispatch(
    openDialog({
      type: dialogText.type,
      title: dialogText.DIALOG_TITLE,
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <CustomTextArea
            fieldName="rejectReason"
            labelName="เหตุผลการปฏิเสธคืน"
            required
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitReject(uuid, status)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const handleSubmitReject = async (uuid, status) => {
  const isValid = await validateData(rejectValidationSchema)
  if (isValid) {
    const { formData } = store.getState().crud
    changeTransferStatus(uuid, status, formData)
  }
}

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}
