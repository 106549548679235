import dayjs from 'dayjs'
import { store } from 'src/App'
import {
  setFiltersListDisplay,
  setReduxDashboardByKey,
} from '../../../../redux/slices/monthlyPlan'
import _ from 'lodash'

export const handleFilterDisplay = () => {
  const {
    filters,
    filters: {
      dateChecked,
      departmentChecked,
      defaultDepartment,
      departmentOptions,
      department,
      stationChecked,
      station,
      stationOptions,
      startDate,
      endDate,
      courseCodeChecked,
      courseCode,
      courseCodeOptions,
      positionChecked,
      position,
      positionOptions,
    },
  } = store.getState().monthlyPlan.dashboard

  let filterTotal = 0
  const filterDisplay = []

  let formattedStartDate = dayjs().startOf('month').format('DD/MM/YYYY')
  let formattedEndDate = dayjs().format('DD/MM/YYYY')
  let isFilterDate = false
  if (dateChecked && startDate && endDate) {
    formattedStartDate = dayjs(startDate).format('DD/MM/YYYY')
    formattedEndDate = dayjs(endDate).format('DD/MM/YYYY')
    filterTotal++
    isFilterDate = true
  }

  const dateItem = {
    name: 'department',
    label: 'วันที่',
    value: `${formattedStartDate} - ${formattedEndDate}`,
    isFilter: isFilterDate,
  }
  filterDisplay.push(dateItem)

  let isFilterDepartment = false
  let selectedDepartment = defaultDepartment
  if (departmentChecked && department.length > 0) {
    selectedDepartment = _.map(department, (de) => de.value)
    filterTotal++
    isFilterDepartment = true
  }
  const departmentLabels = _.filter(departmentOptions, (item) =>
    _.includes(selectedDepartment, item.value),
  )
    .map((item) => item.label)
    .join(', ')

  let departmentItem = {
    name: 'department',
    label: 'Department',
    value: departmentLabels,
    isFilter: isFilterDepartment,
  }
  filterDisplay.push(departmentItem)

  let stationList = []
  let isFilterStation = false

  if (stationChecked && Object.values(station).some((value) => value)) {
    const stationLabels = _.filter(stationOptions, (arr) => station[arr.name])
      .map((arr) => arr.label)
      .join(',')

    _.filter(stationOptions, (arr) => station[arr.name]).forEach((item) =>
      stationList.push(item.value),
    )

    filterTotal++
    isFilterStation = true

    const stationItem = {
      name: 'station',
      label: 'Station',
      value: stationLabels,
      isFilter: isFilterStation,
    }
    filterDisplay.push(stationItem)
  }

  let isFilterCourseCode = false
  let selectedCourseCode = []
  if (courseCodeChecked && courseCode.length > 0) {
    selectedCourseCode = _.map(courseCode, (de) => de.value)
    filterTotal++
    isFilterCourseCode = true

    const courseCodeLabels = _.filter(courseCodeOptions, (item) =>
      _.includes(selectedCourseCode, item.value),
    )
      .map((item) => item.label)
      .join(', ')

    let courseCodeItem = {
      name: 'courseCode',
      label: 'Course code',
      value: courseCodeLabels,
      isFilter: isFilterCourseCode,
    }
    filterDisplay.push(courseCodeItem)
  }

  let isFilterPosition = false
  let selectedPosition = []
  if (positionChecked && position.length > 0) {
    selectedPosition = _.map(position, (de) => de.value)
    filterTotal++
    isFilterPosition = true
    const positionLabels = _.filter(positionOptions, (item) =>
      _.includes(selectedPosition, item.value),
    )
      .map((item) => item.label)
      .join(', ')

    let positionItem = {
      name: 'position',
      label: 'ประเภทพนักงาน',
      value: positionLabels,
      isFilter: isFilterPosition,
    }
    filterDisplay.push(positionItem)
  }

  store.dispatch(setFiltersListDisplay(filterDisplay))
  store.dispatch(
    setReduxDashboardByKey({
      key: 'filters',
      value: {
        ...filters,
        filterTotal,
        stationList,
        departmentList: selectedDepartment,
        positionList: selectedPosition,
        courseCodeList: selectedCourseCode,
      },
    }),
  )
}
