import { Box, Typography } from '@mui/material'
import { StyledSummaryCard } from '../../Styled'
import DoughnutChart from '../../../../components/Chart/DoughnutChart'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import InfoPreview from '../../../../components/InfoPreview'
import { handleOpenClassDrawer } from '../handler/handleOpenClassDrawer'

const SummaryStatusOfClassCard = () => {
  const { classStatusDetails, allClass } = useSelector(
    (state) => ({
      classStatusDetails: state.monthlyPlan.dashboard.classStatusDetails,
      allClass: state.monthlyPlan.dashboard.allClass,
    }),
    shallowEqual,
  )

  const chartData = _.map(classStatusDetails, (classDetail) =>
    _.get(classDetail, 'count'),
  )
  const chartColorList = _.map(classStatusDetails, (classDetail) =>
    _.get(classDetail, 'color'),
  )

  return (
    <StyledSummaryCard>
      <Typography variant="h6">สรุปคลาสรายสถานะ</Typography>
      <Box display="flex" gap={2}>
        <DoughnutChart
          dataSet={{
            data: chartData,
            backgroundColor: chartColorList,
            borderRadius: 8,
            spacing: 2,
          }}
          height={224}
          label="ทั้งหมด"
          value={allClass}
        />
        <Box display="flex" flexWrap="wrap" gap={0.5}>
          {_.map(classStatusDetails, (classDetail, idx) => {
            return (
              <InfoPreview
                boxSx={{ width: 264, height: 96, border: 'none', p: 1.25 }}
                key={idx}
                label={classDetail.statusClassTH}
                chipLabel={classDetail.statusClass}
                count={classDetail.count}
                unit={classDetail.unit}
                bgColor={classDetail.bgColor}
                color={classDetail.color}
                fontColor={classDetail.fontColor}
                hideButton={false}
                hasDot={true}
                handleClick={() => handleOpenClassDrawer(classDetail.key)}
              />
            )
          })}
        </Box>
      </Box>
    </StyledSummaryCard>
  )
}

export default SummaryStatusOfClassCard
