import React, { useEffect } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { Route, Switch, useLocation } from 'react-router-dom'
import Layout from './Layout'
import { hasPermission } from './utils/lib'
import { path } from './constants/path'
import * as permission from './constants/permission/route'
import * as permissionECertification from './constants/permission/eCertificateRoute'
import * as permissionApiToRcms from './constants/permission/apiToRcms'
import * as permissionAgentProfile from './constants/permission/agentProfile'
import * as permissionCSAgentProfile from './constants/permission/csAgentProfile'
import * as permissionProspectProfile from './constants/permission/prospectProfile'
import * as permissionCSUserProfile from './constants/permission/csUserProfile'

import LevelOfLearnerList from './pages/master-data/level-of-learner/list'
import AddLevelOfLearner from './pages/master-data/level-of-learner/form'
import ViewLevelOfLearner from './pages/master-data/level-of-learner/[id]'
import EditLevelOfLearner from './pages/master-data/level-of-learner/edit/[id]'
import LevelList from './pages/master-data/level/list'
import LevelForm from './pages/master-data/level/form'
import LevelView from './pages/master-data/level/[id]'
import EditLevelForm from './pages/master-data/level/edit/[id]'
import CourseSetting from './pages/course-setting/list'
import CourseSettingForm from './pages/course-setting/form'
import CourseSettingVersion from './pages/course-setting/version/[id]'
import CourseSettingVersionIndex from './pages/course-setting/version'
import EditCourseSettingVersion from './pages/course-setting/version/edit/[id]'
import CourseSettingVersionForm from './pages/course-setting/version/form/[id]'
import CourseSettingView from './pages/course-setting/version/preview/[id]'
import CourseSettingCloneCourseForm from './pages/course-setting/clone/course/[id]'
import CourseSettingCloneVersionForm from './pages/course-setting/clone/version/[id]'

import ProductTypeList from './pages/master-data/product-type/list'
import ViewProductType from './pages/master-data/product-type/[id]'
import AddProductType from './pages/master-data/product-type/form'
import EditProductType from './pages/master-data/product-type/edit/[id]'

import DistributionChannelList from './pages/master-data/distribution-channel/list'
import AddDistributionChannel from './pages/master-data/distribution-channel/form'
import ViewDistributionChannel from './pages/master-data/distribution-channel/[id]'
import EditDistributionChannel from './pages/master-data/distribution-channel/edit/[id]'
import Login from './pages/login'
import LoginPage from './pages/login/login'

import RoadMapList from './pages/master-data/road-map/list'
import AddRoadMap from './pages/master-data/road-map/form'
import ViewRoadMap from './pages/master-data/road-map/[id]'
import EditRoadMap from './pages/master-data/road-map/edit/[id]'

import TrainingPlatformList from './pages/master-data/training-platform/list'
import ViewTrainingPlatform from './pages/master-data/training-platform/[id]'
import AddTrainingPlatform from './pages/master-data/training-platform/form'
import EditTrainingPlatform from './pages/master-data/training-platform/edit/[id]'

import AcquiredSkillList from './pages/master-data/acquired-skill/list'
import ViewAcquiredSkill from './pages/master-data/acquired-skill/[id]'
import AddAcquiredSkill from './pages/master-data/acquired-skill/form'
import EditAcquiredSkill from './pages/master-data/acquired-skill/edit/[id]'

import SkillTypeList from './pages/master-data/skill-type/list'
import AddSkillType from './pages/master-data/skill-type/form'
import ViewSkillType from './pages/master-data/skill-type/[id]'
import EditSkillType from './pages/master-data/skill-type/edit/[id]'

import Calendar from './pages/monthly-plan/calendar'
import CalendarById from './pages/monthly-plan/calendar/[id]'
import Booking from './pages/monthly-plan/booking/[id]'
import ClassDashboardPage from './pages/monthly-plan/class-dashboard'
import WorkloadDashboardPage from './pages/monthly-plan/workload-dashboard'

// manage
import StaffProfileList from './pages/manage/staff-profile/list'
import AddStaffProfile from './pages/manage/staff-profile/form'
import ViewStaffProfile from './pages/manage/staff-profile/[id]'
import EditStaffProfile from './pages/manage/staff-profile/edit/[id]'
import AgentProfilePage from './pages/manage/agent-profile/list'
import AgentProfileViewPage from './pages/manage/agent-profile/[id]'
import CsAgentProfilePage from './pages/manage/cs-agent-profile/list'
import CsAgentProfileViewPage from './pages/manage/cs-agent-profile/[id]'
import CsAgentProfileFormPage from './pages/manage/cs-agent-profile/edit/[id]'

import ProspectProfileViewPage from './pages/manage/prospect-profile/[id]'
import ProspectProfilePage from './pages/manage/prospect-profile/list'
import ProspectProfileFormPage from './pages/manage/prospect-profile/edit/[id]'
import CsUserProfilePage from './pages/manage/cs-user-profile/list'
import CsUserProfileViewPage from './pages/manage/cs-user-profile/[id]'
import CsUserProfileFormPage from './pages/manage/cs-user-profile/edit/[id]'

import PermissionList from './pages/manage/permission/list'
import AddPermission from './pages/manage/permission/form'
import ViewPermission from './pages/manage/permission/[id]'
import EditPermission from './pages/manage/permission/edit/[id]'

import ManageProfileDashboardPage from './pages/manage/dashboard'

import SpeakerRequesterList from './pages/monthly-plan/speaker-borrow/requester-list'
import SpeakerApproverList from './pages/monthly-plan/speaker-borrow/approver-list.js'

// e-testing
import ETestingList from './pages/e-testing/list'
import ETestingVersion from './pages/e-testing/version/[id]'
import ETestingVersionIndex from './pages/e-testing/version'
import ETestingVersionView from './pages/e-testing/version/view/[id]'
import ETestingVersionEdit from './pages/e-testing/version/edit/[id]'
import ETestingVersionClone from './pages/e-testing/version/clone/[id]'
import ETestingCreateVersion from './pages/e-testing/version/createVersion/[codeId]'

import ETestingFrom from './pages/e-testing/[createType]'
import ETestingPreviewIndex from './pages/e-testing/preview'
import ETestingPreview from './pages/e-testing/preview/[id]'
import ETestingPreviewTesting from './pages/e-testing/preview/testing/[id]'
import ETestingPreviewResult from './pages/e-testing/preview/result/[id]'

// e-valuation
import EEvaluationList from './pages/e-evaluation/list'
import EEvaluationVersion from './pages/e-evaluation/version/[id]'
import EEvaluationVersionIndex from './pages/e-evaluation/version'
import EEvaluationVersionView from './pages/e-evaluation/version/view/[id]'
import EEvaluationVersionEdit from './pages/e-evaluation/version/edit/[id]'
import EEvaluationCreateVersion from './pages/e-evaluation/version/createVersion/[codeId]'
import EEvaluationPreviewIndex from './pages/e-evaluation/preview'
import EEvaluationPreview from './pages/e-evaluation/preview/[id]'
import EEvaluationPreviewTesting from './pages/e-evaluation/preview/evaluate/[id]'
import EEvaluationVersionClone from './pages/e-evaluation/version/clone/[id]'

import EEvaluationForm from './pages/e-evaluation/[createType]'

// job-title
import JobTitleList from './pages/master-data/job-title/list'
import ViewJobTitle from './pages/master-data/job-title/[id]'
import AddJobTitle from './pages/master-data/job-title/form'
import EditJobTitle from './pages/master-data/job-title/edit/[id]'

// e-certification
import ECertificationList from './pages/e-certification/list'
import ECertificationBackgroundList from './pages/e-certification/background'
import ECertificationBackgroundForm from './pages/e-certification/background/form'
import ECertificationBackgroundView from './pages/e-certification/background/view/[id]'
import ECertificationBackgroundEdit from './pages/e-certification/background/edit/[id]'
import ECertificationForm from './pages/e-certification/form'
import ECertificationVersionList from './pages/e-certification/version/[id]'
import ECertificationCreateVersion from './pages/e-certification/version/createVersion/[codeId]'
import ECertificationVersionEdit from './pages/e-certification/version/edit/[id]'
import ECertificationVersionClone from './pages/e-certification/version/clone/[id]'
import ECertificationVersionView from './pages/e-certification/version/view/[id]'

// api-to-rcms
import ApiToRcmsList from './pages/api-to-rcms/list'
import ApiToRcmsDetailList from './pages/api-to-rcms/detail/[id]'
import ApiToRcmsPreview from './pages/api-to-rcms/preview/[uuid]/[id]'

// manage-class
import ManageClassList from './pages/manage-class/list'
import ManageClassSetting from './pages/manage-class/setting/[id]'
import ManageClassDetail from './pages/manage-class/detail/[id]'
import ManageClassLearner from './pages/manage-class/detail/learner/[id]'
import ManageClassApplicationDocuments from './pages/manage-class/detail/learner/application-documents/[id]'
import ManageClassEvaStatus from './pages/manage-class/detail/evaluation-status/[id]'
import ManageClassDashboard from './pages/manage-class/dashboard'

// manage-class/oic
import ManageClassOicList from './pages/manage-class/oic/list'
import ManageClassOicView from './pages/manage-class/oic/[uuid]'

// attendance
import ManageClassAttendance from './pages/manage-class/attendance/[id]'
import ManageClassAttendanceList from './pages/manage-class/attendance/list/[id]'

// document
import ManageClassDocument from './pages/manage-class/detail/learner/document/[id]'

//  detail learner
import ManageClassLearnerDetail from './pages/manage-class/detail/learner/detail/[id]'

// check exam
import ManageClassCheckExamList from './pages/check-exam'
import ManageClassCheckETesting from './pages/check-exam/[id]'
import ETestingCheckExamList from './pages/check-exam/standalone'
import ETestingCheckExamVersionList from './pages/check-exam/standalone/version'
import ETestingCheckETesting from './pages/check-exam/standalone/[id]'

//  License
import LicenseList from './pages/master-data/license/list'
import LicenseForm from './pages/master-data/license/form'
import LicenseView from './pages/master-data/license/[id]'

// manage-content
import ContentHomePage from './pages/manage-content/home'
import ContentHomePreview from './pages/manage-content/home/preview'
import ContentStationPage from './pages/manage-content/station'
import ContentStationPreview from './pages/manage-content/station/preview'
import { cancelPreviousPageRequests } from './utils/baseService'

// manage-content login
import ContentLoginPage from './pages/manage-content/login'
import ContentLoginPreview from './pages/manage-content/login/preview'

// manage-content home elearning
import ContentELearningHomePage from './pages/manage-content/home-e-learning'
import ContentElearningHomePreview from './pages/manage-content/home-e-learning/preview'

import DragAndDropPage from './pages/drag-and-drop'

//report
import ReportPage from './pages/report'

//meeting-room
import MeetingRoomListPage from './pages/master-data/meeting-room/list'
import MeetingRoomFormPage from './pages/master-data/meeting-room/form'
import MeetingRoomView from './pages/master-data/meeting-room/[id]'

//to-do-list
import ToDoListPage from './pages/to-do-list/list'
import RoomManagementPage from './pages/room-management'
import RoomManagementViewPage from './pages/room-management/[id]'

//e-learning
import ModuleListPage from './pages/e-learning/module'
import ModuleFormPage from './pages/e-learning/module/form'
import ModuleEditPage from './pages/e-learning/module/edit/[id]'
import ModuleViewPage from './pages/e-learning/module/view/[id]'
import CourseListPage from './pages/e-learning/course'
import CourseFormPage from './pages/e-learning/course/[createType]'
import CourseEditPage from './pages/e-learning/course/edit/[id]'
import CourseViewPage from './pages/e-learning/course/view/[id]'
import CourseLearnerPage from './pages/e-learning/course/learner/[id]'
import LearningPathListPage from './pages/e-learning/learning-path'
import LearningPathFormPage from './pages/e-learning/learning-path/form'
import LearningPathViewPage from './pages/e-learning/learning-path/view/[id]'
import LearningPathEditPage from './pages/e-learning/learning-path/edit/[id]'
import LearningPathPreviewPage from './pages/e-learning/learning-path/preview'

import CourseBatchViewPage from './pages/e-learning/course-batch/view/[id]'
import CourseBatchLearnerPage from './pages/e-learning/course-batch/learner'
import ELearningPreviewPage from './pages/e-learning/preview'
import QuestionListPage from './pages/e-learning/question'

import ELearningDashboardPage from './pages/e-learning/dashboard'

//examination and station
import ExaminationFormPage from './pages/master-data/examination/form'
import ExaminationEditPage from './pages/master-data/examination/edit/[id]'
import ExaminationViewPage from './pages/master-data/examination/[id]'
import ExaminationListPage from './pages/master-data/examination/list'
import StationPage from './pages/master-data/examination/station/list'

import StationFormPage from './pages/master-data/examination/station/form'
import StationEditPage from './pages/master-data/examination/station/edit/[id]'
import StationViewPage from './pages/master-data/examination/station/[id]'

//report-course-setting
import ReportCourseSettingPage from './pages/report-course-setting/list'
import ReportCourseSettingDashboardPage from './pages/report-course-setting-dashboard'

//report-manage-class
import ReportClassListPage from './pages/report-class/list'
import ReportExportByLearnerListPage from './pages/report-export-by-learner/list'
import ReportForHFListPage from './pages/report-for-hf/list'
import ReportWorkloadTOListPage from './pages/report-workload-to/list'
import ReportRawDataByClassListPage from './pages/e-testing/report-raw-data-by-class/list'
import ReportRawDataByLearnerListPage from './pages/e-testing//report-raw-data-by-learner/list'

//report-e-examination
import ReportExamResultListPage from './pages/e-examination/report/result'
import ReportExamEnrollListPage from './pages/e-examination/report/enroll'
import ReportExamScheduleListPage from './pages/e-examination/report/schedule'

//report-manage-profile
import ReportManageAgentListPage from './pages/report-manage-profile/agent'
import ReportManageProspectListPage from './pages/report-manage-profile/prospect'
import ReportManageStaffListPage from './pages/report-manage-profile/staff'

//e-examination
import EExamReportPage from './pages/e-examination/report'
import EExamDashboardPage from './pages/e-examination/dashboard'
import EExamListPage from './pages/e-examination/list'
import EExamFormPage from './pages/e-examination/form'
import EExamFormEditPage from './pages/e-examination/form/[id]'
import EExamExaminerListPage from './pages/e-examination/examiner/list/[id]'
import EExamExaminerDetailPage from './pages/e-examination/examiner/detail/[id]'

//e-configuration
import EConfigurationPage from './pages/e-configuration'

//inventory - stock
import StockListPage from './pages/inventory/stock/list'
import StockFromPage from './pages/inventory/stock/form'
import StockFromEditPage from './pages/inventory/stock/form/[id]'
import StockDetailPage from './pages/inventory/stock/detail/[id]'

//inventory - transfer
import { InventoryTransferListPage } from './pages/inventory/transfer/list'
import { InventoryTransferFormPage } from './pages/inventory/transfer/form/[uuid]'
import { InventoryTransferViewPage } from './pages/inventory/transfer/view/[uuid]'
//inventory - product
import InventoryProductListPage from './pages/inventory/product/list'

//inventory - product - stock
import ProductStockFormPage from './pages/inventory/product/stock/form'
import ProductStockFormEditPage from './pages/inventory/product/stock/form/[id]'
import ProductStockViewPage from './pages/inventory/product/stock/view/[id]'

//inventory - product - asset
import ProductAssetFormPage from './pages/inventory/product/asset/form'
import ProductAssetFormEditPage from './pages/inventory/product/asset/form/[id]'
import ProductAssetViewPage from './pages/inventory/product/asset/view/[id]'

//inventory - product - license
import ProductLicenseFormPage from './pages/inventory/product/license/form'
import ProductLicenseFormEditPage from './pages/inventory/product/license/form/[id]'
import ProductLicenseViewPage from './pages/inventory/product/license/view/[id]'

//inventory - requisition
import { InventoryRequisitionPage } from './pages/inventory/requisition/list'
import { InventoryRequisitionFormPage } from './pages/inventory/requisition/form/[uuid]'
import { InventoryRequisitionMonthlyPlanFormPage } from './pages/inventory/requisition/form/monthly-plan/[uuid]'
import { InventoryRequisitionOtherFormPage } from './pages/inventory/requisition/form/other/[uuid]'
import { InventoryRequisitionViewPage } from './pages/inventory/requisition/view/[uuid]'

// e-content
import { EContentContentListPage } from './pages/e-content/content/list'
import EContentFormPage from './pages/e-content/content/form/[uuid]'
import { EContentView } from './modules/EContent/Content/View'
import { EContentPreviewPage } from './pages/e-content/content/preview'
import { EContentCategoryListPage } from './pages/e-content/category/list'
import { EContentCategoryFormPage } from './pages/e-content/category/form/[uuid]'
import { EContentCategoryViewPage } from './pages/e-content/category/view/[uuid]'
import { EContentSubCategoryListPage } from './pages/e-content/sub-category/list'
import { EContentSubCategoryFormPage } from './pages/e-content/sub-category/form/[uuid]'
import { EContentSubCategoryViewPage } from './pages/e-content/sub-category/view/[uuid]'

//catalog
import CatalogPage from './pages/learning-point/catalog'
import CatalogCreatePage from './pages/learning-point/catalog/create'
import CatalogEditPage from './pages/learning-point/catalog/edit/[id]'
import CatalogViewPage from './pages/learning-point/catalog/view/[id]'

// setting point
import SettingPointPage from './pages/learning-point/setting-point'
import SettingPointCreatePage from './pages/learning-point/setting-point/create'
import SettingPointEditPage from './pages/learning-point/setting-point/edit/[id]'
import SettingPointViewPage from './pages/learning-point/setting-point/view/[id]'

import { AccountCodeViewPage } from './pages/master-data/account-code/view/[uuid]'
import { AccountCodeFormPage } from './pages/master-data/account-code/form/[uuid]'
import { AccountCodeListPage } from './pages/master-data/account-code/list'
import { InventoryCategoryListPage } from './pages/master-data/inventory/category/list'
import { InventorySubCategoryListPage } from './pages/master-data/inventory/sub-category/list'
import { InventoryCategoryFormPage } from './pages/master-data/inventory/category/form/[uuid]'
import { InventoryCategoryViewPage } from './pages/master-data/inventory/category/view/[uuid]'
import { InventorySubCategoryFormPage } from './pages/master-data/inventory/sub-category/form/[uuid]'
import { InventorySubCategoryViewPage } from './pages/master-data/inventory/sub-category/view/[uuid]'
import { ExpenseCategoryViewPage } from './pages/master-data/expense-category/view/[uuid]'
import { ExpenseCategoryFormPage } from './pages/master-data/expense-category/form/[uuid]'
import { ExpenseCategoryListPage } from './pages/master-data/expense-category/list'
import { CostCenterViewPage } from './pages/master-data/cost-center/view/[uuid]'
import { CostCenterFormPage } from './pages/master-data/cost-center/form/[uuid]'
import { CostCenterListPage } from './pages/master-data/cost-center/list'

import { VendorManagementListPage } from './pages/master-data/vendor-management/list'
import { VendorManagementFormPage } from './pages/master-data/vendor-management/form/[uuid]'
import { VendorManagementViewPage } from './pages/master-data/vendor-management/view/[uuid]'

//budget-management
import BudgetManagementListPage from './pages/budget-management/list'
import BudgetManagementFormPage from './pages/budget-management/form'
import BudgetManagementViewPage from './pages/budget-management/view/[id]'
import { ELearningCollectionListPage } from './pages/e-learning/collection/list'
import { ELearningCollectionFormPage } from './pages/e-learning/collection/form/[uuid]'
import { ELearningCollectionViewPage } from './pages/e-learning/collection/view/[uuid]'
import { ELearningCollectionPreviewPage } from './pages/e-learning/collection/preview'
import Error404 from './pages/error/404'

import ReportMonthlyPlanPage from './pages/monthly-plan/report/list'

const routeList = [
  { path: '/', component: Login },
  { path: '/login', component: LoginPage },
  {
    path: '/course-setting',
    component: CourseSetting,
    permission: permission.permissionCourseSetting,
  },
  {
    path: '/report-course-setting/dashboard',
    component: ReportCourseSettingDashboardPage,
    permission: permission.permissionDashboardCourseSetting,
  },
  {
    path: '/report-course-setting',
    component: ReportCourseSettingPage,
    permission: permission.permissionReportCourseSetting,
  },
  {
    path: '/course-setting/form',
    component: CourseSettingForm,
    permission: [
      {
        module: 'COURSE_SETTING',
        owner: 'PIC',
        permission: ['CREATE'],
      },
      {
        module: 'COURSE_SETTING',
        owner: 'ALL',
        permission: ['CREATE'],
      },
    ],
    noPermissionPath: '/course-setting',
  },
  { path: '/course-setting/version/:id', component: CourseSettingVersion },
  { path: '/course-setting/version', component: CourseSettingVersionIndex },
  {
    path: '/course-setting/version/form/:id',
    component: CourseSettingVersionForm,
  },
  {
    path: '/course-setting/version/edit/:id',
    component: EditCourseSettingVersion,
    permission: [
      {
        module: 'COURSE_SETTING',
        owner: 'PIC',
        permission: ['CREATE'],
      },
      {
        module: 'COURSE_SETTING',
        owner: 'ALL',
        permission: ['CREATE'],
      },
      {
        module: 'COURSE_SETTING',
        owner: 'ALL',
        permission: ['VIEW'],
      },
    ],
    noPermissionPath: '/course-setting',
  },
  { path: '/course-setting/version/preview/:id', component: CourseSettingView },
  {
    path: '/course-setting/clone/version/:id',
    component: CourseSettingCloneVersionForm,
  },
  {
    path: '/course-setting/clone/course/:id',
    component: CourseSettingCloneCourseForm,
  },
  //distribution
  {
    path: '/master-data/distribution-channel',
    component: DistributionChannelList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/distribution-channel/form',
    component: AddDistributionChannel,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/distribution-channel/edit/:id',
    component: EditDistributionChannel,
  },
  {
    path: '/master-data/distribution-channel/:id',
    component: ViewDistributionChannel,
    permission: permission.permissionViewMasterData,
  },
  //level of learner
  {
    path: path.levelOfLearner,
    component: LevelOfLearnerList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: `${path.levelOfLearner}/form`,
    component: AddLevelOfLearner,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: `${path.levelOfLearner}/edit/:id`,
    component: EditLevelOfLearner,
    permission: permission.permissionViewMasterData,
  },
  {
    path: `${path.levelOfLearner}/:id`,
    component: ViewLevelOfLearner,
    permission: permission.permissionViewMasterData,
  },
  //level
  {
    path: path.level,
    component: LevelList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: `${path.level}/form`,
    component: LevelForm,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: `${path.level}/edit/:id`,
    component: EditLevelForm,
    permission: permission.permissionViewMasterData,
  },
  {
    path: `${path.level}/:id`,
    component: LevelView,
    permission: permission.permissionViewMasterData,
  },
  //product type
  {
    path: '/master-data/product-type',
    component: ProductTypeList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/product-type/form',
    component: AddProductType,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/product-type/edit/:id',
    component: EditProductType,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/product-type/:id',
    component: ViewProductType,
    permission: permission.permissionCreateMasterData,
  },
  //roadmap
  {
    path: '/master-data/road-map',
    component: RoadMapList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/road-map/form',
    component: AddRoadMap,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/road-map/edit/:id',
    component: EditRoadMap,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/road-map/:id',
    component: ViewRoadMap,
    permission: permission.permissionViewMasterData,
  },
  //training platform
  {
    path: '/master-data/training-platform',
    component: TrainingPlatformList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/training-platform/form',
    component: AddTrainingPlatform,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/training-platform/edit/:id',
    component: EditTrainingPlatform,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/training-platform/:id',
    component: ViewTrainingPlatform,
    permission: permission.permissionViewMasterData,
  },
  //acquired skill
  {
    path: '/master-data/acquired-skill',
    component: AcquiredSkillList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/acquired-skill/form',
    component: AddAcquiredSkill,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/acquired-skill/edit/:id',
    component: EditAcquiredSkill,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/acquired-skill/:id',
    component: ViewAcquiredSkill,
    permission: permission.permissionViewMasterData,
  },
  //skill type
  {
    path: '/master-data/skill-type',
    component: SkillTypeList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/skill-type/form',
    component: AddSkillType,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/skill-type/edit/:id',
    component: EditSkillType,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/skill-type/:id',
    component: ViewSkillType,
    permission: permission.permissionViewMasterData,
  },
  //job title
  {
    path: '/master-data/job-title',
    component: JobTitleList,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/job-title/form',
    component: AddJobTitle,
    permission: permission.permissionCreateMasterData,
  },
  {
    path: '/master-data/job-title/edit/:id',
    component: EditJobTitle,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/master-data/job-title/:id',
    component: ViewJobTitle,
    permission: permission.permissionViewMasterData,
  },
  //monthy plan
  {
    path: '/monthly-plan/calendar',
    component: Calendar,
    permission: permission.permissionMonthlyPlan,
  },
  {
    path: '/monthly-plan/calendar/:id',
    component: CalendarById,
  },
  {
    path: '/monthly-plan/booking/:id',
    component: Booking,
  },
  //manage staff
  {
    path: '/monthly-plan/speaker-requester',
    component: SpeakerRequesterList,
  },
  {
    path: '/monthly-plan/speaker-approver',
    component: SpeakerApproverList,
  },
  //manage staff
  {
    path: '/monthly-plan/speaker-requester',
    component: SpeakerRequesterList,
  },
  //monthly plan dashboard
  {
    path: '/monthly-plan/class/dashboard',
    component: ClassDashboardPage,
    permission: [
      ...permission.permissionReportDashboardMonthlyPlan,
      ...permission.permissionMonthlyPlan,
    ],
  },
  {
    path: '/monthly-plan/workload/dashboard',
    component: WorkloadDashboardPage,
    permission: [
      ...permission.permissionReportDashboardMonthlyPlan,
      ...permission.permissionMonthlyPlan,
    ],
  },
  //monthly plan report
  {
    path: '/monthly-plan/report',
    component: ReportMonthlyPlanPage,
    permission: [
      ...permission.permissionReportDashboardMonthlyPlan,
      ...permission.permissionMonthlyPlan,
    ],
  },
  //manage
  {
    path: '/manage/staff-profile',
    component: StaffProfileList,
  },
  {
    path: '/manage/staff-profile/form',
    component: AddStaffProfile,
  },
  {
    path: '/manage/staff-profile/:id',
    component: ViewStaffProfile,
  },
  {
    path: '/manage/staff-profile/edit/:id',
    component: EditStaffProfile,
  },
  {
    path: '/manage/permission',
    component: PermissionList,
    permission: permission.permissionManagePermission,
  },
  {
    path: '/manage/permission/form',
    component: AddPermission,
    permission: permission.permissionManagePermission,
  },
  {
    path: '/manage/permission/:id',
    component: ViewPermission,
    permission: permission.permissionManagePermission,
  },
  {
    path: '/manage/permission/edit/:id',
    component: EditPermission,
    permission: permission.permissionManagePermission,
  },
  {
    path: '/manage/agent-profile',
    component: AgentProfilePage,
    permission: [
      ...permissionAgentProfile.permissionAgentProfileMenu,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/agent-profile/view/:id',
    component: AgentProfileViewPage,
    permission: [
      ...permissionAgentProfile.permissionAgentProfileView,
      ...permission.permissionELearningCourseEdit,
    ],
  },

  {
    path: '/manage/cs-agent-profile/edit/:id',
    component: CsAgentProfileFormPage,
    permission: permissionCSAgentProfile.permissionCSAgentProfileView,
  },
  {
    path: '/manage/cs-agent-profile/view/:id',
    component: CsAgentProfileViewPage,
    permission: [
      ...permissionCSAgentProfile.permissionCSAgentProfileView,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/cs-agent-profile',
    component: CsAgentProfilePage,
    permission: [
      ...permissionCSAgentProfile.permissionCSAgentProfileMenu,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/prospect-profile',
    component: ProspectProfilePage,
    permission: [
      ...permissionProspectProfile.permissionProspectProfileMenu,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/prospect-profile/view/:id',
    component: ProspectProfileViewPage,
    permission: [
      ...permissionProspectProfile.permissionProspectProfileView,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/prospect-profile/edit/:id',
    component: ProspectProfileFormPage,
    permission: permissionProspectProfile.permissionProspectProfileEdit,
  },
  {
    path: '/manage/cs-user-profile',
    component: CsUserProfilePage,
    permission: [
      ...permissionCSUserProfile.permissionCSUserProfileMenu,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/cs-user-profile/view/:id',
    component: CsUserProfileViewPage,
    permission: [
      ...permissionCSUserProfile.permissionCSUserProfileView,
      ...permission.permissionELearningCourseEdit,
    ],
  },
  {
    path: '/manage/cs-user-profile/edit/:id',
    component: CsUserProfileFormPage,
    permission: permissionCSUserProfile.permissionCSUserProfileEdit,
  },
  {
    path: '/manage/dashboard',
    component: ManageProfileDashboardPage,
    permission: [],
  },
  {
    path: '/manage/report-manage-profile/agent',
    component: ReportManageAgentListPage,
    permission: [],
  },
  {
    path: '/manage/report-manage-profile/prospect',
    component: ReportManageProspectListPage,
    permission: [],
  },
  {
    path: '/manage/report-manage-profile/staff',
    component: ReportManageStaffListPage,
    permission: [],
  },

  // e-testing
  {
    path: '/e-testing',
    component: ETestingList,
    permission: permission.permissionETestingMenu, // menu
  },
  {
    path: '/e-testing/version',
    component: ETestingVersionIndex,
    permission: permission.permissionETestingMenu, // menu
  },
  {
    path: '/e-testing/version/:id',
    component: ETestingVersion,
    permission: permission.permissionETestingMenu, // menu // action- > view, edit
  },
  {
    path: '/e-testing/version/view/:id',
    component: ETestingVersionView,
    permission: permission.permissionETestingView, // view
  },
  {
    path: '/e-testing/version/edit/:id',
    component: ETestingVersionEdit,
    permission: permission.permissionETestingEdit, // edit
  },
  {
    path: '/e-testing/view-mode/:id',
    component: ETestingVersionEdit,
    permission: permission.permissionETestingView, // edit
  },
  {
    path: '/e-testing/version/clone/:id',
    component: ETestingVersionClone,
    permission: permission.permissionETestingCreate, //  create
  },
  {
    path: '/e-testing/create-version/:createType/:codeId',
    component: ETestingCreateVersion,
    permission: permission.permissionETestingCreate, // create
  },
  {
    path: '/e-testing/form/:createType',
    component: ETestingFrom,
    permission: permission.permissionETestingCreate, // create
  },
  {
    path: '/e-testing/preview',
    component: ETestingPreviewIndex,
    permission: permission.permissionETestingEdit, //  view
  },
  {
    path: '/e-testing/preview/:id',
    component: ETestingPreview,
    permission: permission.permissionETestingView, //   view
  },
  {
    path: '/e-testing/preview/testing/:id',
    component: ETestingPreviewTesting,
    permission: permission.permissionETestingView, // view
  },
  {
    path: '/e-testing/preview/result/:id',
    component: ETestingPreviewResult,
    permission: permission.permissionETestingView, // view
  },
  // e-testing standalone check exam
  {
    path: '/e-testing/check-exam/list',
    component: ETestingCheckExamList,
    permission: permission.permissionStandaloneViewExam,
  },
  {
    path: '/e-testing/check-exam/version/:id',
    component: ETestingCheckExamVersionList,
    permission: permission.permissionStandaloneViewExam,
  },
  {
    path: '/e-testing/check-exam/:eTestingVersionUuid',
    component: ETestingCheckETesting,
    permission: permission.permissionStandaloneEditExam,
  },
  {
    path: '/e-testing/report-raw-data-by-class',
    component: ReportRawDataByClassListPage,
    permission: permission.permissionETestingView,
  },
  {
    path: '/e-testing/report-raw-data-by-learner',
    component: ReportRawDataByLearnerListPage,
    permission: permission.permissionETestingView,
  },
  // e-valuation
  {
    path: '/e-evaluation',
    component: EEvaluationList,
    permission: permission.permissionEValuationMenu,
  },
  {
    path: '/e-evaluation/version',
    component: EEvaluationVersionIndex,
    permission: permission.permissionEValuationMenu,
  },
  {
    path: '/e-evaluation/version/:id',
    component: EEvaluationVersion,
    permission: permission.permissionEValuationMenu,
  },
  {
    path: '/e-evaluation/version/view/:id',
    component: EEvaluationVersionView,
    permission: permission.permissionEValuationView,
  },
  {
    path: '/e-evaluation/form/:createType',
    component: EEvaluationForm,
    permission: permission.permissionEValuationCreate,
  },
  {
    path: '/e-evaluation/version/edit/:id',
    component: EEvaluationVersionEdit,
    permission: permission.permissionEValuationEdit,
  },
  {
    path: '/e-evaluation/view-mode/:id',
    component: EEvaluationVersionEdit,
    permission: permission.permissionEValuationView,
  },
  {
    path: '/e-evaluation/create-version/:createType/:codeId',
    component: EEvaluationCreateVersion,
    permission: permission.permissionEValuationEdit,
  },
  {
    path: '/e-evaluation/preview',
    component: EEvaluationPreviewIndex,
    permission: permission.permissionEValuationEdit,
  },
  {
    path: '/e-evaluation/preview/:id',
    component: EEvaluationPreview,
    permission: permission.permissionEValuationEdit,
  },
  {
    path: '/e-evaluation/preview/evaluate/:id',
    component: EEvaluationPreviewTesting,
    permission: permission.permissionEValuationEdit,
  },
  {
    path: '/e-evaluation/version/clone/:id',
    component: EEvaluationVersionClone,
    permission: permission.permissionEValuationEdit,
  },

  //api-to-rcms
  {
    path: '/api-to-rcms',
    component: ApiToRcmsList,
    permission: permissionApiToRcms.permissionApiToRcmsMenu,
  },
  {
    path: '/api-to-rcms/detail/:id',
    component: ApiToRcmsDetailList,
    permission: permissionApiToRcms.permissionApiToRcmsView,
  },
  {
    path: '/api-to-rcms/preview/:uuid/:id',
    component: ApiToRcmsPreview,
    permission: permissionApiToRcms.permissionApiToRcmsView,
  },

  //e-certification
  {
    path: '/e-certification',
    component: ECertificationList,
    permission: permissionECertification.permissionECertificationMenu,
  },
  {
    path: '/e-certification/background',
    component: ECertificationBackgroundList,
    permission: permissionECertification.permissionECertificationView,
  },
  {
    path: '/e-certification/background/form',
    component: ECertificationBackgroundForm,
    permission: permissionECertification.permissionECertificationCreateBg,
  },
  {
    path: '/e-certification/background/view/:id',
    component: ECertificationBackgroundView,
    permission: permissionECertification.permissionECertificationView,
  },
  {
    path: '/e-certification/background/edit/:id',
    component: ECertificationBackgroundEdit,
    permission: permissionECertification.permissionECertificationEdit,
  },

  {
    path: '/e-certification/version/:id',
    component: ECertificationVersionList,
    permission: permissionECertification.permissionECertificationView,
  },
  {
    path: '/e-certification/form',
    component: ECertificationForm,
    permission: permissionECertification.permissionECertificationCreate,
  },

  {
    path: '/e-certification/create-version/:codeId',
    component: ECertificationCreateVersion,
    permission: permissionECertification.permissionECertificationEdit,
  },

  {
    path: '/e-certification/version/edit/:id',
    component: ECertificationVersionEdit,
    permission: permissionECertification.permissionECertificationEdit,
  },
  {
    path: '/e-certification/version/view/:id',
    component: ECertificationVersionView,
    permission: permissionECertification.permissionECertificationView,
  },
  {
    path: '/e-certification/version/clone/:id',
    component: ECertificationVersionClone,
    permission: permissionECertification.permissionECertificationEdit,
  },
  // manage-class
  {
    path: '/manage-class',
    component: ManageClassList,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/setting/:id',
    component: ManageClassSetting,
    permission: permission.permissionManageClassEditClass,
  },
  //manage-class-oic
  {
    path: '/manage-class/oic',
    component: ManageClassOicList,
    permission: permission.permissionManageClassMenuOic,
  },
  {
    path: '/manage-class/oic/:uuid',
    component: ManageClassOicView,
    permission: permission.permissionManageClassMenuOic,
  },
  // attendance
  {
    path: '/manage-class/attendance/:id',
    component: ManageClassAttendance,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/attendance/list/:id',
    component: ManageClassAttendanceList,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/detail/:id',
    component: ManageClassDetail,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/learner/:id',
    component: ManageClassLearner,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/learner/application-documents/:id',
    component: ManageClassApplicationDocuments,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/detail/evaluation-status/:id',
    component: ManageClassEvaStatus,
    permission: permission.permissionManageClassMenuClass,
  },
  {
    path: '/manage-class/dashboard',
    component: ManageClassDashboard,
    permission: permission.permissionReportManageClassDashboard,
  },

  //  Document
  {
    path: '/manage-class/learner/document/:id',
    component: ManageClassDocument,
    permission: permission.permissionManageClassMenuClass,
  },
  //   learner detail
  {
    path: '/manage-class/learner/detail/:id',
    component: ManageClassLearnerDetail,
    permission: permission.permissionManageClassMenuClass,
  },
  // check exam
  {
    path: '/manage-class/check-exam/list',
    component: ManageClassCheckExamList,
    permission: permission.permissionManageClassMenuExam,
  },
  {
    path: '/manage-class/check-exam/:id/:courseId',
    component: ManageClassCheckETesting,
    permission: permission.permissionManageClassEditExam,
  },

  // manage-class report
  {
    path: '/manage-class/report-export-by-class',
    component: ReportClassListPage,
    permission: permission.permissionReportManageClassByClass,
  },
  {
    path: '/manage-class/report-export-by-learner',
    component: ReportExportByLearnerListPage,
    permission: permission.permissionReportManageClassByLearner,
  },
  {
    path: '/manage-class/report-for-hf',
    component: ReportForHFListPage,
    permission: permission.permissionReportManageClassHF,
  },
  {
    path: '/manage-class/report-workload-to',
    component: ReportWorkloadTOListPage,
    permission: permission.permissionReportManageClassWorkloadTo,
  },
  //License
  {
    path: '/master-data/license',
    component: LicenseList,
  },
  {
    path: '/master-data/license/form',
    component: LicenseForm,
  },
  {
    path: '/master-data/license/edit/:id',
    component: LicenseForm,
  },
  {
    path: '/master-data/license/view/:id',
    component: LicenseView,
  },
  // manage content
  {
    path: '/manage-content/home',
    component: ContentHomePage,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/manage-content/home/preview',
    component: ContentHomePreview,
    permission: permission.permissionViewMasterData,
  },
  // manage content login
  {
    path: '/manage-content/login',
    component: ContentLoginPage,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/manage-content/login/preview',
    component: ContentLoginPreview,
    permission: permission.permissionViewMasterData,
  },
  // manage content station
  {
    path: '/manage-content/station',
    component: ContentStationPage,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/manage-content/station/preview',
    component: ContentStationPreview,
    permission: permission.permissionViewMasterData,
  },
  // manage content e learning home
  {
    path: '/manage-content/home-e-learning',
    component: ContentELearningHomePage,
    permission: permission.permissionViewMasterData,
  },
  {
    path: '/manage-content/home-e-learning/preview',
    permission: permission.permissionViewMasterData,
    component: ContentElearningHomePreview,
  },

  //Meeting Room
  { path: '/master-data/meeting-room', component: MeetingRoomListPage },
  { path: '/master-data/meeting-room/form', component: MeetingRoomFormPage },
  {
    path: '/master-data/meeting-room/edit/:id',
    component: MeetingRoomFormPage,
  },
  {
    path: '/master-data/meeting-room/view/:id',
    component: MeetingRoomView,
  },

  { path: '/test/drag-and-drop', component: DragAndDropPage },

  {
    path: '/report/raw-data',
    component: ReportPage,
    permission: permission.permissionViewMasterData,
  },

  { path: '/to-do-list', component: ToDoListPage },

  // room-management
  {
    path: '/room-management',
    component: RoomManagementPage,
    permission: permission.permissionRoomManagementMenu,
  },
  {
    path: '/room-management/view/:id',
    component: RoomManagementViewPage,
    permission: permission.permissionRoomManagementView,
  },

  // e-learning
  {
    path: '/e-learning/module',
    component: ModuleListPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/module/form',
    component: ModuleFormPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/module/form/:id',
    component: ModuleEditPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/module/view/:id',
    component: ModuleViewPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/collection',
    component: ELearningCollectionListPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/collection/form',
    component: ELearningCollectionFormPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/collection/form/:uuid',
    component: ELearningCollectionFormPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/collection/view/:uuid',
    component: ELearningCollectionViewPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/collection/preview/',
    component: ELearningCollectionPreviewPage,
    permission: permission.permissionELearningCourseEdit,
  },
  { path: '/e-learning/course', component: CourseListPage },
  {
    path: '/e-learning/course/form/:createType',
    component: CourseFormPage,
    permission: permission.permissionELearningCourseCreate,
  },
  {
    path: '/e-learning/course/edit/:id',
    component: CourseEditPage,
    permission: permission.permissionELearningCourseEdit,
  },
  {
    path: '/e-learning/course/view/:id',
    component: CourseViewPage,
    permission: permission.permissionELearningCourseView,
  },
  { path: '/e-learning/course/learner/view/:id', component: CourseLearnerPage },
  {
    path: '/e-learning/course/batch/view/:id',
    component: CourseBatchViewPage,
    permission: permission.permissionELearningCourseView,
  },
  {
    path: '/e-learning/course/batch/view/learner/:id',
    component: CourseBatchLearnerPage,
    permission: permission.permissionELearningCourseView,
  },
  {
    path: '/e-learning/course/batch/learner/view/:id',
    component: CourseLearnerPage,
    permission: permission.permissionELearningCourseView,
  },
  {
    path: '/e-learning/learning-path',
    component: LearningPathListPage,
    permission: permission.permissionELearningCourseCreate,
  },
  {
    path: '/e-learning/learning-path/form',
    component: LearningPathFormPage,
    permission: permission.permissionELearningCourseCreate,
  },
  {
    path: '/e-learning/learning-path/view/:id',
    component: LearningPathViewPage,
    permission: permission.permissionELearningCourseCreate,
  },
  {
    path: '/e-learning/learning-path/edit/:id',
    component: LearningPathEditPage,
    permission: permission.permissionELearningCourseCreate,
  },
  {
    path: '/e-learning/learning-path/preview',
    component: LearningPathPreviewPage,
    permission: permission.permissionELearningCourseCreate,
  },
  { path: '/e-learning/preview', component: ELearningPreviewPage },
  { path: '/e-learning/preview/testing', component: ELearningPreviewPage },
  { path: '/e-learning/preview/evaluate', component: ELearningPreviewPage },
  { path: '/e-learning/question', component: QuestionListPage },
  {
    path: '/e-learning/dashboard',
    component: ELearningDashboardPage,
    permission: permission.permissionELearningCourseEdit,
  },

  { path: '/master-data/examination', component: ExaminationListPage },
  { path: '/master-data/examination/form', component: ExaminationFormPage },
  {
    path: '/master-data/examination/edit/:id',
    component: ExaminationEditPage,
  },
  {
    path: '/master-data/examination/view/:id',
    component: ExaminationViewPage,
  },

  { path: '/master-data/station', component: StationPage },
  { path: '/master-data/station/form', component: StationFormPage },
  {
    path: '/master-data/station/edit/:id',
    component: StationEditPage,
  },
  {
    path: '/master-data/station/view/:id',
    component: StationViewPage,
  },

  //e-examinition
  {
    path: '/e-examination/list',
    component: EExamListPage,
    permission: permission.permissionEExaminationView,
  },
  {
    path: '/e-examination/form',
    component: EExamFormPage,
    permission: permission.permissionEExaminationView,
  },
  {
    path: '/e-examination/form/:id',
    component: EExamFormEditPage,
    permission: permission.permissionEExaminationView,
  },
  {
    path: '/e-examination/examiner/list/:id',
    component: EExamExaminerListPage,
    permission: permission.permissionEExaminationView,
  },
  {
    path: '/e-examination/examiner/detail/:id',
    component: EExamExaminerDetailPage,
    permission: permission.permissionEExaminationView,
  },
  {
    path: '/e-examination/report',
    component: EExamReportPage,
    permission: permission.permissionEExaminationDc,
  },
  {
    path: '/e-examination/report-result',
    component: ReportExamResultListPage,
    permission: permission.permissionReportEExaminationResult,
  },
  {
    path: '/e-examination/report-schedule',
    component: ReportExamScheduleListPage,
    permission: permission.permissionReportEExaminationSchedule,
  },
  {
    path: '/e-examination/report-enroll',
    component: ReportExamEnrollListPage,
    permission: permission.permissionReportEExaminationEnroll,
  },
  {
    path: '/e-examination/dashboard',
    component: EExamDashboardPage,
    permission: permission.permissionDashboardEExamination,
  },

  //e-configuration
  {
    path: '/configuration',
    component: EConfigurationPage,
    permission: permission.permissionEConfigView,
  },

  //inventory - stock
  {
    path: '/inventory/stock',
    component: StockListPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/stock/form',
    component: StockFromPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/stock/form/:id',
    component: StockFromEditPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/stock/detail/:id',
    component: StockDetailPage,
    permission: permission.permissionInventoryView,
  },

  //inventory - transfer
  {
    path: '/inventory/transfer',
    component: InventoryTransferListPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/transfer/form',
    component: InventoryTransferFormPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/transfer/form/:uuid',
    component: InventoryTransferFormPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/transfer/view/:uuid',
    component: InventoryTransferViewPage,
    permission: permission.permissionInventoryView,
  },
  //inventory - product
  {
    path: '/inventory/product',
    component: InventoryProductListPage,
    permission: permission.permissionInventoryView,
  },

  //inventory - product - stock
  {
    path: '/inventory/product/stock/form',
    component: ProductStockFormPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/stock/form/:id',
    component: ProductStockFormEditPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/stock/view/:id',
    component: ProductStockViewPage,
    permission: permission.permissionInventoryView,
  },

  //inventory - product - asset
  {
    path: '/inventory/product/asset/form',
    component: ProductAssetFormPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/asset/form/:id',
    component: ProductAssetFormEditPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/asset/view/:id',
    component: ProductAssetViewPage,
    permission: permission.permissionInventoryView,
  },

  //inventory - product - license
  {
    path: '/inventory/product/license/form',
    component: ProductLicenseFormPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/license/form/:id',
    component: ProductLicenseFormEditPage,
    permission: permission.permissionInventoryView,
  },
  {
    path: '/inventory/product/license/view/:id',
    component: ProductLicenseViewPage,
    permission: permission.permissionInventoryView,
  },
  //inventory - requisition
  {
    path: '/inventory/requisition',
    component: InventoryRequisitionPage,
  },
  {
    path: '/inventory/requisition/other/form',
    component: InventoryRequisitionOtherFormPage,
  },
  {
    path: '/inventory/requisition/monthly-plan/form',
    component: InventoryRequisitionMonthlyPlanFormPage,
    permission: permission.permissionRequisitionView,
  },
  {
    path: '/inventory/requisition/form/:uuid',
    component: InventoryRequisitionFormPage,
  },
  // {
  //   path: '/inventory/requisition/other/form/:uuid',
  //   component: InventoryRequisitionOtherFormPage,
  // },
  // {
  //   path: '/inventory/requisition/monthly-plan/form/:uuid',
  //   component: InventoryRequisitionMonthlyPlanFormPage,
  // },
  {
    path: '/inventory/requisition/view/:uuid',
    component: InventoryRequisitionViewPage,
  },

  //e-content
  {
    path: '/e-content/content',
    component: EContentContentListPage,
    permission: permission.permissionEContentView,
  },
  {
    path: '/e-content/content/form',
    component: EContentFormPage,
    permission: permission.permissionEContentForm,
  },
  {
    path: '/e-content/content/form/:uuid',
    component: EContentFormPage,
    permission: permission.permissionEContentForm,
  },
  {
    path: '/e-content/content/view/:uuid',
    component: EContentView,
    permission: permission.permissionEContentView,
  },
  {
    path: '/e-content/content/preview',
    component: EContentPreviewPage,
    permission: permission.permissionEContentView,
  },
  { path: '/e-content/category', component: EContentCategoryListPage },
  { path: '/e-content/sub-category', component: EContentSubCategoryListPage },
  { path: '/inventory/category', component: InventoryCategoryListPage },
  { path: '/inventory/sub-category', component: InventorySubCategoryListPage },

  //catalog
  {
    path: '/learning-point/catalog',
    component: CatalogPage,
    permission: permission.permissionLearningPointCatalog,
  },
  {
    path: '/learning-point/catalog/form',
    component: CatalogCreatePage,
    permission: permission.permissionLearningPointCatalog,
  },
  {
    path: '/learning-point/catalog/edit/:id',
    component: CatalogEditPage,
    permission: permission.permissionLearningPointCatalog,
  },
  {
    path: '/learning-point/catalog/view/:id',
    component: CatalogViewPage,
    permission: permission.permissionLearningPointCatalog,
  },

  // setting point
  {
    path: '/learning-point/setting-point',
    component: SettingPointPage,
    permission: permission.permissionLearningPointSettingPoint,
  },
  {
    path: '/learning-point/setting-point/form',
    component: SettingPointCreatePage,
    permission: permission.permissionLearningPointSettingPoint,
  },
  {
    path: '/learning-point/setting-point/edit/:id',
    component: SettingPointEditPage,
    permission: permission.permissionLearningPointSettingPoint,
  },
  {
    path: '/learning-point/setting-point/view/:id',
    component: SettingPointViewPage,
    permission: permission.permissionLearningPointSettingPoint,
  },

  {
    path: '/master-data/e-content/category',
    component: EContentCategoryListPage,
  },
  {
    path: '/master-data/e-content/category/form/',
    component: EContentCategoryFormPage,
  },
  {
    path: '/master-data/e-content/category/form/:uuid',
    component: EContentCategoryFormPage,
  },
  {
    path: '/master-data/e-content/category/view/:uuid',
    component: EContentCategoryViewPage,
  },
  {
    path: '/master-data/e-content/sub-category',
    component: EContentSubCategoryListPage,
  },
  {
    path: '/master-data/e-content/sub-category/form/',
    component: EContentSubCategoryFormPage,
  },
  {
    path: '/master-data/e-content/sub-category/form/:uuid',
    component: EContentSubCategoryFormPage,
  },
  {
    path: '/master-data/e-content/sub-category/view/:uuid',
    component: EContentSubCategoryViewPage,
  },
  {
    path: '/master-data/Inventory/category',
    component: InventoryCategoryListPage,
  },
  {
    path: '/master-data/Inventory/category/form/',
    component: InventoryCategoryFormPage,
  },
  {
    path: '/master-data/Inventory/category/form/:uuid',
    component: InventoryCategoryFormPage,
  },
  {
    path: '/master-data/Inventory/category/view/:uuid',
    component: InventoryCategoryViewPage,
  },
  {
    path: '/master-data/Inventory/sub-category',
    component: InventorySubCategoryListPage,
  },
  {
    path: '/master-data/Inventory/sub-category/form/',
    component: InventorySubCategoryFormPage,
  },
  {
    path: '/master-data/Inventory/sub-category/form/:uuid',
    component: InventorySubCategoryFormPage,
  },
  {
    path: '/master-data/Inventory/sub-category/view/:uuid',
    component: InventorySubCategoryViewPage,
  },
  { path: '/master-data/account-code', component: AccountCodeListPage },
  {
    path: '/master-data/account-code/form/',
    component: AccountCodeFormPage,
  },
  {
    path: '/master-data/account-code/form/:uuid',
    component: AccountCodeFormPage,
  },
  {
    path: '/master-data/account-code/view/:uuid',
    component: AccountCodeViewPage,
  },
  { path: '/master-data/expense-category', component: ExpenseCategoryListPage },
  {
    path: '/master-data/expense-category/form/',
    component: ExpenseCategoryFormPage,
  },
  {
    path: '/master-data/expense-category/form/:uuid',
    component: ExpenseCategoryFormPage,
  },
  {
    path: '/master-data/expense-category/view/:uuid',
    component: ExpenseCategoryViewPage,
  },
  { path: '/master-data/cost-center', component: CostCenterListPage },
  {
    path: '/master-data/cost-center/form/',
    component: CostCenterFormPage,
  },
  {
    path: '/master-data/cost-center/form/:uuid',
    component: CostCenterFormPage,
  },
  {
    path: '/master-data/cost-center/view/:uuid',
    component: CostCenterViewPage,
  },

  {
    path: '/inventory-data/vendor-management',
    component: VendorManagementListPage,
    permission: permission.permissionVendorView,
  },
  {
    path: '/inventory-data/vendor-management/form',
    component: VendorManagementFormPage,
    permission: permission.permissionVendorView,
  },
  {
    path: '/inventory-data/vendor-management/form/:uuid',
    component: VendorManagementFormPage,
    permission: permission.permissionVendorView,
  },
  {
    path: '/inventory-data/vendor-management/view/:uuid',
    component: VendorManagementViewPage,
    permission: permission.permissionVendorView,
  },

  {
    path: '/budget-management',
    component: BudgetManagementListPage,
    permission: permission.permissionExpense,
  },
  {
    path: '/budget-management/form',
    component: BudgetManagementFormPage,
    permission: permission.permissionExpense,
  },
  {
    path: '/budget-management/form/:uuid',
    component: BudgetManagementFormPage,
    permission: permission.permissionExpense,
  },
  {
    path: '/budget-management/view/:uuid',
    component: BudgetManagementViewPage,
    permission: permission.permissionExpense,
  },
  {
    path: '*',
    component: Error404,
  },
]
const withLayout = (WL, permissionCheck) => {
  const Comp = (props) => {
    if (permissionCheck) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '45%',
            left: '45%',
          }}
        >
          <Typography variant="h4">403 Forbidden</Typography>
          <Button
            sx={{ alignSelf: 'center' }}
            variant="contained"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        </Box>
      )
    }
    return (
      <Layout layout={WL.templateLayout}>
        <WL {...props} />
      </Layout>
    )
  }
  Comp.displayName = 'withLayout'
  return Comp
}

let previousPath = ''
let currentPath = ''

const Index = (props) => {
  const { user } = props
  const location = useLocation()

  useEffect(() => {
    if (!previousPath && !currentPath) {
      previousPath = location.pathname
      currentPath = location.pathname
    } else {
      previousPath = currentPath
      currentPath = location.pathname
    }

    if (previousPath !== currentPath) {
      cancelPreviousPageRequests(previousPath)
    }
  }, [location.pathname])

  return (
    <Switch>
      {routeList.map((item) => {
        //check user permission can access
        const permissionCheck =
          !_.isNil(item.permission) &&
          !_.isNil(user) &&
          item.permission.length > 0 &&
          !hasPermission({
            userPermissions: user.permission,
            permissionList: item.permission,
          })
        return (
          <Route
            key={item.path}
            exact
            path={item.path}
            component={withLayout(item.component, permissionCheck)}
          />
        )
      })}
    </Switch>
  )
}
export default Index
