import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'
import { COLUMN_NAME_REPORT_CLASS } from '../reportClass/reportClass'

export const reportClassConst = {
  COLUMN_NAME: {
    NO: 'no',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
    TRAININGPLATFORM: 'trainingPlatform',
    EXPECT_NUMBER_PEOPLE: 'expectNumberPeople',
    COUNT_STUDENT: 'countStudent',
    COUNT_ATTENDACE: 'countAttendance',
    COUNT_NOT_ATTENDANCE: 'countNotAttendance',
    COUNT_PASS: 'countPass',
    COUNT_NOT_PASS: 'countNotPass',
    TEACHING_TIME: 'teachingTime',
    HOUR: 'hours',
    MINUTE: 'minutes',
    STATUS_CLASS: 'statusClass',
    DATE_TRAINING: 'dateTraining',
    START_CLASS_DATETIME: 'startClassDateTime',
    COUNT_SEAT_EMPTY: 'countSeatEmpty',
    END_CLASS_DATETIME: 'endClassDateTime',
    CLOSED_CLASS_DATETIME: 'closeClassDateTime',
    DIFF_DATETIME: 'diffClassDateTime',
    STATION: 'station',
    DEPARTMENT: 'department',
    TRAINER_ID_1: 'trainerId1',
    TRAINER_ID_2: 'trainerId2',
    TRAINER_ID_3: 'trainerId3',
    TRAINER_ID_4: 'trainerId4',
    TRAINER_ID_5: 'trainerId5',
    TRAINER_ID_6: 'trainerId6',
    TRAINER_ID_7: 'trainerId7',
    TRAINER_ID_8: 'trainerId8',
    TRAINER_ID_9: 'trainerId9',
    TRAINER_ID_10: 'trainerId10',
    TRAINER_ID_11: 'trainerId11',
    TRAINER_ID_12: 'trainerId12',
    TRAINER_ID_13: 'trainerId13',
    TRAINER_ID_14: 'trainerId14',
    TRAINER_ID_15: 'trainerId15',
    TRAINER_ID_16: 'trainerId16',
    TRAINER_ID_17: 'trainerId17',
    TRAINER_ID_18: 'trainerId18',
    TRAINER_ID_19: 'trainerId19',
    TRAINER_ID_20: 'trainerId20',
    TRAINER_ROLE_1: 'trainerRole1',
    TRAINER_ROLE_2: 'trainerRole2',
    TRAINER_ROLE_3: 'trainerRole3',
    TRAINER_ROLE_4: 'trainerRole4',
    TRAINER_ROLE_5: 'trainerRole5',
    TRAINER_ROLE_6: 'trainerRole6',
    TRAINER_ROLE_7: 'trainerRole7',
    TRAINER_ROLE_8: 'trainerRole8',
    TRAINER_ROLE_9: 'trainerRole9',
    TRAINER_ROLE_10: 'trainerRole10',
    TRAINER_ROLE_11: 'trainerRole11',
    TRAINER_ROLE_12: 'trainerRole12',
    TRAINER_ROLE_13: 'trainerRole13',
    TRAINER_ROLE_14: 'trainerRole14',
    TRAINER_ROLE_15: 'trainerRole15',
    TRAINER_ROLE_16: 'trainerRole16',
    TRAINER_ROLE_17: 'trainerRole17',
    TRAINER_ROLE_18: 'trainerRole18',
    TRAINER_ROLE_19: 'trainerRole19',
    TRAINER_ROLE_20: 'trainerRole20',
    TRAINER_NAME_1: 'trainerName1',
    TRAINER_NAME_2: 'trainerName2',
    TRAINER_NAME_3: 'trainerName3',
    TRAINER_NAME_4: 'trainerName4',
    TRAINER_NAME_5: 'trainerName5',
    TRAINER_NAME_6: 'trainerName6',
    TRAINER_NAME_7: 'trainerName7',
    TRAINER_NAME_8: 'trainerName8',
    TRAINER_NAME_9: 'trainerName9',
    TRAINER_NAME_10: 'trainerName10',
    TRAINER_NAME_11: 'trainerName11',
    TRAINER_NAME_12: 'trainerName12',
    TRAINER_NAME_13: 'trainerName13',
    TRAINER_NAME_14: 'trainerName14',
    TRAINER_NAME_15: 'trainerName15',
    TRAINER_NAME_16: 'trainerName16',
    TRAINER_NAME_17: 'trainerName17',
    TRAINER_NAME_18: 'trainerName18',
    TRAINER_NAME_19: 'trainerName19',
    TRAINER_NAME_20: 'trainerName20',
    MAIN_ADMIN_ID: 'adminId',
    MAIN_ADMIN_NAME: 'adminName',
    SUB_ADMIN_ID_1: 'assigneeId1',
    SUB_ADMIN_ID_2: 'assigneeId2',
    SUB_ADMIN_ID_3: 'assigneeId3',
    SUB_ADMIN_ID_4: 'assigneeId4',
    SUB_ADMIN_ID_5: 'assigneeId5',
    SUB_ADMIN_ID_6: 'assigneeId6',
    SUB_ADMIN_ID_7: 'assigneeId7',
    SUB_ADMIN_ID_8: 'assigneeId8',
    SUB_ADMIN_ID_9: 'assigneeId9',
    SUB_ADMIN_ID_10: 'assigneeId10',
    SUB_ADMIN_NAME_1: 'assigneeName1',
    SUB_ADMIN_NAME_2: 'assigneeName2',
    SUB_ADMIN_NAME_3: 'assigneeName3',
    SUB_ADMIN_NAME_4: 'assigneeName4',
    SUB_ADMIN_NAME_5: 'assigneeName5',
    SUB_ADMIN_NAME_6: 'assigneeName6',
    SUB_ADMIN_NAME_7: 'assigneeName7',
    SUB_ADMIN_NAME_8: 'assigneeName8',
    SUB_ADMIN_NAME_9: 'assigneeName9',
    SUB_ADMIN_NAME_10: 'assigneeName10',
    DISTRIBUTION: 'distribution',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    TRAINER_NAME: 'trainerName',
    VERSION_NUMBER: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
    TRAININGPLATFORM: 'รูปแบบการสอน',
    EXPECT_NUMBER_PEOPLE: 'จำนวนที่คาดหวัง',
    COUNT_STUDENT: 'จำนวนสมัคร',
    COUNT_ATTENDACE: 'จำนวนผู้เข้าเรียน',
    COUNT_NOT_ATTENDANCE: 'จำนวนคนไม่เข้าอบรม',
    COUNT_PASS: 'ผ่าน',
    COUNT_NOT_PASS: 'ไม่ผ่าน',
    COUNT_SEAT_EMPTY: 'จำนวนที่ว่าง',
    TEACHING_TIME: 'เวลาจัดสอนทั้งหมด',
    HOUR: 'เวลาจัดสอนทั้งหมด',
    MINUTE: 'นาที',
    STATUS_CLASS: 'สถานะคลาส',
    DATE_TRAINING: 'วันที่เริ่มอบรม',
    START_CLASS_DATETIME: 'วันเวลาเริ่มคลาส',
    END_CLASS_DATETIME: 'วันเวลาสิ้นสุดคลาส',
    CLOSED_CLASS_DATETIME: 'วันเวลาปิดคลาส',
    DIFF_DATETIME: 'วันเวลา Diff คลาส',
    STATION: 'Station',
    DEPARTMENT: 'Department',
    TRAINER_ID_1: 'Trainer ID (1)',
    TRAINER_ID_2: 'Trainer ID (2)',
    TRAINER_ID_3: 'Trainer ID (3)',
    TRAINER_ID_4: 'Trainer ID (4)',
    TRAINER_ID_5: 'Trainer ID (5)',
    TRAINER_ID_6: 'Trainer ID (6)',
    TRAINER_ID_7: 'Trainer ID (7)',
    TRAINER_ID_8: 'Trainer ID (8)',
    TRAINER_ID_9: 'Trainer ID (9)',
    TRAINER_ID_10: 'Trainer ID (10)',
    TRAINER_ID_11: 'Trainer ID (11)',
    TRAINER_ID_12: 'Trainer ID (12)',
    TRAINER_ID_13: 'Trainer ID (13)',
    TRAINER_ID_14: 'Trainer ID (14)',
    TRAINER_ID_15: 'Trainer ID (15)',
    TRAINER_ID_16: 'Trainer ID (16)',
    TRAINER_ID_17: 'Trainer ID (17)',
    TRAINER_ID_18: 'Trainer ID (18)',
    TRAINER_ID_19: 'Trainer ID (19)',
    TRAINER_ID_20: 'Trainer ID (20)',
    TRAINER_NAME_1: 'Trainer Name (1)',
    TRAINER_NAME_2: 'Trainer Name (2)',
    TRAINER_NAME_3: 'Trainer Name (3)',
    TRAINER_NAME_4: 'Trainer Name (4)',
    TRAINER_NAME_5: 'Trainer Name (5)',
    TRAINER_NAME_6: 'Trainer Name (6)',
    TRAINER_NAME_7: 'Trainer Name (7)',
    TRAINER_NAME_8: 'Trainer Name (8)',
    TRAINER_NAME_9: 'Trainer Name (9)',
    TRAINER_NAME_10: 'Trainer Name (10)',
    TRAINER_NAME_11: 'Trainer Name (11)',
    TRAINER_NAME_12: 'Trainer Name (12)',
    TRAINER_NAME_13: 'Trainer Name (13)',
    TRAINER_NAME_14: 'Trainer Name (14)',
    TRAINER_NAME_15: 'Trainer Name (15)',
    TRAINER_NAME_16: 'Trainer Name (16)',
    TRAINER_NAME_17: 'Trainer Name (17)',
    TRAINER_NAME_18: 'Trainer Name (18)',
    TRAINER_NAME_19: 'Trainer Name (19)',
    TRAINER_NAME_20: 'Trainer Name (20)',
    TRAINER_ROLE_1: 'Trainer Role (1)',
    TRAINER_ROLE_2: 'Trainer Role (2)',
    TRAINER_ROLE_3: 'Trainer Role (3)',
    TRAINER_ROLE_4: 'Trainer Role (4)',
    TRAINER_ROLE_5: 'Trainer Role (5)',
    TRAINER_ROLE_6: 'Trainer Role (6)',
    TRAINER_ROLE_7: 'Trainer Role (7)',
    TRAINER_ROLE_8: 'Trainer Role (8)',
    TRAINER_ROLE_9: 'Trainer Role (9)',
    TRAINER_ROLE_10: 'Trainer Role (10)',
    TRAINER_ROLE_11: 'Trainer Role (11)',
    TRAINER_ROLE_12: 'Trainer Role (12)',
    TRAINER_ROLE_13: 'Trainer Role (13)',
    TRAINER_ROLE_14: 'Trainer Role (14)',
    TRAINER_ROLE_15: 'Trainer Role (15)',
    TRAINER_ROLE_16: 'Trainer Role (16)',
    TRAINER_ROLE_17: 'Trainer Role (17)',
    TRAINER_ROLE_18: 'Trainer Role (18)',
    TRAINER_ROLE_19: 'Trainer Role (19)',
    TRAINER_ROLE_20: 'Trainer Role (20)',
    MAIN_ADMIN_ID: 'Admin หลัก ID',
    MAIN_ADMIN_NAME: 'Admin หลัก Name',
    SUB_ADMIN_ID_1: 'Admin รอง ID (1)',
    SUB_ADMIN_ID_2: 'Admin รอง ID (2)',
    SUB_ADMIN_ID_3: 'Admin รอง ID (3)',
    SUB_ADMIN_ID_4: 'Admin รอง ID (4)',
    SUB_ADMIN_ID_5: 'Admin รอง ID (5)',
    SUB_ADMIN_ID_6: 'Admin รอง ID (6)',
    SUB_ADMIN_ID_7: 'Admin รอง ID (7)',
    SUB_ADMIN_ID_8: 'Admin รอง ID (8)',
    SUB_ADMIN_ID_9: 'Admin รอง ID (9)',
    SUB_ADMIN_ID_10: 'Admin รอง ID (10)',
    SUB_ADMIN_NAME_1: 'Admin รอง Name (1)',
    SUB_ADMIN_NAME_2: 'Admin รอง Name (2)',
    SUB_ADMIN_NAME_3: 'Admin รอง Name (3)',
    SUB_ADMIN_NAME_4: 'Admin รอง Name (4)',
    SUB_ADMIN_NAME_5: 'Admin รอง Name (5)',
    SUB_ADMIN_NAME_6: 'Admin รอง Name (6)',
    SUB_ADMIN_NAME_7: 'Admin รอง Name (7)',
    SUB_ADMIN_NAME_8: 'Admin รอง Name (8)',
    SUB_ADMIN_NAME_9: 'Admin รอง Name (9)',
    SUB_ADMIN_NAME_10: 'Admin รอง Name (10)',
    DISTRIBUTION: 'ช่องทางการจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนชองทางการจำหน่ายอื่น',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    TRAINER_NAME: 'Trainer Name',
    VERSION_NUMBER: 'เลขเวอร์ชันของหลักสูตร',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_TEXT_SEARCH: 'INPUT_TEXT_SEARCH',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportClassDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportClassConst.COLUMN_NAME.NO,
      value: reportClassConst.COLUMN_NAME.NO,
      label: reportClassConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportClassConst.COLUMN_NAME.COURSE_CODE,
      value: reportClassConst.COLUMN_NAME.COURSE_CODE,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
    },

    {
      id: 6,
      name: reportClassConst.COLUMN_NAME.TRAININGPLATFORM,
      value: reportClassConst.COLUMN_NAME.TRAININGPLATFORM,
      label: reportClassConst.COLUMN_NAME_TH.TRAININGPLATFORM,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportClassConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportClassConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportClassConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
    },
    {
      id: 73,
      name: reportClassConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportClassConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportClassConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      value: reportClassConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_SEAT_EMPTY,
      minWidth: 160,
    },
    {
      id: 75,
      name: reportClassConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      value: reportClassConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_NOT_ATTENDANCE,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportClassConst.COLUMN_NAME.COUNT_ATTENDACE,
      value: reportClassConst.COLUMN_NAME.COUNT_ATTENDACE,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_ATTENDACE,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportClassConst.COLUMN_NAME.COUNT_PASS,
      value: reportClassConst.COLUMN_NAME.COUNT_PASS,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportClassConst.COLUMN_NAME.COUNT_NOT_PASS,
      value: reportClassConst.COLUMN_NAME.COUNT_NOT_PASS,
      label: reportClassConst.COLUMN_NAME_TH.COUNT_NOT_PASS,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportClassConst.COLUMN_NAME.HOUR,
      value: reportClassConst.COLUMN_NAME.HOUR,
      label: reportClassConst.COLUMN_NAME_TH.TEACHING_TIME,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportClassConst.COLUMN_NAME.STATUS_CLASS,
      value: reportClassConst.COLUMN_NAME.STATUS_CLASS,
      label: reportClassConst.COLUMN_NAME_TH.STATUS_CLASS,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportClassConst.COLUMN_NAME.START_CLASS_DATETIME,
      value: reportClassConst.COLUMN_NAME.START_CLASS_DATETIME,
      label: reportClassConst.COLUMN_NAME_TH.START_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportClassConst.COLUMN_NAME.END_CLASS_DATETIME,
      value: reportClassConst.COLUMN_NAME.END_CLASS_DATETIME,
      label: reportClassConst.COLUMN_NAME_TH.END_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportClassConst.COLUMN_NAME.CLOSED_CLASS_DATETIME,
      value: reportClassConst.COLUMN_NAME.CLOSED_CLASS_DATETIME,
      label: reportClassConst.COLUMN_NAME_TH.CLOSED_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportClassConst.COLUMN_NAME.DIFF_DATETIME,
      value: reportClassConst.COLUMN_NAME.DIFF_DATETIME,
      label: reportClassConst.COLUMN_NAME_TH.DIFF_DATETIME,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportClassConst.COLUMN_NAME.STATION,
      value: reportClassConst.COLUMN_NAME.STATION,
      label: reportClassConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_1,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_1,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_1,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_2,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_2,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_2,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_3,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_3,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_3,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_4,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_4,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_4,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_5,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_5,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_5,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_6,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_6,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_6,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_7,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_7,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_7,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_8,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_8,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_8,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_9,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_9,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_9,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_10,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_10,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_10,
      minWidth: 160,
    },
    {
      id: 76,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_11,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_11,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_11,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_12,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_12,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_12,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_13,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_13,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_13,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_14,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_14,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_14,
      minWidth: 160,
    },
    {
      id: 80,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_15,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_15,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_15,
      minWidth: 160,
    },
    {
      id: 81,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_16,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_16,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_16,
      minWidth: 160,
    },
    {
      id: 82,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_17,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_17,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_17,
      minWidth: 160,
    },
    {
      id: 83,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_18,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_18,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_18,
      minWidth: 160,
    },
    {
      id: 84,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_19,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_19,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_19,
      minWidth: 160,
    },
    {
      id: 85,
      name: reportClassConst.COLUMN_NAME.TRAINER_ID_20,
      value: reportClassConst.COLUMN_NAME.TRAINER_ID_20,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ID_20,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_1,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_1,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_1,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_2,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_2,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_2,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_3,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_3,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_3,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_4,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_4,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_4,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_5,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_5,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_5,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_6,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_6,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_6,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_7,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_7,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_7,
      minWidth: 160,
    },
    {
      id: 38,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_8,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_8,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_8,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_9,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_9,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_9,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_10,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_10,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_10,
      minWidth: 160,
    },
    {
      id: 88,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_11,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_11,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_11,
      minWidth: 160,
    },
    {
      id: 89,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_12,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_12,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_12,
      minWidth: 160,
    },
    {
      id: 90,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_13,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_13,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_13,
      minWidth: 160,
    },
    {
      id: 91,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_14,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_14,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_14,
      minWidth: 160,
    },
    {
      id: 92,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_15,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_15,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_15,
      minWidth: 160,
    },
    {
      id: 93,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_16,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_16,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_16,
      minWidth: 160,
    },
    {
      id: 94,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_17,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_17,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_17,
      minWidth: 160,
    },
    {
      id: 95,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_18,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_18,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_18,
      minWidth: 160,
    },
    {
      id: 96,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_19,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_19,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_19,
      minWidth: 160,
    },
    {
      id: 97,
      name: reportClassConst.COLUMN_NAME.TRAINER_NAME_20,
      value: reportClassConst.COLUMN_NAME.TRAINER_NAME_20,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_NAME_20,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_1,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_1,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_1,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_2,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_2,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_2,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_3,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_3,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_3,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_4,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_4,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_4,
      minWidth: 160,
    },
    {
      id: 45,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_5,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_5,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_5,
      minWidth: 160,
    },
    {
      id: 46,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_6,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_6,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_6,
      minWidth: 160,
    },
    {
      id: 47,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_7,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_7,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_7,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_8,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_8,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_8,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_9,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_9,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_9,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_10,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_10,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_10,
      minWidth: 160,
    },
    {
      id: 98,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_11,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_11,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_11,
      minWidth: 160,
    },
    {
      id: 99,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_12,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_12,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_12,
      minWidth: 160,
    },
    {
      id: 100,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_13,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_13,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_13,
      minWidth: 160,
    },
    {
      id: 101,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_14,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_14,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_14,
      minWidth: 160,
    },
    {
      id: 102,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_15,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_15,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_15,
      minWidth: 160,
    },
    {
      id: 103,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_16,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_16,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_16,
      minWidth: 160,
    },
    {
      id: 104,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_17,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_17,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_17,
      minWidth: 160,
    },
    {
      id: 105,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_18,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_18,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_18,
      minWidth: 160,
    },
    {
      id: 106,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_19,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_19,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_19,
      minWidth: 160,
    },
    {
      id: 107,
      name: reportClassConst.COLUMN_NAME.TRAINER_ROLE_20,
      value: reportClassConst.COLUMN_NAME.TRAINER_ROLE_20,
      label: reportClassConst.COLUMN_NAME_TH.TRAINER_ROLE_20,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportClassConst.COLUMN_NAME.MAIN_ADMIN_ID,
      value: reportClassConst.COLUMN_NAME.MAIN_ADMIN_ID,
      label: reportClassConst.COLUMN_NAME_TH.MAIN_ADMIN_ID,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportClassConst.COLUMN_NAME.MAIN_ADMIN_NAME,
      value: reportClassConst.COLUMN_NAME.MAIN_ADMIN_NAME,
      label: reportClassConst.COLUMN_NAME_TH.MAIN_ADMIN_NAME,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_1,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_1,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_1,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_2,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_2,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_2,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_3,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_3,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_3,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_4,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_4,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_4,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_5,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_5,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_5,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_6,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_6,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_6,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_7,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_7,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_7,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_8,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_8,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_8,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_9,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_9,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_9,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_10,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_ID_10,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_ID_10,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_1,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_1,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_1,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_2,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_2,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_2,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_3,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_3,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_3,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_4,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_4,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_4,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_5,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_5,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_5,
      minWidth: 160,
    },
    {
      id: 68,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_6,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_6,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_6,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_7,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_7,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_7,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_8,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_8,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_8,
      minWidth: 160,
    },
    {
      id: 71,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_9,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_9,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_9,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_10,
      value: reportClassConst.COLUMN_NAME.SUB_ADMIN_NAME_10,
      label: reportClassConst.COLUMN_NAME_TH.SUB_ADMIN_NAME_10,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportClassConst.COLUMN_NAME.COURSE_CODE,
      value: reportClassConst.COLUMN_NAME.COURSE_CODE,
      label: 'รหัสหลักสูตร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 20,
      align: 'center',
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportClassConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportClassConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportClassConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportClassConst.COLUMN_NAME.TRAININGPLATFORM,
      value: reportClassConst.COLUMN_NAME.TRAININGPLATFORM,
      label: 'รูปแบบการสอน',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportClassConst.COLUMN_NAME.TRAININGPLATFORM,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 7,
      name: COLUMN_NAME_REPORT_CLASS.DISTRIBUTION,
      value: COLUMN_NAME_REPORT_CLASS.DISTRIBUTION,
      label: 'ช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.DISTRIBUTION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: COLUMN_NAME_REPORT_CLASS.ABBREVIATION_DISTRIBUTION,
      value: COLUMN_NAME_REPORT_CLASS.ABBREVIATION_DISTRIBUTION,
      label: 'ชื่อย่อของช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.DISTRIBUTION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: COLUMN_NAME_REPORT_CLASS.ROADMAP,
      value: COLUMN_NAME_REPORT_CLASS.ROADMAP,
      label: 'กลุ่มหลักสูตร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: COLUMN_NAME_REPORT_CLASS.ABBREVIATION_ROADMAP,
      value: COLUMN_NAME_REPORT_CLASS.ABBREVIATION_ROADMAP,
      label: 'ชื่อย่อของกลุ่มหลักสูตร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: COLUMN_NAME_REPORT_CLASS.LEVEL_OF_LEARNER,
      value: COLUMN_NAME_REPORT_CLASS.LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียน',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 13,
      name: COLUMN_NAME_REPORT_CLASS.OTHER_LEVEL_OF_LEARNER,
      value: COLUMN_NAME_REPORT_CLASS.OTHER_LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 14,
      name: COLUMN_NAME_REPORT_CLASS.ACQUIRED_SKILL,
      value: COLUMN_NAME_REPORT_CLASS.ACQUIRED_SKILL,
      label: 'ระดับหลักสูตร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.ACQUIRED_SKILL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: COLUMN_NAME_REPORT_CLASS.REQUIRE_DOCUMENT,
      value: COLUMN_NAME_REPORT_CLASS.REQUIRE_DOCUMENT,
      label: 'เอกสารการสมัคร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportClassConst.requireOptions,
    },
    {
      id: 16,
      name: COLUMN_NAME_REPORT_CLASS.STATION,
      value: COLUMN_NAME_REPORT_CLASS.STATION,
      label: 'Station',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.STATION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: COLUMN_NAME_REPORT_CLASS.DEPARTMENT,
      value: COLUMN_NAME_REPORT_CLASS.DEPARTMENT,
      label: 'Department',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.DEPARTMENT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: COLUMN_NAME_REPORT_CLASS.TRAINER_STAFF_ID,
      value: COLUMN_NAME_REPORT_CLASS.TRAINER_STAFF_ID,
      label: 'Trainer Staff ID',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.TRAINER_STAFF_ID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 19,
      name: COLUMN_NAME_REPORT_CLASS.TRAINER_NAME,
      value: COLUMN_NAME_REPORT_CLASS.TRAINER_NAME,
      label: 'Trainer Name',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: COLUMN_NAME_REPORT_CLASS.TRAINER_NAME,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 20,
      name: COLUMN_NAME_REPORT_CLASS.STATUS_CLASS,
      value: COLUMN_NAME_REPORT_CLASS.STATUS_CLASS,
      label: 'Status Class',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportClassConst.statusOptions,
    },
    {
      id: 21,
      name: COLUMN_NAME_REPORT_CLASS.VERSION_NUMBER,
      value: COLUMN_NAME_REPORT_CLASS.VERSION_NUMBER,
      label: 'เลขเวอร์ชันของหลักสูตร',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 22,
      name: COLUMN_NAME_REPORT_CLASS.START_CLASS_DATETIME,
      value: COLUMN_NAME_REPORT_CLASS.START_CLASS_DATETIME,
      label: 'วันเริ่มต้นคลาส',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 23,
      name: COLUMN_NAME_REPORT_CLASS.END_CLASS_DATETIME,
      value: COLUMN_NAME_REPORT_CLASS.END_CLASS_DATETIME,
      label: 'วันสิ้นสุดคลาส',
      minWidth: 160,
      type: reportClassConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
