import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import RadarChart from '../../../../components/Chart/RadarChart'
import _ from 'lodash'
import { FileDownloadOutlined } from '@mui/icons-material'
import { downloadChart } from '../handler/downloadChart'

const RadarChartBox = ({ workTypeData = {}, idx, workTypeListAVG }) => {
  const theme = useTheme()
  const dataSets = {
    label: workTypeData.title || '',
    data: _.map(workTypeData.workTypeList, (li) => Number(li.value)),
    fill: true,
    backgroundColor: 'rgba(233, 158, 125, 0.50)',
    borderColor: theme.palette.graph.orange1,
  }
  const defaultDataSet = {
    label: 'โดยรวม',
    data: _.map(workTypeListAVG, (li) => Number(li.value)),
    fill: true,
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
    borderColor: theme.palette.blue.oceanBlue,
  }
  const data = {
    labels: _.map(workTypeData.workTypeList, (li) => li.label),
    datasets: [dataSets, defaultDataSet],
  }

  return (
    <Box
      id={`radarChartBox-${idx}`}
      sx={{
        p: 1.25,
        borderRadius: 2,
        border: `1px solid ${theme.palette.blue.blueLine}`,
        backgroundColor: theme.palette.background.default,
        width: 416,
        height: 373,
        position: 'relative',
      }}
    >
      <Button
        sx={{ position: 'absolute', right: 0 }}
        id={`btn-download-chart-${idx}`}
        data-testid="btn-download-chart"
        variant="text"
        size="m"
        startIcon={<FileDownloadOutlined sx={{ fontSize: 16 }} />}
        onClick={() =>
          downloadChart(`radarChartBox-${idx}`, `btn-download-chart-${idx}`)
        }
      >
        <Typography variant="buttons">ดาวน์โหลด</Typography>
      </Button>
      <RadarChart data={data} height={397} idx={idx} />
    </Box>
  )
}

export default RadarChartBox
