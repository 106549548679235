//import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
//import { store } from '../../App'
import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'
//import { getETestingReportQuestionCount } from '../../services/reportComp'

export const reportETestingRawDataByLearnerConst = {
  COLUMN_NAME: {
    NO: 'no',
    NAME: 'name',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    CLASS_LEVEL: 'classLevel',
    COURSE_ID: 'courseId',
    TEST_ID: 'testId',
    TEST_NAME: 'testName',
    TEST_TYPE: 'testType',
    TRAINING_DATE: 'trainingDate',
    STATION: 'station',
    TRAINER_ID: 'trainerId',
    TRAINER_NAME: 'trainerName',
    COUNT_QUESTION: 'countQuestion',
    COUNT_OPEN: 'countOpen',
    COUNT_ENROLL: 'countEnroll',
    COUNT_ATTENDANCE: 'countAttendance',
    PASS: 'pass',
    FAIL: 'fail',
    ABSENT: 'absent',
    MEAN_SCORE: 'meanScore',
    MIN_SCORE: 'minScore',
    MAX_SCORE: 'maxScore',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    VERSION_NUMBER: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
    VERSION: 'courseVersion',
    STATUS_CLASS: 'statusClass',
    END_CLASS_DATETIME: 'endClassDateTime',
    START_CLASS_DATETIME: 'startClassDateTime',
    TRAININGPLATFORM: 'trainingPlatform',
    AGENT_TYPE: 'agentType',
    COURSE_NAME_FOR_LEARNER: 'courseNameLearner',
    DISTRIBUTION: 'distribution',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt',
    TIME: 'time',
    SCORE: 'score',
    TITLENAME: 'titleName',
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    AGENT_CODE: 'agentCode',
    FULL_AGENT_CODE: 'fullAgentCode',
    RAM: 'RAM',
    SRAM: 'SRAM',
    UNITCODE: 'unitCode',
    GROUP: 'group',
    EXAM_NAME: 'examName',
    EXAM_TYPE: 'examType',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    NAME: 'ชื่อแบบทดสอบ',
    COURSE_NAME_EN: 'CourseName EN',
    COURSE_NAME_TH: 'CourseName TH',
    CLASS_LEVEL: 'ระดับ Class',
    COURSE_ID: 'Course ID',
    TEST_ID: 'Test ID',
    TEST_NAME: 'Test Name',
    TEST_TYPE: 'Test Type',
    TRAINING_DATE: 'Training Date',
    STATION: 'station',
    TRAINER_ID: 'Trainer ID',
    TRAINER_NAME: 'Trainer Name - LastName',
    COUNT_QUESTION: 'จำนวนคำถาม',
    COUNT_OPEN: 'จำนวนเปิดรับสมัคร',
    COUNT_ENROLL: 'จำนวนรับสมัคร',
    COUNT_ATTENDANCE: 'จำนวนเข้าเรียน',
    PASS: 'ผ่าน',
    FAIL: 'ไม่ผ่าน',
    ABSENT: 'ไม่ทำ',
    MEAN_SCORE: 'ค่าเฉลี่ยคะแนน',
    MIN_SCORE: 'คะแนน Min',
    MAX_SCORE: 'คะแนน Max',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนของทางการจำหน่ายอื่น',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    VERSION_NUMBER: 'เลขเวอร์ชันของหลักสูตร',
    TRAININGPLATFORM: 'รูปแบบการสอน',
    AGENT_TYPE: 'Agent Type',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
    DISTRIBUTION: 'ช่องทางการจัดจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจัดจำหน่าย',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    CREATED_AT: 'วันที่สร้าง',
    VERSION: 'Version',
    TIME: 'Time',
    SCORE: 'คะแนนที่ได้รับ',
    TITLENAME: 'TitleName',
    FIRSTNAME: 'FirstName',
    LASTNAME: 'LastName',
    AGENT_CODE: 'รหัสตัวแทน 6 หลัก',
    FULL_AGENT_CODE: 'Full Agent code',
    RAM: 'RAM',
    SRAM: 'SRAM',
    UNITCODE: 'Unit Code',
    GROUP: 'สังกัดหน่วย',
    EXAM_NAME: 'ชื่อแบบทดสอบ',
    EXAM_TYPE: 'ประเภทแบบทดสอบ',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],

  PlatformOptions: [
    {
      value: 'CLASSROOM',
      label: 'Classroom',
    },
    {
      value: 'ONLINE',
      label: 'Online',
    },
    {
      value: 'ELEARNING',
      label: 'E-Learning',
    },
  ],

  agentTypeOptions: [
    {
      value: 'ALLTYPE',
      label: 'All Type',
    },
    {
      value: 'A',
      label: 'A',
    },
    {
      value: 'L',
      label: 'L',
    },
  ],
}

export const reportETestingRawDataByLearnerDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.NO,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.NO,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_ID,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_ID,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_ID,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TEST_ID,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_NAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_NAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TEST_NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_TYPE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_TYPE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TEST_TYPE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.VERSION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.VERSION,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.VERSION,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINING_DATE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINING_DATE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TRAINING_DATE,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATION,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_ID,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TRAINER_ID,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COUNT_QUESTION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COUNT_QUESTION,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COUNT_QUESTION,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TIME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TIME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TIME,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.SCORE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.SCORE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.SCORE,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TITLENAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TITLENAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TITLENAME,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.FIRSTNAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.FIRSTNAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.FIRSTNAME,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.LASTNAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.LASTNAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.LASTNAME,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.AGENT_CODE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.AGENT_CODE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.RAM,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.RAM,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.RAM,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.SRAM,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.SRAM,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.SRAM,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      label: 'Distribution Channel',
      minWidth: 160,
    },
    {
      id: 25,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.UNITCODE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.UNITCODE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.UNITCODE,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.GROUP,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.GROUP,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.GROUP,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.EXAM_NAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.EXAM_NAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.EXAM_NAME,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.EXAM_TYPE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.EXAM_TYPE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.EXAM_TYPE,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATION,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 2,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_ID,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.COURSE_ID,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TEST_ID,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TEST_ID,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      label: 'Status Class',
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportETestingRawDataByLearnerConst.statusOptions,
    },
    {
      id: 7,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.VERSION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.VERSION,
      label: 'เวอร์ชั่น',
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.INPUT_NUMBER,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME
        .START_CLASS_DATETIME,
      value:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.START_CLASS_DATETIME,
      label: 'วันเริ่มต้น (Training Date)',
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 9,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      label: 'วันสิ้นสุด (Training Date)',
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 10,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME
        .COURSE_NAME_FOR_LEARNER,
      value:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH
          .COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_ID,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_ID,
      label: 'Trainer (Staff ID)',
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAININGPLATFORM,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.TRAININGPLATFORM,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.TRAININGPLATFORM,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportETestingRawDataByLearnerConst.PlatformOptions,
    },
    {
      id: 14,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME
        .ABBREVIATION_DISTRIBUTION,
      value:
        reportETestingRawDataByLearnerConst.COLUMN_NAME
          .ABBREVIATION_DISTRIBUTION,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH
          .ABBREVIATION_DISTRIBUTION,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME
          .ABBREVIATION_DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 16,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.ROADMAP,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.ROADMAP,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.ROADMAP,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportETestingRawDataByLearnerConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 17,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME
        .ABBREVIATION_ROADMAP,
      value:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.ABBREVIATION_ROADMAP,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 18,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.LEVEL_OF_LEARNER,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 19,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME
        .OTHER_LEVEL_OF_LEARNER,
      value:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      label:
        reportETestingRawDataByLearnerConst.COLUMN_NAME_TH
          .OTHER_LEVEL_OF_LEARNER,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 20,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.ACQUIRED_SKILL,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportETestingRawDataByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 21,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.AGENT_TYPE,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.AGENT_TYPE,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportETestingRawDataByLearnerConst.agentTypeOptions,
    },
    {
      id: 22,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.NAME,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.NAME,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.NAME,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.CREATED_AT,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.CREATED_AT,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 24,
      name: reportETestingRawDataByLearnerConst.COLUMN_NAME.UPDATED_AT,
      value: reportETestingRawDataByLearnerConst.COLUMN_NAME.UPDATED_AT,
      label: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
      type: reportETestingRawDataByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}

export const HandleLearnerExamQuestion = async () => {
  const newDefault = reportETestingRawDataByLearnerDefaultColumn.defaultColumns
  // const questionCount = await store.dispatch(getETestingReportQuestionCount())
  const mockQuestionCount = 5
  for (let i = 0; i < mockQuestionCount; i++) {
    newDefault.push(
      {
        id: 30 + 2 * i,
        name: `point${i + 1}`,
        value: `point${i + 1}`,
        label: `ชื่อแบบทดสอบข้อที่ ${i + 1}`,
        minWidth: 160,
      },
      {
        id: 31 + 2 * i,
        name: `result${i + 1}`,
        value: `result${i + 1}`,
        label: `ถูกผิดข้อที่ ${i + 1}`,
        minWidth: 160,
      },
    )
  }
  console.log(newDefault)
  return newDefault
}
