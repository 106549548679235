import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { reportConfig } from './reportConfig'
import { handleFilterReport } from './events'
import {
  setTabActiveReport,
  setInitialReportMP,
} from '../../../redux/slices/monthlyPlan'
import { Box, Card } from '@mui/material'
import FilterDrawer from '../../../components/Report/components/FilterDrawer'
import {
  setReportConfig,
  setInitialReport,
} from '../../../redux/slices/reportComp'
import TableReport from './TableReport'
import { useReportConstants } from '../../../components/Report/hooks/useReportConstants'
import Header from './Header'
import { LoadingPage } from './Styled'
import { defaultDataWorkload, defaultDataYearly } from './model'
import { getDefaultFiltersList } from '../handler/getDefaultFiltersList'

const Index = () => {
  const dispatch = useDispatch()
  const { tabActive, isLoading, filters } = useSelector(
    (state) => ({
      tabActive: state.monthlyPlan.report.tabActive,
      isLoading: state.monthlyPlan.report.isLoading,
      filters: state.reportComp.filters,
    }),
    shallowEqual,
  )

  const moduleId = tabActive === 1 ? 'MP_WORKLOAD' : 'MP_YEARLY'
  const { filterColumnOptions, defaultColumns, COLUMN_NAME } =
    useReportConstants(moduleId)
  const isWorkload = moduleId === 'MP_WORKLOAD'
  const defaultData = isWorkload ? defaultDataWorkload : defaultDataYearly

  useEffect(async () => {
    const defaultFilters = await getDefaultFiltersList()

    dispatch(
      setReportConfig({
        reportConfig: {
          ...reportConfig,
          moduleId: moduleId,
          modulePath: isWorkload ? '/monthly-plan' : '/monthly-plan/yearly',
        },
      }),
    )
    dispatch(
      setInitialReport({
        filterColumnOptions: filterColumnOptions,
        columnName: COLUMN_NAME,
        defaultColumns: defaultColumns,
        defaultFilters: filters.length > 0 ? filters : defaultFilters,
      }),
    )
    dispatch(
      setInitialReportMP({
        headCellsReport: defaultColumns,
        reportsData: defaultData,
      }),
    )
    handleFilterReport()
  }, [tabActive])

  useEffect(() => {
    dispatch(setTabActiveReport(0))
  }, [])

  return (
    <Box
      sx={{
        opacity: isLoading ? 0.4 : 'unset',
        pointerEvents: isLoading ? 'none' : 'unset',
      }}
      display="flex"
      flexDirection="column"
      gap={3}
      mx={3}
      pb={2}
    >
      <Header />
      <Card sx={{ p: 3, overflow: 'scroll' }}>
        <TableReport />
      </Card>
      <FilterDrawer handleFilter={handleFilterReport} />
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}
export default Index
